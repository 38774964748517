export const HOME_SHELL = [
  // главная страница
  {
    name: 'home',
    path: '/',
    alias: ['/ab/home'],
    component: () =>
      import(
        /* webpackChunkName: "new-home-page" */ '@jtnews/home/frontend/features/home-page'
      ).then((m) => m.HomePage),
  },
];
