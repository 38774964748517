import {
  guardUnspecified,
  guardEmptyString,
  guardMinLengthString,
  guardXssString,
} from '@smh/utils/guards';

import { ValueObject } from '../value-object';

type SendNewsContentProps = {
  value: string;
};

export class SendNewsContentVOError extends Error {
  override name: string;

  private constructor(message: string, error?: Error) {
    super(message, error);

    this.name = 'SendNewsContentVOError';
  }

  static of(message: string, error?: Error) {
    return new SendNewsContentVOError(message, error);
  }
}

export class SendNewsContentVO extends ValueObject<SendNewsContentProps> {
  static readonly MIN_LENGTH = 3;

  private static readonly _error = SendNewsContentVOError.of(
    `Текст сообщения должен быть не меньше ${SendNewsContentVO.MIN_LENGTH} символов`,
  );

  private constructor(props: SendNewsContentProps) {
    super(props);
  }

  get value() {
    return this.props.value;
  }

  public static create(contact: string): SendNewsContentVO {
    if (!guardUnspecified(contact)) {
      throw this._error;
    }

    if (!guardEmptyString(contact)) {
      throw this._error;
    }

    if (!guardMinLengthString(contact, SendNewsContentVO.MIN_LENGTH)) {
      throw this._error;
    }

    if (!guardXssString(contact)) {
      throw this._error;
    }

    return new SendNewsContentVO({ value: contact });
  }
}
