import mitt from 'mitt';

import type {
  ModalEventMap,
  IModalsInform,
} from '@jtnews/shared/seedwork/frontend/application';

export class ModalsInform implements IModalsInform {
  public inform = mitt<ModalEventMap>();

  open<T extends Record<string, unknown> = Record<string, unknown>>(
    key: string,
    data?: T,
  ) {
    this.inform.emit('open', { key, data });
  }

  close(key: string) {
    this.inform.emit('beforeClose', { key });
    this.inform.emit('close', { key });
  }
}
