import { guardUnspecified } from '@smh/utils/guards';

import { CaptchaError } from '@jtnews/shared/seedwork/frontend/application';

import { Captcha, CaptchaConfig } from './captcha';

type GReCaptchaConfig = CaptchaConfig & {
  publicKey: string;
};

export class GReCaptcha extends Captcha {
  constructor(private readonly _config: GReCaptchaConfig) {
    super(_config);
  }

  async execute(input: string): Promise<string> {
    if (this.setupStatus !== 'installed') {
      try {
        await this.setup();
      } catch (err) {
        this.logger.capture({
          error: CaptchaError.of('Не удалось выполнить GReCaptcha prepare', err as Error),
        });

        return this.getFallbackToken();
      }
    }

    return new Promise((resolve, _) => {
      const timeoutId = setTimeout(() => {
        this.logger.capture({
          error: CaptchaError.of(
            `Не удалось сделать GReCaptcha execute из-за timeout=${this.timeout}`,
          ),
        });

        resolve(this.getFallbackToken());
      }, this.timeout);

      try {
        if (guardUnspecified(window.grecaptcha)) {
          window.grecaptcha.ready(() => {
            window.grecaptcha?.execute(this._config.publicKey, { action: input }).then(
              (value) => {
                clearTimeout(timeoutId);
                resolve(value);
              },
              (err: Error) => {
                this.logger.capture({
                  error: CaptchaError.of(
                    'Не удалось выполнить window.grecaptcha.execute',
                    err,
                  ),
                });

                clearTimeout(timeoutId);
                resolve(this.getFallbackToken());
              },
            );
          });

          return;
        }

        this.logger.capture({
          error: CaptchaError.of('window.grecaptcha не инициализирован'),
        });

        clearTimeout(timeoutId);
        resolve(this.getFallbackToken());
      } catch (err) {
        this.logger.capture({
          error: CaptchaError.of('Не удалось выполнить execute', err as Error),
        });

        clearTimeout(timeoutId);
        resolve(this.getFallbackToken());
      }
    });
  }
}
