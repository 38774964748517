import { guardUnspecified } from '@smh/utils/guards';

import { UniqueEntityID } from './unique-entity-id';

export abstract class Entity<T> {
  public readonly props: T;

  protected readonly entityId: UniqueEntityID;

  constructor(props: T, id?: UniqueEntityID) {
    this.entityId = id ? id : new UniqueEntityID();
    this.props = props;
  }

  public equals(object?: Entity<T>): boolean {
    if (!guardUnspecified(object)) {
      return false;
    }

    if (this === object) {
      return true;
    }

    if (!this._isEntity(object)) {
      return false;
    }

    return this.entityId.equals(object.entityId);
  }

  private _isEntity(v: unknown): v is Entity<unknown> {
    return v instanceof Entity;
  }
}
