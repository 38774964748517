import { AppError } from '@jtnews/shared/seedwork/frontend/application';

export class CaptchaError extends AppError {
  name: 'CaptchaError';

  private constructor(message: string, error?: Error) {
    super(message, error);

    this.name = 'CaptchaError';
  }

  static of(message: string, error?: Error) {
    return new CaptchaError(message, error);
  }
}
