var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.tag,_vm._g({tag:"component",class:[
    _vm.$style.wrap,
    _vm.$style[_vm.tag],
    _vm.$style[_vm.btnSize],
    _vm.$style[_vm.btnStyle],
    _vm.$style[_vm.btnType],
    _vm.isRounded ? _vm.$style.rounded : '',
    _vm.isIconOnly ? _vm.$style.iconOnly : '' ]},_vm.$listeners),[_vm._t("default"),(_vm.hasBadge)?_c('span',{class:_vm.$style.badge},[_vm._t("badge")],2):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }