import { injectScript } from '@smh/utils/dom';
import { guardUnspecified, guardEmptyString } from '@smh/utils/guards';

import { BlockUseCase, BlockUseCaseConfig } from '../blocks';

import type { AdvBlockScriptVM } from './adv.vm';

export const ADV_BLOCK_USE_CASE_KEY = 'adv_block_use_case_key';

export interface IAdvBlockUseCase {
  prepareContent(input: { content: string; postfix?: string }): string;
  inject(script: AdvBlockScriptVM): void;
}

export class AdvBlockUseCase extends BlockUseCase implements IAdvBlockUseCase {
  constructor(config: BlockUseCaseConfig) {
    super(config);
  }

  public inject(script: AdvBlockScriptVM): void {
    void injectScript(script, false);
  }

  public prepareContent(input: { content: string; postfix: string }) {
    const { content, postfix } = input;

    if (guardUnspecified(postfix)) {
      return this._updateContainerId({ content, postfix });
    }

    return content;
  }

  private _updateContainerId(input: { content: string; postfix: string }): string {
    const { content, postfix } = input;

    const containerId = this._getContainerId(content);

    if (guardEmptyString(containerId)) {
      const containerIdRegex = new RegExp(containerId, 'g');

      const newContainerId = `${containerId}_${postfix}`;
      return content.replace(containerIdRegex, newContainerId);
    }

    return content;
  }

  private _getContainerId(advContent: string): string {
    const containerIdRegEx = /\sid=("|')([^"]*?)("|')/gi;
    const data = containerIdRegEx.exec(advContent);

    if (guardUnspecified(data)) {
      const [, , id] = data;
      if (guardUnspecified(id) && guardEmptyString(id)) {
        return id;
      }
    }

    return '';
  }
}
