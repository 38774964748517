import { Entity, UniqueEntityID } from '@jtnews/shared/seedwork/frontend/domain';

import { AuthorizationPasswordVO } from './authorization-password.vo';
import { AuthorizationUsernameVO } from './authorization-username.vo';

type AuthorizationUserProps = {
  username: AuthorizationUsernameVO;
  password: AuthorizationPasswordVO;
};

export class AuthorizationUserEntity extends Entity<AuthorizationUserProps> {
  private constructor(props: AuthorizationUserProps, id?: UniqueEntityID) {
    super(props, id);
  }

  get id() {
    return this.entityId;
  }

  get username() {
    return this.props.username;
  }

  get password() {
    return this.props.password;
  }

  public static create(props: AuthorizationUserProps, id?: UniqueEntityID) {
    return new AuthorizationUserEntity(props, id);
  }
}
