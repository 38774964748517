import { guardUnspecified } from '@smh/utils/guards';

import type {
  BlockUseCaseConfig,
  ILogger,
  IBookmarksUseCase,
  IModalsInform,
} from '@jtnews/shared/seedwork/frontend/application';

import { AUTH_MODAL_KEY } from '../membership';

import { BookmarkUseCaseError } from './bookmark-usecase.error';
import type {
  IBookmarkApi,
  AddBookmarkApiInput,
  DeleteBookmarkApiInput,
} from './bookmark.api';
import { BookmarkLimitError } from './bookmark.error';
import type { IBookmarkPresenter } from './bookmark.presenter';
import type { BookmarkVM } from './bookmark.vm';

type AddBookmarkInput = Omit<AddBookmarkApiInput, 'regionId'>;

type DeleteBookmarkInput = Omit<DeleteBookmarkApiInput, 'regionId'>;

type ToggleBookmarkInput = Omit<AddBookmarkInput, 'profileId'>;

export interface IBookmarkUseCaseUserStore {
  readonly isAuthorized: boolean;
  account: {
    userId: number;
    bookmarksLink: string;
  } | null;
  hasUserBookmark: (articleId: number) => boolean;
  findBookmarkByArticleId: (articleId: number) => BookmarkVM | null;
  addBookmark: (bookmark: BookmarkVM) => void;
  deleteBookmark: (bookmarkId: string) => void;
}

export const BOOKMARKS_LIMIT_MODAL_KEY = 'bookmarks-limit-modal-key';

export const BOOKMARKS_USE_CASE_KEY = 'bookmarks_use_case_key';

export type BookmarkUseCaseConfig<TPresenterInput> = BlockUseCaseConfig & {
  regionId: number;
  deps: {
    api: IBookmarkApi<TPresenterInput>;
    presenter: IBookmarkPresenter<TPresenterInput>;
    store: IBookmarkUseCaseUserStore;
    modalsInform: IModalsInform;
  };
};

export class BookmarkUseCase<TPresenterInput> implements IBookmarksUseCase {
  private readonly _logger: ILogger;
  private readonly _api: IBookmarkApi<TPresenterInput>;
  private readonly _presenter: IBookmarkPresenter<TPresenterInput>;
  private readonly _regionId: number;
  private readonly _store: IBookmarkUseCaseUserStore;
  private readonly _modalsInform: IModalsInform;

  constructor(config: BookmarkUseCaseConfig<TPresenterInput>) {
    const {
      regionId,
      deps: { api, store, presenter, logger, modalsInform },
    } = config;

    this._api = api;
    this._logger = logger;
    this._presenter = presenter;
    this._regionId = regionId;
    this._store = store;
    this._modalsInform = modalsInform;
  }

  public get bookmarksLink() {
    return this._store.account?.bookmarksLink ?? '';
  }

  private get _profileId() {
    return this._store.account?.userId ?? 0;
  }

  public hasUserBookmark(articleId: number): boolean {
    return this._store.hasUserBookmark(articleId);
  }

  public async toggle(input: ToggleBookmarkInput) {
    if (!this._store.isAuthorized) {
      this._modalsInform.open(AUTH_MODAL_KEY);

      return;
    }

    const { articleId, type } = input;

    if (!this.hasUserBookmark(articleId)) {
      await this._add({
        type,
        profileId: this._profileId,
        articleId,
      });

      return;
    }

    const bookmark = this._store.findBookmarkByArticleId(articleId);

    if (guardUnspecified(bookmark)) {
      await this._delete({ bookmarkId: bookmark.id, profileId: this._profileId });
    }
  }

  private async _add(input: AddBookmarkInput) {
    try {
      const data = await this._api.add({
        ...input,
        regionId: this._regionId,
      });

      const bookmark = this._presenter.present({
        data,
        params: null,
      });

      if (!guardUnspecified(bookmark)) {
        throw new Error('Не пришли данные с сервера');
      }

      this._store.addBookmark(bookmark);
    } catch (err) {
      if (err instanceof BookmarkLimitError) {
        this._modalsInform.open(BOOKMARKS_LIMIT_MODAL_KEY, {
          message: err.message,
        });

        return;
      }

      const error = BookmarkUseCaseError.of('Не удалось добавить закладку', err as Error);
      this._logger.capture({ error });
    }
  }

  private async _delete(input: DeleteBookmarkInput) {
    try {
      await this._api.delete({
        ...input,
        regionId: this._regionId,
      });

      this._store.deleteBookmark(input.bookmarkId);
    } catch (err) {
      const error = BookmarkUseCaseError.of('Не удалось удалить закладку', err as Error);
      this._logger.capture({ error });
    }
  }
}
