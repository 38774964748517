import { AppError } from '../app-error';

export class SendNewsUseCaseError extends AppError {
  name: 'SendNewsUseCaseError';

  private constructor(message: string, error?: Error) {
    super(message, error);

    this.name = 'SendNewsUseCaseError';
  }

  static of(message: string, error?: Error) {
    return new SendNewsUseCaseError(message, error);
  }
}
