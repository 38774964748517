import { AppError } from '@jtnews/shared/seedwork/frontend/application';

export class AccountUseCaseError extends AppError {
  name: 'AccountUseCaseError';

  private constructor(message: string, error?: Error) {
    super(message, error);

    this.name = 'AccountUseCaseError';
  }

  static of(message: string, error?: Error) {
    return new AccountUseCaseError(message, error);
  }
}
