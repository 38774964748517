






import { Component, Prop, Vue, Inject } from 'vue-property-decorator';

import { FULLSCREEN_ADV_USE_CASE_KEY } from '@jtnews/shared/seedwork/frontend/application';
import type {
  AdvVM,
  IFullscreenAdvUseCase,
} from '@jtnews/shared/seedwork/frontend/application';
import { AnalyticsBlockPlace } from '@jtnews/shared/seedwork/frontend/domain';
import { LazyHydrate } from '@jtnews/shared/ui';

const enum FullscreenAdvEvent {
  Mounted = 'jtnFullscreenMounted',
  Closed = 'jtnFullscreenClosed',
}

@Component({
  name: 'AdvFullscreen',
  components: { LazyHydrate },
})
export default class AdvBlock extends Vue {
  @Prop({
    required: true,
    type: Number,
  })
  cmsPlace!: number;

  @Prop({
    required: true,
    type: Object,
  })
  block!: AdvVM;

  @Inject(FULLSCREEN_ADV_USE_CASE_KEY)
  private readonly _useCase: IFullscreenAdvUseCase;

  disposables: CallableFunction[] = [];

  created() {
    this._useCase.setBlockPlace({
      defaultPlace: AnalyticsBlockPlace.Content,
      ignoreBreakpoints: true,
    });
  }

  beforeMount() {
    if (window.jtnewsAdvEventsInfo?.isCallOfFullscreenMounted) {
      this.processAdvMounted();
    } else {
      document.removeEventListener(
        FullscreenAdvEvent.Mounted,
        window.setFlagCalledFullscreenMounted,
      );

      const listener = () => {
        this.processAdvMounted();
      };

      this.disposables.push(() => {
        document.removeEventListener(FullscreenAdvEvent.Mounted, listener);
      });

      document.addEventListener(FullscreenAdvEvent.Mounted, listener, { once: true });
    }

    if (window.jtnewsAdvEventsInfo?.isCallOfFullscreenClosed) {
      this.processAdvClosed();
    } else {
      document.removeEventListener(
        FullscreenAdvEvent.Closed,
        window.setFlagCalledFullscreenClosed,
      );

      const listener = () => {
        this.processAdvClosed();
      };

      this.disposables.push(() => {
        document.removeEventListener(FullscreenAdvEvent.Closed, listener);
      });

      document.addEventListener(FullscreenAdvEvent.Closed, listener, { once: true });
    }
  }

  beforeDestroy() {
    this.disposables.forEach((dispose) => void dispose());
  }

  processAdvMounted() {
    this._useCase.processAdvMounted();
  }

  processAdvClosed() {
    this._useCase.processAdvClosed();
  }
}
