import { guardUnspecified } from '@smh/utils/guards';
import { isBrowser } from '@smh/utils/is-in-browser';
import { localStorageApiFactory } from '@smh/utils/storage';

import {
  BlockUseCase,
  type IModalsInform,
} from '@jtnews/shared/seedwork/frontend/application';
import type {
  IBlockUseCase,
  BlockUseCaseConfig,
} from '@jtnews/shared/seedwork/frontend/application';
import type { BlockPlaceRepositoryConfig } from '@jtnews/shared/seedwork/frontend/domain';
import {
  AUTH_FORUM_MODAL_KEY,
  AUTH_MODAL_KEY,
  LOCAL_STORAGE_USER_IS_AUTH_KEY,
  type AccountForumsVM,
  type AccountVM,
  type ILogout,
  type ILogoutForums,
  type INotificationUseCase,
} from '@jtnews/users/frontend/application';
import type { IPageHeaderProfileAnalyticsService } from '@jtnews/users/frontend/domain';

type AccountIsAuth<T> = { isAuthorized: T };
type AccountData<T> = (AccountIsAuth<true> & T) | AccountIsAuth<false>;

export const PAGE_HEADER_PROFILE_USE_CASE_KEY = 'page_header_profile_use_case_key';

export interface IPageHeaderProfileStore {
  readonly account: AccountVM | null;
  readonly accountForums: AccountForumsVM | null;
}

export interface IPageHeaderProfileUseCase extends IBlockUseCase {
  readonly accountData: AccountData<AccountVM>;
  readonly accountForumsData: AccountData<AccountForumsVM>;
  readonly bookmarksLink: string;
  readonly notificationsCount: number;
  readonly profilePageLink: string;
  readonly profileSettingsLink: string;
  readonly hasForums: boolean;
  logout(): void;
  logoutForums(): void;
  processBookmarksClick(): void;
  processLoginClick(profileType?: 'news' | 'forums'): void;
  processProfileClick(): void;
  processProfileTriggerClick(): void;
  processSettingsClick(): void;
}

export type PageHeaderProfileUseCaseConfig = BlockUseCaseConfig & {
  regionId: number;
  mailLink?: string;
  hasForums: boolean;
  deps: {
    analyticsService: IPageHeaderProfileAnalyticsService;
    logoutApi: ILogout;
    logoutForumsApi: ILogoutForums;
    notificationUseCase: INotificationUseCase;
    store: IPageHeaderProfileStore;
    modalsInform: IModalsInform;
  };
};

export class PageHeaderProfileUseCase
  extends BlockUseCase
  implements IPageHeaderProfileUseCase
{
  private readonly _analyticsService: IPageHeaderProfileAnalyticsService;
  private readonly _notificationUseCase: INotificationUseCase;
  private readonly _regionId: number;
  private readonly _hasForums: boolean;
  private readonly _logoutApi: ILogout;
  private readonly _logoutForumsApi: ILogout;
  private readonly _store: IPageHeaderProfileStore;
  private readonly _modalsInform: IModalsInform;
  private readonly _localStorageApi!: ReturnType<typeof localStorageApiFactory>;

  constructor(config: PageHeaderProfileUseCaseConfig) {
    super(config);

    const {
      regionId,
      hasForums,
      deps: {
        analyticsService,
        modalsInform,
        logoutApi,
        logoutForumsApi,
        notificationUseCase,
        store,
      },
    } = config;

    this._analyticsService = analyticsService;
    this._notificationUseCase = notificationUseCase;
    this._regionId = regionId;
    this._hasForums = hasForums;
    this._logoutApi = logoutApi;
    this._logoutForumsApi = logoutForumsApi;
    this._store = store;
    this._modalsInform = modalsInform;

    if (isBrowser()) {
      this._localStorageApi = localStorageApiFactory(localStorage);
    }
  }

  public get accountData(): AccountData<AccountVM> {
    return guardUnspecified(this._store.account)
      ? { isAuthorized: true, ...this._store.account }
      : { isAuthorized: false };
  }

  public get accountForumsData(): AccountData<AccountForumsVM> {
    return guardUnspecified(this._store.accountForums)
      ? { isAuthorized: true, ...this._store.accountForums }
      : { isAuthorized: false };
  }

  public get notificationsCount() {
    return this._notificationUseCase.newNotificationsCount;
  }

  public get bookmarksLink() {
    return this._store.account?.bookmarksLink ?? '';
  }

  public get profilePageLink() {
    return this._store.account?.profilePageLink ?? '';
  }

  public get profileSettingsLink() {
    return this._store.account?.profileSettingsLink ?? '';
  }

  public get hasForums() {
    return this._hasForums;
  }

  public setBlockPlace(blockPlaceConfig: BlockPlaceRepositoryConfig) {
    this._analyticsService.setBlockPlace(blockPlaceConfig);
  }

  public async logout() {
    if (!guardUnspecified(this._store.account)) {
      return;
    }

    try {
      await this._logoutApi.logout({ regionId: this._regionId });

      this._setUserAuthStateToLocalStorage();
    } catch (err) {
      return;
    } finally {
      location.reload();
    }
  }

  public async logoutForums() {
    if (!guardUnspecified(this._store.accountForums)) {
      return;
    }

    try {
      await this._logoutForumsApi.logout({ regionId: this._regionId });
    } catch (err) {
      return;
    } finally {
      location.reload();
    }
  }

  public processBookmarksClick() {
    this._analyticsService.sendEventBookmarksClick();
  }

  public processLoginClick(profileType: 'news' | 'forums' = 'news') {
    if (profileType === 'forums') {
      this._modalsInform.open(AUTH_FORUM_MODAL_KEY);
      return;
    }
    this._modalsInform.open(AUTH_MODAL_KEY);
  }

  public processProfileClick() {
    this._analyticsService.sendEventProfileClick();
  }

  public processProfileTriggerClick() {
    this._analyticsService.sendEventProfileTriggerClick();
  }

  public processSettingsClick() {
    this._analyticsService.sendEventSettingsClick();
  }

  // хак для UMP, тк UMP ходит за профилем только если UMP_USER_IS_AUTH == true, то нужно засетить этот ключик, если авторизовываемся/выходим на старом паблике
  private _setUserAuthStateToLocalStorage() {
    this._localStorageApi.set(LOCAL_STORAGE_USER_IS_AUTH_KEY, false);
  }
}
