






import CloudyRainSvg from 'ui/svg/weather/cloudy-rain.svg';
import CloudySnowSvg from 'ui/svg/weather/cloudy-snow.svg';
import CloudyStormSvg from 'ui/svg/weather/cloudy-storm.svg';
import CloudySvg from 'ui/svg/weather/cloudy.svg';
import MoonCloudRainSvg from 'ui/svg/weather/moon-cloud-rain.svg';
import MoonCloudSnowSvg from 'ui/svg/weather/moon-cloud-snow.svg';
import MoonCloudStormSvg from 'ui/svg/weather/moon-cloud-storm.svg';
import MoonCloudSvg from 'ui/svg/weather/moon-cloud.svg';
import MoonSvg from 'ui/svg/weather/moon.svg';
import MostlyCloudyRainSvg from 'ui/svg/weather/mostly-cloudy-rain.svg';
import MostlyCloudySnowSvg from 'ui/svg/weather/mostly-cloudy-snow.svg';
import MostlyCloudyStormSvg from 'ui/svg/weather/mostly-cloudy-storm.svg';
import MostlyCloudySvg from 'ui/svg/weather/mostly-cloudy.svg';
import SunCloudRainSvg from 'ui/svg/weather/sun-cloud-rain.svg';
import SunCloudSnowSvg from 'ui/svg/weather/sun-cloud-snow.svg';
import SunCloudStormSvg from 'ui/svg/weather/sun-cloud-storm.svg';
import SunCloudSvg from 'ui/svg/weather/sun-cloud.svg';
import SunSvg from 'ui/svg/weather/sun.svg';
import { Component, Prop, Vue } from 'vue-property-decorator';

import type { WeatherIconNamesVM } from '@jtnews/shared/seedwork/frontend/application';

const WEATHER_SVG_MAP: Record<WeatherIconNamesVM, string> = {
  ['cloudy']: CloudySvg,
  ['cloudy-rain']: CloudyRainSvg,
  ['cloudy-snow']: CloudySnowSvg,
  ['cloudy-storm']: CloudyStormSvg,
  ['mostly-cloudy']: MostlyCloudySvg,
  ['mostly-cloudy-rain']: MostlyCloudyRainSvg,
  ['mostly-cloudy-snow']: MostlyCloudySnowSvg,
  ['mostly-cloudy-storm']: MostlyCloudyStormSvg,
  ['sun']: SunSvg,
  ['sun-cloud']: SunCloudSvg,
  ['sun-cloud-rain']: SunCloudRainSvg,
  ['sun-cloud-snow']: SunCloudSnowSvg,
  ['sun-cloud-storm']: SunCloudStormSvg,
  ['moon']: MoonSvg,
  ['moon-cloud']: MoonCloudSvg,
  ['moon-cloud-rain']: MoonCloudRainSvg,
  ['moon-cloud-snow']: MoonCloudSnowSvg,
  ['moon-cloud-storm']: MoonCloudStormSvg,
};

@Component({
  name: 'UiWeatherIcon',
})
export default class UiWeatherIcon extends Vue {
  @Prop({
    required: true,
    type: String,
  })
  iconName!: WeatherIconNamesVM;

  get icon() {
    return WEATHER_SVG_MAP[this.iconName];
  }
}
