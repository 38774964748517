


















import { Component, Prop, Vue, Inject } from 'vue-property-decorator';

import type {
  HotNewsBlockVM,
  IHotNewsBlockUseCase,
} from '@jtnews/shared/seedwork/frontend/application';
import { HOT_NEWS_BLOCK_USE_CASE_KEY } from '@jtnews/shared/seedwork/frontend/application';
import { AnalyticsBlockPlace } from '@jtnews/shared/seedwork/frontend/domain';

@Component({
  name: 'HotNewsBlock',
})
export default class HotNewsBlock extends Vue {
  @Prop({
    required: true,
    type: Object as () => HotNewsBlockVM,
  })
  block!: HotNewsBlockVM;

  @Inject(HOT_NEWS_BLOCK_USE_CASE_KEY) private readonly _useCase!: IHotNewsBlockUseCase;

  created() {
    this._useCase.setBlockPlace({
      defaultPlace: AnalyticsBlockPlace.Menu,
      ignoreBreakpoints: true,
    });
  }

  processArticleClicked() {
    this._useCase.processArticleClick(this.block.data);
  }
}
