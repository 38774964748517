import {
  guardUnspecified,
  guardEmptyString,
  guardMinLengthString,
  guardXssString,
} from '@smh/utils/guards';

import { ValueObject } from '@jtnews/shared/seedwork/frontend/domain';

type AuthorizationPasswordProps = {
  value: string;
};

export class AuthorizationPasswordVO extends ValueObject<AuthorizationPasswordProps> {
  static readonly MIN_LENGTH = 3;

  private constructor(props: AuthorizationPasswordProps) {
    super(props);
  }

  get value() {
    return this.props.value;
  }

  public static create(password: string): AuthorizationPasswordVO {
    if (!guardUnspecified(password)) {
      throw Error('Необходимо заполнить поле');
    }

    if (!guardEmptyString(password)) {
      throw Error('Необходимо заполнить поле');
    }

    if (!guardMinLengthString(password, AuthorizationPasswordVO.MIN_LENGTH)) {
      throw Error('Необходимо заполнить поле');
    }

    if (!guardXssString(password)) {
      throw Error('Пароль содержит недопустимый набор символов');
    }

    return new AuthorizationPasswordVO({ value: password });
  }
}
