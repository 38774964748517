import {
  AnalyticsService,
  AnalyticsServiceConfig,
  IAnalyticsService,
} from '../analytics';

export interface IHeaderBlockAnalyticsService extends IAnalyticsService {
  sendEventAdvLogoClick(url: string): void;
  sendEventAppClick(appName: string): void;
  sendEventLogoClick(): void;
  sendEventProjectClick(projectName: string): void;
  sendEventRubricClick(rubricName: string): void;
  sendEventRubricsMenuOpen(): void;
  sendEventSearchOpen(): void;
  sendEventSendNews(): void;
}

export type HeaderBlockAnalyticsServiceConfig = AnalyticsServiceConfig;

export class HeaderBlockAnalyticsService
  extends AnalyticsService
  implements IHeaderBlockAnalyticsService
{
  constructor(config: HeaderBlockAnalyticsServiceConfig) {
    super(config);
  }

  public sendEventAppClick(appName: string) {
    this.analytics.send({
      event: {
        blockType: 'Залипающее Меню',
        fieldType: 'Рубрики',
        valueName: appName,
        pageName: this.pageName,
        goalName: 'clickMenu',
      },
    });
    this.analytics.send({
      event: {
        pageName: this.pageName,
        goalName: 'ClickMenuRubrics',
        blockType: this.blockPlace,
        actionType: 'Клик',
        prop1: 'Блок рубрик',
        prop2: appName,
      },
    });
  }

  public sendEventLogoClick() {
    this.analytics.send({
      event: {
        blockType: this.blockPlace,
        fieldType: 'Логотип',
        valueName: 'Логотип',
        pageName: this.pageName,
        goalName: 'clickMenu',
      },
    });
    this.analytics.send({
      event: {
        pageName: this.pageName,
        goalName: 'ClickMenuInformer',
        blockType: this.blockPlace,
        actionType: 'Клик',
        prop1: 'Информеры',
        prop2: 'Логотип',
      },
    });
  }

  public sendEventAdvLogoClick(url: string) {
    this.analytics.send({
      event: {
        blockType: this.blockPlace,
        fieldType: 'Рекламное лого',
        valueName: url,
        pageName: this.pageName,
        goalName: 'clickMenu',
      },
    });
  }

  public sendEventProjectClick(projectName: string) {
    this.analytics.send({
      event: {
        blockType: this.blockPlace,
        fieldType: 'Проекты',
        valueName: projectName,
        pageName: this.pageName,
        goalName: 'clickMenu',
      },
    });
    this.analytics.send({
      event: {
        pageName: this.pageName,
        goalName: 'ClickMenuProject',
        blockType: this.blockPlace,
        actionType: 'Клик',
        prop1: 'Проект',
        prop2: projectName,
      },
    });
  }

  public sendEventRubricClick(rubricName: string) {
    this.analytics.send({
      event: {
        blockType: this.blockPlace,
        fieldType: 'Рубрики',
        valueName: rubricName,
        pageName: this.pageName,
        goalName: 'clickMenu',
      },
    });
    this.analytics.send({
      event: {
        pageName: this.pageName,
        goalName: 'ClickMenuRubrics',
        blockType: this.blockPlace,
        actionType: 'Клик',
        prop1: 'Блок рубрик',
        prop2: rubricName,
      },
    });
  }

  public sendEventRubricsMenuOpen() {
    this.analytics.send({
      event: {
        blockType: this.blockPlace,
        fieldType: 'Рубрики',
        valueName: 'Открыл',
        pageName: this.pageName,
        goalName: 'clickMenu',
      },
    });
    this.analytics.send({
      event: {
        pageName: this.pageName,
        goalName: 'ClickMenuRubrics',
        blockType: this.blockPlace,
        actionType: 'Клик',
        prop1: 'Блок рубрик',
        prop2: 'Открыл',
      },
    });
  }

  public sendEventSendNews() {
    this.analytics.send({
      event: {
        blockType: this.blockPlace,
        fieldType: 'Форма связи с редакцией (Меню))',
        valueName: 'Открыта',
        pageName: this.pageName,
        goalName: 'clickMenu',
      },
    });
  }

  public sendEventSearchOpen() {
    this.analytics.send({
      event: {
        blockType: this.blockPlace,
        fieldType: 'Пользователь',
        valueName: 'Поиск',
        pageName: this.pageName,
        goalName: 'clickMenu',
      },
    });
    this.analytics.send({
      event: {
        pageName: this.pageName,
        goalName: 'ClickMenuInformer',
        blockType: this.blockPlace,
        actionType: 'Клик',
        prop1: 'Информеры',
        prop2: 'Поиск',
      },
    });
  }
}
