type VideosPageType = typeof import('@jtnews/videos/frontend/features');

export const VIDEOS_SHELL = [
  // главная страница
  {
    name: 'videos',
    path: '/videos',
    component: (): Promise<VideosPageType> =>
      import(
        /* webpackChunkName: "new-videos-page" */ '@jtnews/videos/frontend/features'
      ).then((m) => m.VideosPage) as Promise<VideosPageType>,
  },
];
