















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'UiAdvLabel',
})
export default class UiAdvLabel extends Vue {
  @Prop({
    type: Boolean,
    default: true,
  })
  isFilled!: boolean;

  @Prop({
    type: Boolean,
    default: true,
  })
  isDark!: boolean;

  @Prop({
    type: Boolean,
    default: true,
  })
  isCentral!: boolean;
}
