import { guardEmptyString } from '@smh/utils/guards';

import { AnalyticsService, IAnalyticsService } from '../analytics';
import type { AnalyticsServiceConfig } from '../analytics';

type RGData = {
  isHotNews: boolean;
  id: number;
  recordId: number;
};

export interface IHotNewsBlockAnalyticsService extends IAnalyticsService {
  sendEventArticleClick(data: RGData): void;
}

export type HotNewsBlockAnalyticsServiceConfig = AnalyticsServiceConfig;

export class HotNewsBlockAnalyticsService
  extends AnalyticsService
  implements IHotNewsBlockAnalyticsService
{
  constructor(config: HotNewsBlockAnalyticsServiceConfig) {
    super(config);
  }

  sendEventArticleClick({ isHotNews, id, recordId }: RGData): void {
    const blockName = isHotNews ? 'Срочная новость' : 'Тема дня';
    const valueName = !isHotNews && guardEmptyString(recordId.toString()) ? recordId : id;

    this.analytics.send({
      event: {
        pageName: this.pageName,
        blockType: this.blockPlace,
        fieldType: blockName,
        goalName: 'clickMenu',
        valueName,
      },
    });
  }
}
