import { guardUnspecified } from '@smh/utils/guards';

type DomainErrorCause = {
  name: string;
  stack?: string;
  message: string;
  cause?: unknown;
};

export abstract class DomainError extends Error {
  private readonly _reason: DomainErrorCause;

  abstract override name: string;

  constructor(message: string, error?: Error) {
    super(`${message}${guardUnspecified(error) ? ` [${error.message}]` : ''}`);

    if (guardUnspecified(error)) {
      const { message, name, stack, cause } = error;

      this._reason = {
        cause,
        message,
        name,
        stack,
      };

      this.stack = `${
        this.stack
          ? this.stack
              .split('\n')
              .slice(0, (this.message.match(/\n/g) || []).length + 1)
              .join('\n')
          : ''
      }
          '\n'
          ${stack || ''}`;
    }
  }

  get reason() {
    return this._reason;
  }
}
