import { IInform } from '@jtnews/shared/seedwork/frontend/domain';

export type ModalEvent = {
  key: string;
};

export type ModalDataEvent<T extends Record<string, unknown> = Record<string, unknown>> =
  ModalEvent & { data?: T };

export type ModalEventMap = {
  open: ModalDataEvent;
  close: ModalEvent;
  beforeClose: ModalEvent;
};

export interface IModalsInform extends IInform<ModalEventMap> {
  open<T extends Record<string, unknown> = Record<string, unknown>>(
    key: string,
    data?: T,
  ): void;
  close(key: string): void;
}

export const MODALS_INFORM_KEY = 'modals_inform_key';
