













import { guardUnspecified } from '@smh/utils/guards';
import { Component, Prop, Vue, Inject } from 'vue-property-decorator';

import { ADV_BLOCK_USE_CASE_KEY } from '@jtnews/shared/seedwork/frontend/application';
import type {
  AdvVM,
  IAdvBlockUseCase,
} from '@jtnews/shared/seedwork/frontend/application';
import { LazyHydrate } from '@jtnews/shared/ui';

@Component({
  name: 'AdvBlock',
  components: { LazyHydrate },
})
export default class AdvBlock extends Vue {
  @Prop({
    required: true,
    type: Number,
  })
  cmsPlace!: number;

  @Prop({
    required: true,
    type: Object as () => AdvVM,
  })
  block!: AdvVM;

  @Prop({
    default: false,
    type: Boolean,
  })
  isLeftColumn!: boolean;

  @Prop({
    default: false,
    type: Boolean,
  })
  isCentralColumn!: boolean;

  @Prop({
    default: false,
    type: Boolean,
  })
  hasBackground!: boolean;

  @Prop({
    default: false,
    type: Boolean,
  })
  hasPadding!: boolean;

  @Prop({
    default: false,
    type: Boolean,
  })
  isSticky!: boolean;

  @Prop({
    default: null,
    type: String,
  })
  containerIdPostfix: string;

  @Prop({
    default: false,
    type: Boolean,
  })
  needInjectScript: boolean;

  @Prop({
    default: null,
    type: Number,
  })
  width: number;

  @Prop({
    default: null,
    type: Number,
  })
  height: number;

  @Prop({
    default: null,
    type: Number,
  })
  wrapMaxWidth: number;

  @Prop({
    default: null,
    type: Number,
  })
  wrapMaxHeight: number;

  @Inject(ADV_BLOCK_USE_CASE_KEY)
  private readonly _useCase: IAdvBlockUseCase;

  get content() {
    return this._useCase.prepareContent({
      content: this.block.data.content,
      postfix: this.containerIdPostfix,
    });
  }

  get classes() {
    return [
      this.$style.adv,
      this.isLeftColumn ? this.$style.left : '',
      this.hasBackground ? this.$style.hasBackground : '',
      this.hasPadding ? this.$style.hasPadding : '',
      this.isCentralColumn ? this.$style.central : '',
      this.isSticky ? this.$style.sticky : '',
    ];
  }

  get styles() {
    return {
      height: guardUnspecified(this.height) ? `${this.height}px` : null,
      width: guardUnspecified(this.width) ? `${this.width}px` : null,
    };
  }

  get wrapStyles() {
    return {
      maxHeight: guardUnspecified(this.wrapMaxHeight)
        ? `${this.wrapMaxHeight}px`
        : null,
      maxWidth: guardUnspecified(this.wrapMaxWidth) ? `${this.wrapMaxWidth}px` : null,
    };
  }

  mounted() {
    if (this.needInjectScript) {
      this._useCase.inject(this.block.data.script);
    }
  }
}
