type ArticlesByDatePageType = typeof import('@jtnews/records/frontend/features');

export const ARTICLES_BY_DATE_PAGE_SHELL = [
  // страница поиска новости по дате
  {
    name: 'articlesByDatePage',
    path: '/text/:year(\\d{4})/:month(\\d{2})?/:day(\\d{2})?',
    component: (): Promise<ArticlesByDatePageType> =>
      import(
        /* webpackChunkName: "new-home-page" */ '@jtnews/records/frontend/features'
      ).then((m) => m.ArticlesByDatePage) as Promise<ArticlesByDatePageType>,
  },
];
