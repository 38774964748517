




















































import { guardUnspecified } from '@smh/utils/guards';
import { Component, Provide, Vue, Watch } from 'vue-property-decorator';
import { environment } from '@jtnews/shared/environments';

import type {
  IAdvBlockUseCase,
  IAdvertLogoUseCase,
  IBookmarksUseCase,
  ICaptcha,
  IFullscreenAdvUseCase,
  IHotNewsBlockUseCase,
  IInformerBlockUseCase,
  ILogger,
  IModalsInform,
  INewsFeedBlockUseCase,
  IPageHeaderUseCase,
  IPageUseCase,
  IThemesBlockUseCase
} from '@jtnews/shared/seedwork/frontend/application';
import {
  ADV_BLOCK_USE_CASE_KEY,
  AdvBlockUseCase,
  ADVERT_LOGO_USE_CASE_KEY,
  AdvertLogoUseCase,
  CAPTCHA_KEY,
  FULLSCREEN_ADV_USE_CASE_KEY,
  FullscreenAdvUseCase,
  HOT_NEWS_BLOCK_USE_CASE_KEY,
  HotNewsBlockUseCase,
  INFORMER_BLOCK_USE_CASE_KEY,
  InformerBlockUseCase,
  LOGGER_KEY,
  NEWS_FEED_BLOCK_USE_CASE_KEY,
  NewsFeedBlockUseCase,
  PAGE_HEADER_USE_CASE_KEY,
  PageHeaderUseCase,
  PageStoreFacade,
  PageUseCase,
  THEMES_USE_CASE_KEY,
  ThemesBlockUseCase,
  PAGE_STORE_FACADE_KEY,
  MODALS_INFORM_KEY,
  SEND_NEWS_MODAL_KEY
} from '@jtnews/shared/seedwork/frontend/application';

import type { IAppNewsClient } from '@jtnews/shared/seedwork/frontend/infrastructure';
import {
  BLOCK_TYPE_REPOSITORY_KEY,
  BlockPlaceRepository,
  configureNewsHttpClient,
  DevCaptcha,
  GReCaptcha,
  NEWS_CLIENT_KEY,
  PageHitAnalytics,
  ReachGoalAnalytics,
  ModalsInform
} from '@jtnews/shared/seedwork/frontend/infrastructure';

import type {
  AccountDTO,
  IAccountPresenter,
  INotificationUseCase,
  IPageHeaderNotificationsUseCase,
  IPageHeaderProfileUseCase,
  AccountForumsDTO,
  IAccountForumsPresenter
} from '@jtnews/users/frontend/api';
import {
  AccountApi,
  AccountPresenter,
  AccountUseCase,
  BookmarkApi,
  BOOKMARKS_USE_CASE_KEY,
  BookmarkSpecPresenter,
  BookmarkUseCase,
  LogoutApi,
  NOTIFICATION_USE_CASE_KEY,
  NotificationApi,
  NotificationUseCase,
  PAGE_HEADER_NOTIFICATIONS_USE_CASE_KEY,
  PAGE_HEADER_PROFILE_USE_CASE_KEY,
  PageHeaderNotificationsAnalyticsService,
  PageHeaderNotificationsUseCase,
  PageHeaderProfileAnalyticsService,
  PageHeaderProfileUseCase,
  UserStoreFacade,
  AccountForumsUseCase,
  AccountForumsApi,
  AccountForumsPresenter,
  LogoutForumsApi
} from '@jtnews/users/frontend/api';

import {
  PageHeaderNotifications,
  PageHeaderProfileBlocks
} from '@jtnews/users/frontend/api-features';

import {
  AdvBlock,
  AdvertLogo,
  AdvFullscreen,
  AdvStickyMobile,
  HotNewsBlock,
  PageHeader,
  ModalContainer
} from '@jtnews/shared/seedwork/frontend/features';

import type {
  IPageAnalyticsService,
  IPageHitAnalytics,
  IReachGoalAnalytics
} from '@jtnews/shared/seedwork/frontend/domain';
import { Regions } from '@jtnews/shared/seedwork/frontend/domain';
import {
  AdvertLogoAnalyticsService,
  AnalyticsBlockPlace,
  FullscreenAdvBlockAnalyticsService,
  HeaderBlockAnalyticsService,
  HotNewsBlockAnalyticsService,
  InformerBlockAnalyticsService,
  NewsFeedBlockAnalyticsService,
  PageAnalyticsService,
  REACH_GOAL_ANALYTICS_KEY,
  ThemesBlockAnalyticsService
} from '@jtnews/shared/seedwork/frontend/domain';

import { logger } from './client.container';

import type { IPushNotificationsUseCase } from '@jtnews/notifications/frontend/application';
import {
  NotificationsStoreFacade,
  PushNotificationsUseCase,
  PUSH_NOTIFICATIONS_USE_CASE_KEY
} from '@jtnews/notifications/frontend/application';
import { PushNotificationsHttpClient } from '@jtnews/shared/newsapi/push';
import { OneSignalNotifications } from '@jtnews/shared/push-notifications';
import { LazyBlock } from '@jtnews/shared/ui';
import { PushNotificationsPresenter } from '@jtnews/notifications/frontend/infrastructure';
import { PushNotificationsAnalyticsService } from '@jtnews/notifications/frontend/domain';
import {
  AUTH_FORUM_MODAL_KEY,
  AUTH_MODAL_KEY,
  BOOKMARKS_LIMIT_MODAL_KEY,
  USER_STORE_FACADE_KEY
} from '@jtnews/users/frontend/application';

const { isClientBundle, useProxy, proxyUrl } = environment;

const Footer = () =>
  import(
    /* webpackChunkName: "footer" */ '@jtnews/shared/seedwork/frontend/features/footer'
  ).then(m => m.Footer);

const SendNewsModal = () =>
  import(
    /* webpackChunkName: "send-news-modal" */ '@jtnews/shared/seedwork/frontend/features/send-news-modal'
  ).then(m => m.SendNewsModal);

const AuthModal = () =>
  import(
    /* webpackChunkName: "send-news-modal" */ '@jtnews/users/frontend/features/auth-modal'
  ).then(m => m.AuthModal);

const AuthForumsModal = () =>
  import(
    /* webpackChunkName: "auth-forums-modal" */ '@jtnews/users/frontend/features/auth-forums-modal'
  ).then(m => m.AuthForumsModal);

const BookmarkLimitModal = () =>
  import(
    /* webpackChunkName: "bookmark-limit-modal" */ '@jtnews/users/frontend/features/bookmark-limit-modal'
  ).then(m => m.BookmarkLimitModal);

@Component({
  name: 'App',
  components: {
    AdvertLogo,
    AdvBlock,
    AdvFullscreen,
    AdvStickyMobile,
    PageHeader,
    PageHeaderNotifications,
    PageHeaderProfileBlocks,
    HotNewsBlock,
    Footer,
    LazyBlock,
    ModalContainer
  }
})
export default class App extends Vue {
  @Provide(PAGE_STORE_FACADE_KEY)
  pageStoreFacade = new PageStoreFacade(this.$pinia);

  @Provide(USER_STORE_FACADE_KEY)
  userStoreFacade = new UserStoreFacade(this.$pinia);

  @Provide(LOGGER_KEY)
  logger: ILogger = logger;

  @Provide(MODALS_INFORM_KEY)
  modalsInform: IModalsInform = new ModalsInform();

  @Provide(NEWS_CLIENT_KEY)
  newsClient: IAppNewsClient = configureNewsHttpClient({
    host: useProxy ? proxyUrl : undefined,
    envType: this.pageStoreFacade.envType,
    protocol: useProxy ? 'http' : 'https',
    enableKeepAlive: true,
    maxRedirects: 2,
    timeout: 2000,
    needFilterHeaders: !isClientBundle
  });

  accountPresenter: IAccountPresenter<AccountDTO> = new AccountPresenter({
    deps: {
      logger: this.logger
    }
  });

  accountForumsPresenter: IAccountForumsPresenter<AccountForumsDTO> =
    new AccountForumsPresenter({
      deps: {
        logger: this.logger
      }
    });

  accountUseCase = new AccountUseCase({
    regionId: this.pageStoreFacade.regionId,
    deps: {
      logger: this.logger,
      getAccount: new AccountApi({
        deps: {
          client: this.newsClient
        }
      }),
      presenter: this.accountPresenter,
      store: this.userStoreFacade
    }
  });

  accountForumsUseCase = new AccountForumsUseCase({
    regionId: this.pageStoreFacade.regionId,
    deps: {
      logger: this.logger,
      getAccount: new AccountForumsApi({
        deps: {
          client: this.newsClient
        }
      }),
      presenter: this.accountForumsPresenter,
      store: this.userStoreFacade
    }
  });

  @Provide(REACH_GOAL_ANALYTICS_KEY)
  reachGoalAnalytics: IReachGoalAnalytics = new ReachGoalAnalytics({
    yandex: {
      counters: this.pageStoreFacade.analyticsSettings.yandex.countersIds
    },
    domain: `https://analytics.${this.pageStoreFacade.domain}${this.pageStoreFacade.envType}/v1/`,
    experiments: this.pageStoreFacade.experiments,
    sendOnlyByProps: this.pageStoreFacade.analyticsSettings.isExperimentalMetrics
  });

  @Provide(BLOCK_TYPE_REPOSITORY_KEY)
  blockPlaceRepository = new BlockPlaceRepository(AnalyticsBlockPlace.Central);

  pageHitAnalytics: IPageHitAnalytics = new PageHitAnalytics({
    yandex: {
      countersIds: this.pageStoreFacade.analyticsSettings.yandex.countersIds
    },
    liveInternet: {
      countersIds: this.pageStoreFacade.analyticsSettings.liveInternet.countersIds
    },
    mediascope: {
      counter: this.pageStoreFacade.analyticsSettings.mediascope.counter
    },
    google: {
      counter: this.pageStoreFacade.analyticsSettings.google.counter
    },
    deps: {
      logger: this.logger
    }
  });

  pageAnalyticsService: IPageAnalyticsService = new PageAnalyticsService({
    deps: {
      pageHitAnalytics: this.pageHitAnalytics
    }
  });

  notificationsStoreFacade: NotificationsStoreFacade = new NotificationsStoreFacade(
    this.$pinia
  );

  @Provide(PUSH_NOTIFICATIONS_USE_CASE_KEY)
  pushNotificationsUseCase: IPushNotificationsUseCase = new PushNotificationsUseCase({
    deps: {
      logger: this.logger,
      store: this.notificationsStoreFacade,
      pushNotificationsService: new OneSignalNotifications({
        ...new PushNotificationsPresenter().presentOneSignalConfig(
          this.pageStoreFacade.pushNotificationsConfig,
          this.pageStoreFacade.regionId === Regions.Arkhangelsk
        ),
        isUnsupportedPlatform: this.pageStoreFacade.deviceInfoSettings.isIOS,
        deps: { logger: this.logger }
      }),
      pushNotificationsApi: new PushNotificationsHttpClient({
        envType: this.pageStoreFacade.envType || '',
        protocol: 'https'
      }),
      pushNotificationsAnalyticsService: new PushNotificationsAnalyticsService({
        pageName: this.pageStoreFacade.pageName,
        deps: {
          blockPlaceRepository: this.blockPlaceRepository,
          analytics: this.reachGoalAnalytics
        }
      })
    },
    isUnsupportedPlatform: this.pageStoreFacade.deviceInfoSettings.isIOS,
    regionId: this.pageStoreFacade.regionId,
    isMobile: this.pageStoreFacade.isMobile,
    isAuthorized: this.userStoreFacade.isAuthorized,
    userId: this.userStoreFacade.account?.userId
  });

  @Provide(CAPTCHA_KEY)
  captcha: ICaptcha = useProxy
    ? new DevCaptcha({
        deps: {
          logger: this.logger
        }
      })
    : new GReCaptcha({
        publicKey: this.pageStoreFacade.captcha.key,
        scriptSrc: this.pageStoreFacade.captcha.scriptSrc,
        deps: {
          logger: this.logger
        }
      });

  @Provide(BOOKMARKS_USE_CASE_KEY)
  bookmarkUseCase: IBookmarksUseCase = new BookmarkUseCase({
    regionId: this.pageStoreFacade.regionId,
    deps: {
      logger: this.logger,
      api: new BookmarkApi({
        deps: {
          client: this.newsClient
        }
      }),
      store: this.userStoreFacade,
      presenter: new BookmarkSpecPresenter({
        deps: {
          logger: this.logger
        }
      }),
      modalsInform: this.modalsInform
    }
  });

  @Provide(NOTIFICATION_USE_CASE_KEY)
  notificationUseCase: INotificationUseCase = new NotificationUseCase({
    regionId: this.pageStoreFacade.regionId,
    deps: {
      store: this.userStoreFacade,
      noticesApi: new NotificationApi({
        deps: {
          client: this.newsClient
        }
      })
    }
  });

  @Provide(ADVERT_LOGO_USE_CASE_KEY)
  advertLogoUseCase: IAdvertLogoUseCase = new AdvertLogoUseCase({
    deps: {
      analyticsService: new AdvertLogoAnalyticsService({
        pageName: this.pageStoreFacade.pageName,
        deps: {
          blockPlaceRepository: this.blockPlaceRepository,
          analytics: this.reachGoalAnalytics
        }
      }),
      logger: this.logger,
      store: this.pageStoreFacade
    }
  });

  @Provide(PAGE_HEADER_USE_CASE_KEY)
  pageHeaderUseCase: IPageHeaderUseCase = new PageHeaderUseCase({
    pageName: this.pageStoreFacade.pageName,
    isMobile: this.pageStoreFacade.isMobile,
    regionId: this.pageStoreFacade.regionId,
    common: {
      subscribePageLink: this.pageStoreFacade.routesSettings.subscribePageLink,
      socials: this.pageStoreFacade.content.menuSocials,
      mobileApps: this.pageStoreFacade.content.menuMobileApps,
      currency: this.pageStoreFacade.content.currency,
      traffic: this.pageStoreFacade.content.traffic,
      weather: this.pageStoreFacade.content.weather
    },
    header: {
      rubrics: this.pageStoreFacade.content.menuRubrics,
      services: this.pageStoreFacade.content.menuServices,
      themes: this.pageStoreFacade.content.menuThemes
    },
    deps: {
      analyticsService: new HeaderBlockAnalyticsService({
        pageName: this.pageStoreFacade.pageName,
        deps: {
          blockPlaceRepository: this.blockPlaceRepository,
          analytics: this.reachGoalAnalytics
        }
      }),
      logger: this.logger,
      modalsInform: this.modalsInform
    }
  });

  @Provide(PAGE_HEADER_NOTIFICATIONS_USE_CASE_KEY)
  pageHeaderNotificationsUseCase: IPageHeaderNotificationsUseCase =
    new PageHeaderNotificationsUseCase({
      notifications: this.pageStoreFacade.content.notifications,
      notificationsPageLink: this.pageStoreFacade.routesSettings.notificationsPageLink,
      deps: {
        analyticsService: new PageHeaderNotificationsAnalyticsService({
          pageName: this.pageStoreFacade.pageName,
          deps: {
            blockPlaceRepository: this.blockPlaceRepository,
            analytics: this.reachGoalAnalytics
          }
        }),
        logger: this.logger,
        store: this.userStoreFacade,
        notificationUseCase: this.notificationUseCase,
        pushNotificationsUseCase: this.pushNotificationsUseCase,
        modalsInform: this.modalsInform
      }
    });

  @Provide(PAGE_HEADER_PROFILE_USE_CASE_KEY)
  pageHeaderProfileUseCase: IPageHeaderProfileUseCase = new PageHeaderProfileUseCase({
    regionId: this.pageStoreFacade.regionId,
    hasForums: this.pageStoreFacade.hasForums,
    deps: {
      analyticsService: new PageHeaderProfileAnalyticsService({
        pageName: this.pageStoreFacade.pageName,
        deps: {
          blockPlaceRepository: this.blockPlaceRepository,
          analytics: this.reachGoalAnalytics
        }
      }),
      logger: this.logger,
      logoutApi: new LogoutApi({
        deps: {
          client: this.newsClient
        }
      }),
      logoutForumsApi: new LogoutForumsApi({
        deps: {
          client: this.newsClient
        }
      }),
      store: this.userStoreFacade,
      notificationUseCase: this.notificationUseCase,
      modalsInform: this.modalsInform
    }
  });

  @Provide(ADV_BLOCK_USE_CASE_KEY)
  advBlockUseCase: IAdvBlockUseCase = new AdvBlockUseCase({
    deps: {
      logger: this.logger
    }
  });

  @Provide(FULLSCREEN_ADV_USE_CASE_KEY)
  fullscreenAdvUseCase: IFullscreenAdvUseCase = new FullscreenAdvUseCase({
    deps: {
      analyticsService: new FullscreenAdvBlockAnalyticsService({
        pageName: this.pageStoreFacade.pageName,
        deps: {
          blockPlaceRepository: this.blockPlaceRepository,
          analytics: this.reachGoalAnalytics
        }
      }),
      logger: this.logger
    }
  });

  @Provide(HOT_NEWS_BLOCK_USE_CASE_KEY)
  hotNewsBlockUseCase: IHotNewsBlockUseCase = new HotNewsBlockUseCase({
    deps: {
      logger: this.logger,
      analyticsService: new HotNewsBlockAnalyticsService({
        pageName: this.pageStoreFacade.pageName,
        deps: {
          blockPlaceRepository: this.blockPlaceRepository,
          analytics: this.reachGoalAnalytics
        }
      })
    }
  });

  @Provide(INFORMER_BLOCK_USE_CASE_KEY)
  informerBlockUseCase: IInformerBlockUseCase = new InformerBlockUseCase({
    deps: {
      analyticsService: new InformerBlockAnalyticsService({
        pageName: this.pageStoreFacade.pageName,
        deps: {
          blockPlaceRepository: this.blockPlaceRepository,
          analytics: this.reachGoalAnalytics
        }
      }),
      logger: this.logger
    }
  });

  @Provide(NEWS_FEED_BLOCK_USE_CASE_KEY)
  newsFeedBlockUseCase: INewsFeedBlockUseCase = new NewsFeedBlockUseCase({
    deps: {
      analyticsService: new NewsFeedBlockAnalyticsService({
        pageName: this.pageStoreFacade.pageName,
        deps: {
          blockPlaceRepository: this.blockPlaceRepository,
          analytics: this.reachGoalAnalytics
        }
      }),
      logger: this.logger
    }
  });

  @Provide(THEMES_USE_CASE_KEY)
  newsSliderBlockUseCase: IThemesBlockUseCase = new ThemesBlockUseCase({
    isMobile: this.pageStoreFacade.isMobile,
    deps: {
      analyticsService: new ThemesBlockAnalyticsService({
        pageName: this.pageStoreFacade.pageName,
        deps: {
          blockPlaceRepository: this.blockPlaceRepository,
          analytics: this.reachGoalAnalytics
        }
      }),
      logger: this.logger
    }
  });

  pageUseCase: IPageUseCase = new PageUseCase({
    hasForums: this.pageStoreFacade.hasForums,
    deps: {
      pageHitAnalyticsService: this.pageAnalyticsService,
      accountUseCase: this.accountUseCase,
      accountForumsUseCase: this.accountForumsUseCase,
      notificationUseCase: this.notificationUseCase
    }
  });

  modalsConfig = [
    {
      key: SEND_NEWS_MODAL_KEY,
      component: SendNewsModal,
      isFullscreen: this.pageStoreFacade.isMobile,
      attrs: {
        dataTest: 'send-news-modal'
      }
    },
    {
      key: AUTH_MODAL_KEY,
      component: AuthModal,
      isFullscreen: this.pageStoreFacade.isMobile,
      attrs: {
        dataTest: 'auth-modal'
      }
    },
    {
      key: AUTH_FORUM_MODAL_KEY,
      component: AuthForumsModal,
      isFullscreen: this.pageStoreFacade.isMobile,
      attrs: {
        dataTest: 'auth-forum-modal'
      }
    },
    {
      key: BOOKMARKS_LIMIT_MODAL_KEY,
      component: BookmarkLimitModal,
      isFullscreen: this.pageStoreFacade.isMobile,
      attrs: {
        dataTest: 'bookmarks-limit-modal'
      }
    }
  ];

  get styles() {
    const styles = [];

    // device classes
    if (this.pageStoreFacade.isDesktop) {
      styles.push('jtnews--device-desktop');
    }
    if (this.pageStoreFacade.isMobile) {
      styles.push('jtnews--device-mobile');
    }
    if (this.pageStoreFacade.isTablet) {
      styles.push('jtnews--device-tablet');
    }
    if (this.pageStoreFacade.isMobileOnly) {
      styles.push('jtnews--device-mobile-only');
    }

    // theme classes
    if (this.pageStoreFacade.pageStyle === 'e1') {
      styles.push('jtnews--theme-orange');
    }

    return styles.join(' ');
  }

  get isAuthorized() {
    return this.userStoreFacade.isAuthorized;
  }

  get hasHeaderAdvert() {
    return this.advertLogoUseCase.type !== 'none';
  }

  get hasStickyMobileAdvert() {
    return this.pageStoreFacade.deviceInfoSettings.isMobile;
  }

  @Watch('isAuthorized')
  onAuthChanged(value: boolean) {
    if (value && this.notificationsStoreFacade.isPushNotificationsEnabled) {
      this.pushNotificationsUseCase.processUpdateUserPushNotificationsBySettings();
    }
  }

  beforeMount() {
    this.pushNotificationsUseCase.processInitPushNotificationsService();
  }

  mounted() {
    void this.pageUseCase.processPageVisit({
      event: {
        serverDate: this.pageStoreFacade.analyticsSettings.serverDate,
        reachGoals: this.pageStoreFacade.reachGoals,
        // TODO: добавить на странице материала прокидывание recordId
        idlc: '1'
      }
    });

    if (!this.pageStoreFacade.isMobile) {
      this.pushNotificationsUseCase.processInitOneSignal();
    }

    if (this.$route.hash === '#auth') {
      const modalParams: {accountCreatingError?: string | null} = {};

      const {accountCreatingError} = this.$route.query;

      if (guardUnspecified(accountCreatingError) && !Array.isArray(accountCreatingError)) {
        modalParams.accountCreatingError = accountCreatingError;
      }

      this.pageHeaderProfileUseCase.processAuthModalOpen({accountCreatingError});

      this.$router.push({ path: this.$route.path });
    }
  }

  beforeDestroy() {
    this.pushNotificationsUseCase.processUnsubscribePushNotificationsStates();
  }
}
