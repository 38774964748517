import { AppError } from '@jtnews/shared/seedwork/frontend/application';

export class BookmarkUseCaseError extends AppError {
  name: 'BookmarkUseCaseError';

  private constructor(message: string, error?: Error) {
    super(message, error);

    this.name = 'BookmarkUseCaseError';
  }

  static of(message: string, error?: Error): BookmarkUseCaseError {
    return new BookmarkUseCaseError(message, error);
  }
}
