import { guardUnspecified } from '@smh/utils/guards';
import { defineStore, Pinia } from 'pinia';

import type {
  RecommendationsNotLazyBlockVM,
  IRecommendationsBlockStore,
} from '@jtnews/shared/seedwork/frontend/application';
import { Regions } from '@jtnews/shared/seedwork/frontend/domain';

import type {
  LoveNotLazyBlockVM,
  ILoveBlockStore,
  IAutoBlockStore,
  AutoNotLazyBlockVM,
  DoNotLazyBlockVM,
  IDoBlockStore,
  ForumsNotLazyBlockVM,
  IForumsBlockStore,
  TopArticlesNotLazyBlockVM,
  FeedbackNotLazyBlockVM,
  IFeedbackBlockStore,
  ITopArticlesBlockStore,
  IOpinionsBlockStore,
  OpinionsNotLazyBlockVM,
} from '../blocks';

import type { BasePageWithSettings } from './page.vm';

const usePageStore = defineStore('base', {
  state: () => ({} as BasePageWithSettings),
  getters: {},
  actions: {},
});

export const PAGE_STORE_FACADE_KEY = 'page_store_facade';

export class PageStoreFacade
  implements
    IAutoBlockStore,
    IDoBlockStore,
    IFeedbackBlockStore,
    IForumsBlockStore,
    ILoveBlockStore,
    IOpinionsBlockStore,
    IRecommendationsBlockStore,
    ITopArticlesBlockStore
{
  private readonly _pageStore: ReturnType<typeof usePageStore>;

  constructor(pinia: Pinia) {
    this._pageStore = usePageStore(pinia);
  }

  public get envSettings() {
    return this._pageStore.settings.env;
  }

  public get metaSettings() {
    return this._pageStore.settings.meta;
  }

  public get deviceInfoSettings() {
    return this._pageStore.settings.deviceInfo;
  }

  public get analyticsSettings() {
    return this._pageStore.settings.analytics;
  }

  public get advSettings() {
    return this._pageStore.settings.adv;
  }

  public get routesSettings() {
    return this._pageStore.settings.routes;
  }

  public get envType() {
    return this.envSettings.envType;
  }

  public get regionId() {
    return this.metaSettings.regionId;
  }

  public get pageName() {
    return this.metaSettings.pageName;
  }

  public get domain() {
    return this.metaSettings.domain;
  }

  public get isMobileOnly() {
    return this.deviceInfoSettings.isMobileOnly;
  }

  public get isMobile() {
    return this.deviceInfoSettings.isMobile;
  }

  public get isTablet() {
    return this.deviceInfoSettings.isTablet;
  }

  public get isDesktop() {
    return this.deviceInfoSettings.isDesktop;
  }

  public get experiments() {
    return this._pageStore.project.reachGoals.experiments;
  }

  public get reachGoals() {
    return this._pageStore.project.reachGoals.reachGoals;
  }

  public get pageStyle() {
    return this._pageStore.project.settings.settings.style;
  }

  public get captcha() {
    return this._pageStore.project.captcha;
  }

  public get pushNotificationsConfig() {
    return this._pageStore.project.pushes ?? undefined;
  }

  public get metatags() {
    return this._pageStore.project.metatags;
  }

  public get authLinks() {
    const { links } = this._pageStore.project.auth;

    if (typeof window === 'undefined') {
      return links;
    }

    const CURRENT_URL_PLACEHOLDER = '%CURRENT_URL%';
    const {
      authUrls: { recover, mailRegister, rules },
      socials,
    } = links;
    const { href } = document.location;

    return {
      authUrls: {
        recover: recover.replace(CURRENT_URL_PLACEHOLDER, href),
        mailRegister: mailRegister.replace(CURRENT_URL_PLACEHOLDER, href),
        rules: rules.replace(CURRENT_URL_PLACEHOLDER, href),
      },
      socials: socials.map((social) => ({
        ...social,
        link: social.link.replace(CURRENT_URL_PLACEHOLDER, href),
      })),
    };
  }

  public get headerAdv() {
    const HEADER_ADV_ID = 9901;
    return {
      id: HEADER_ADV_ID,
      data: guardUnspecified(this.content.adv)
        ? this.content.adv[HEADER_ADV_ID] ?? null
        : null,
    };
  }

  public get stickyMobileAdv() {
    const STICKY_MOBILE_ADV_ID = 11321;

    return {
      id: STICKY_MOBILE_ADV_ID,
      data: this.content.adv?.[STICKY_MOBILE_ADV_ID] ?? null,
    };
  }

  public get fullscreenAdv() {
    const FULLSCREEN_ADV_ID = 10131;
    return {
      id: FULLSCREEN_ADV_ID,
      data: guardUnspecified(this.content.adv)
        ? this.content.adv[FULLSCREEN_ADV_ID] ?? null
        : null,
    };
  }

  public get galleryAdv() {
    const IMAGE_GALLERY_ADV_ID = 10741;
    return {
      id: IMAGE_GALLERY_ADV_ID,
      data: guardUnspecified(this.content.adv)
        ? this.content.adv[IMAGE_GALLERY_ADV_ID] ?? null
        : null,
    };
  }

  public get advLogo() {
    if (!this._pageStore.content.adv?.advLogo) {
      return null;
    }
    return this._pageStore.content.adv.advLogo;
  }

  public get content() {
    return this._pageStore.content;
  }

  public get hasForums() {
    const REGIONS_WITH_FORUMS = [Regions.NizhnyNovgorod];

    return REGIONS_WITH_FORUMS.includes(this.regionId);
  }

  updateDoBlock(block: DoNotLazyBlockVM) {
    this._pageStore.content.do = block;
  }

  updateLoveBlock(block: LoveNotLazyBlockVM) {
    this._pageStore.content.love = block;
  }

  updateFeedbackBlock(block: FeedbackNotLazyBlockVM | null = null) {
    this._pageStore.content.feedback = block;
  }

  updateForumsBlock(block: ForumsNotLazyBlockVM | null = null) {
    this._pageStore.content.forums = block;
  }

  updateRecommendationsBlock(block: RecommendationsNotLazyBlockVM) {
    this._pageStore.content.recommendations = block;
  }

  updateTopArticlesBlock(block: TopArticlesNotLazyBlockVM) {
    this._pageStore.content.topArticles = block;
  }

  updateOpinionsBlock(block: OpinionsNotLazyBlockVM) {
    this._pageStore.content.opinions = block;
  }

  updateAutoBlock(block: AutoNotLazyBlockVM) {
    this._pageStore.content.auto = block;
  }
}
