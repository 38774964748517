import { guardEmptyString } from '@smh/utils/guards';

import {
  BlockPlaceRepositoryConfig,
  IHeaderBlockAnalyticsService,
} from '@jtnews/shared/seedwork/frontend/domain';

import type { IModalsInform } from '../../modals';
import { SEND_NEWS_MODAL_KEY } from '../../send-news';
import type { BlockUseCaseConfig, IBlockUseCase } from '../block-usecase';
import { BlockUseCase } from '../block-usecase';
import type { CurrencyBlockVM } from '../currency-block';
import type { MenuMobileAppsBlockVM } from '../menu-mobile-apps-block';
import type { MenuRubricsBlockVM } from '../menu-rubrics-block';
import type { MenuServicesBlockVM } from '../menu-services-block';
import type { MenuSocialsBlockVM } from '../menu-socials-block';
import type { MenuThemesBlockVM } from '../menu-themes-block';
import type { TrafficBlockVM } from '../traffic-block';
import type { WeatherBlockVM } from '../weather-block';

type HeaderData = {
  rubrics: MenuRubricsBlockVM | null;
  services: MenuServicesBlockVM | null;
  themes: MenuThemesBlockVM | null;
};

type CommonData = {
  currency: CurrencyBlockVM | null;
  mobileApps: MenuMobileAppsBlockVM | null;
  socials: MenuSocialsBlockVM | null;
  subscribePageLink: string;
  traffic: TrafficBlockVM | null;
  weather: WeatherBlockVM | null;
};

export const PAGE_HEADER_USE_CASE_KEY = 'page_header_use_case_key';

export interface IPageHeaderUseCase extends IBlockUseCase {
  readonly commonData: CommonData;
  readonly headerData: HeaderData;
  readonly isMobile: boolean;
  readonly pageName: string;
  readonly regionId: number;
  processAdvLogoClick(url: string): void;
  processAppClick(appName: string): void;
  processLogoClick(): void;
  processProjectClick(projectName: string): void;
  processRubricClick(rubricName: string): void;
  processRubricsMenuOpen(): void;
  processSearchOpen(): void;
  processSearchSubmit(event: { event: Event; value: string }): void;
  processSendNews(): void;
}

export type PageHeaderUseCaseStore = {
  account: {
    userId: number;
  } | null;
};

export type PageHeaderUseCaseConfig = BlockUseCaseConfig & {
  isMobile: boolean;
  pageName: string;
  regionId: number;
  common: CommonData;
  header: HeaderData;
  deps: {
    analyticsService: IHeaderBlockAnalyticsService;
    modalsInform: IModalsInform;
  };
};

export class PageHeaderUseCase extends BlockUseCase implements IPageHeaderUseCase {
  private readonly _analyticsService: IHeaderBlockAnalyticsService;
  private readonly _common: CommonData;
  private readonly _header: HeaderData;
  private readonly _isMobile: boolean;
  private readonly _pageName: string;
  private readonly _regionId: number;
  private readonly _modalsInform: IModalsInform;

  constructor(config: PageHeaderUseCaseConfig) {
    super(config);

    const {
      common,
      header,
      isMobile,
      pageName,
      regionId,
      deps: { analyticsService, modalsInform },
    } = config;

    this._analyticsService = analyticsService;
    this._common = common;
    this._header = header;
    this._isMobile = isMobile;
    this._pageName = pageName;
    this._regionId = regionId;
    this._modalsInform = modalsInform;
  }

  public get commonData() {
    return this._common;
  }

  public get headerData() {
    return this._header;
  }

  public get isMobile() {
    return this._isMobile;
  }

  public get pageName() {
    return this._pageName;
  }

  public get regionId() {
    return this._regionId;
  }

  public setBlockPlace(blockPlaceConfig: BlockPlaceRepositoryConfig) {
    this._analyticsService.setBlockPlace(blockPlaceConfig);
  }

  processAdvLogoClick(url: string) {
    this._analyticsService.sendEventAdvLogoClick(url);
  }

  processAppClick(appName: string) {
    this._analyticsService.sendEventAppClick(appName);
  }

  processLogoClick() {
    this._analyticsService.sendEventLogoClick();
  }

  processProjectClick(projectName: string) {
    this._analyticsService.sendEventProjectClick(projectName);
  }

  processRubricClick(rubricName: string) {
    this._analyticsService.sendEventRubricClick(rubricName);
  }

  processRubricsMenuOpen() {
    this._analyticsService.sendEventRubricsMenuOpen();
  }

  processSearchOpen() {
    this._analyticsService.sendEventSearchOpen();
  }

  processSendNews() {
    this._analyticsService.sendEventSendNews();
    this._modalsInform.open(SEND_NEWS_MODAL_KEY);
  }

  public processSearchSubmit({ event, value }: { event: Event; value: string }) {
    event.preventDefault();

    const query = guardEmptyString(value) ? `?keywords=${value}` : '';
    window.location.href = `/search/${query}`;
  }
}
