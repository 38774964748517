import type {
  BlockPlaceRepositoryConfig,
  INewsFeedBlockAnalyticsService,
} from '@jtnews/shared/seedwork/frontend/domain';

import { BlockUseCase, IBlockUseCase } from '../block-usecase';
import type { BlockUseCaseConfig } from '../block-usecase';

type RGData = {
  currentPlace: number;
  id: number;
  isCommercial: boolean;
};

export const NEWS_FEED_BLOCK_USE_CASE_KEY = 'news_feed_block_use_case_key';

export interface INewsFeedBlockUseCase extends IBlockUseCase {
  processNewsView(data: RGData): void;
  processArticleClick(data: RGData): void;
  processAllNewsClick(): void;
  processTitleClick(): void;
}

export type NewsFeedBlockUseCaseConfig = BlockUseCaseConfig & {
  deps: {
    analyticsService: INewsFeedBlockAnalyticsService;
  };
};

export class NewsFeedBlockUseCase extends BlockUseCase implements INewsFeedBlockUseCase {
  private readonly _analyticsService: INewsFeedBlockAnalyticsService;

  constructor(config: NewsFeedBlockUseCaseConfig) {
    super(config);

    const {
      deps: { analyticsService },
    } = config;

    this._analyticsService = analyticsService;
  }

  public setBlockPlace(blockPlaceConfig: BlockPlaceRepositoryConfig) {
    this._analyticsService.setBlockPlace(blockPlaceConfig);
  }

  public processAllNewsClick(): void {
    this._analyticsService.sendEventAllNewsClick();
  }

  public processArticleClick(data: RGData): void {
    this._analyticsService.sendEventArticleClick(data);
  }

  public processNewsView(data: RGData): void {
    this._analyticsService.sendEventNewsView(data);
  }

  public processTitleClick(): void {
    this._analyticsService.sendEventTitleClick();
  }
}
