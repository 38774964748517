import type { IInformerBlockAnalyticsService } from '@jtnews/shared/seedwork/frontend/domain';
import { BlockPlaceRepositoryConfig } from '@jtnews/shared/seedwork/frontend/domain';

import { BlockUseCase, IBlockUseCase } from '../block-usecase';
import type { BlockUseCaseConfig } from '../block-usecase';

export const INFORMER_BLOCK_USE_CASE_KEY = 'informer_block_use_case_key';

export type InformerBlockUseCaseConfig = BlockUseCaseConfig & {
  deps: {
    analyticsService: IInformerBlockAnalyticsService;
  };
};

export interface IInformerBlockUseCase extends IBlockUseCase {
  processBlockShown(isHeader: boolean): void;

  processLinkClicked(name: string, isHeader: boolean): void;
}

export class InformerBlockUseCase extends BlockUseCase implements IInformerBlockUseCase {
  private readonly _analyticsService: IInformerBlockAnalyticsService;

  constructor(config: InformerBlockUseCaseConfig) {
    super(config);
    const {
      deps: { analyticsService },
    } = config;

    this._analyticsService = analyticsService;
  }

  public setBlockPlace(blockPlaceConfig: BlockPlaceRepositoryConfig) {
    this._analyticsService.setBlockPlace(blockPlaceConfig);
  }

  public processBlockShown(isHeader: boolean): void {
    this._analyticsService.sendEventBlockShown(isHeader);
  }

  public processLinkClicked(name: string, isHeader: boolean): void {
    this._analyticsService.sendEventLinkClicked(name, isHeader);
  }
}
