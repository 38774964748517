import {
  guardUnspecified,
  guardEmptyString,
  guardMaxLengthString,
} from '@smh/utils/guards';

import {
  AuthorizationPasswordVO,
  AuthorizationUserEntity,
  AuthorizationUsernameVO,
  IAuthorizationAnalyticsService,
} from '@jtnews/users/frontend/domain';

import type { AccountForumsVM, IAccountForumsPresenter } from '../account-forums';

import { AuthorizationForumsUseCaseError } from './authorization-forums-usecase.error';
import type { IAuthorizationForums } from './authorization-forums.api';

export const AUTH_FORUM_MODAL_KEY = 'auth-forum-modal';

export const AUTHORIZATION_FORUMS_USE_CASE_KEY = 'authorization_forums_use_case_key';

export interface IAuthorizationForumsUseCaseStore {
  updateAccountForums: (account: AccountForumsVM) => void;
}

export interface IAuthorizationForumsUseCase {
  readonly authLoginError: string;
  readonly authPasswordError: string;
  readonly authSpecialError: {
    caption: string;
    description: string;
  } | null;
  readonly canSubmit: boolean;
  readonly hasError: boolean;
  readonly isLoading: boolean;
  readonly login: string;
  readonly password: string;
  readonly inputsValueMaxLength: number;

  authorization(input: AuthorizationUserEntity): Promise<void>;

  processCloseModalClick(): void;

  processSocialClick(name: string): void;

  processForgotClick(): void;

  processLoginInput(login: string): void;

  processPasswordInput(password: string): void;

  processAuthClick(): Promise<void>;

  processBeforeClose(): void;

  processBeforeOpen(): void;

  processEnterThrowMailClick(): void;

  processHideAuthForm(): void;
}

export type AuthorizationForumsConfig<TPresenterInputData> = {
  regionId: number;
  login?: string;
  deps: {
    analyticsService: IAuthorizationAnalyticsService;
    authorizationApi: IAuthorizationForums<TPresenterInputData>;
    presenter: IAccountForumsPresenter<TPresenterInputData>;
    store: IAuthorizationForumsUseCaseStore;
  };
};

export class AuthorizationForumsUseCase<TPresenterInputData>
  implements IAuthorizationForumsUseCase
{
  public isLoading = false;

  private _login: AuthorizationUsernameVO | null = null;

  private _password: AuthorizationPasswordVO | null = null;

  private _loginValue = '';

  private _passwordValue = '';

  private readonly _inputsValueMaxLength = 100;

  private readonly _regionId: number;

  private readonly _defLogin: string | undefined;

  private readonly _analyticsService: IAuthorizationAnalyticsService;

  private readonly _authorizationApi: IAuthorizationForums<TPresenterInputData>;

  private readonly _presenter: IAccountForumsPresenter<TPresenterInputData>;

  private readonly _store: IAuthorizationForumsUseCaseStore;

  private _authLoginError = 'Необходимо заполнить поле';

  private _authPasswordError = 'Необходимо заполнить поле';

  private _authSpecialError: { caption: string; description: string } | null = null;

  constructor(config: AuthorizationForumsConfig<TPresenterInputData>) {
    const {
      regionId,
      login,
      deps: { analyticsService, authorizationApi, store, presenter },
    } = config;

    this._regionId = regionId;
    this._analyticsService = analyticsService;
    this._defLogin = login;
    this._authorizationApi = authorizationApi;
    this._presenter = presenter;
    this._store = store;
  }

  get authPasswordError(): string {
    return this._authPasswordError;
  }

  get authLoginError(): string {
    return this._authLoginError;
  }

  get authSpecialError() {
    return this._authSpecialError;
  }

  get login(): string {
    return this._loginValue;
  }

  get password(): string {
    return this._passwordValue;
  }

  get inputsValueMaxLength(): number {
    return this._inputsValueMaxLength;
  }

  get hasError(): boolean {
    return (
      guardEmptyString(this.authPasswordError) ||
      guardEmptyString(this.authLoginError) ||
      guardUnspecified(this.authSpecialError) ||
      !guardMaxLengthString(this.login, this._inputsValueMaxLength) ||
      !guardMaxLengthString(this.password, this._inputsValueMaxLength)
    );
  }

  get canSubmit() {
    return (
      !this.isLoading &&
      !guardEmptyString(this.authPasswordError) &&
      !guardEmptyString(this.authLoginError)
    );
  }

  async authorization(input: AuthorizationUserEntity): Promise<void> {
    const { password, username } = input;

    try {
      const { data } = await this._authorizationApi.authorization({
        regionId: this._regionId,
        username: username.value,
        password: password.value,
      });

      const account = this._presenter.present({
        data,
        params: null,
      });

      this._store.updateAccountForums(account);
    } catch (e) {
      const error = e as Error & { caption?: string; description: string };
      throw AuthorizationForumsUseCaseError.of({
        caption: error.caption ?? '',
        description: error.description ?? error.message,
      });
    }
  }

  public processCloseModalClick(): void {
    this._authSpecialError = null;
    this._analyticsService.sendEventCloseModalClick();
  }

  public processSocialClick(name: string): void {
    this._analyticsService.sendEventSocialClick(name);
  }

  public processForgotClick(): void {
    this._analyticsService.sendEventForgotClick();
  }

  public processLoginInput(login: string): void {
    this._authorizationUsernameCreate(login);
    this._authorizationPasswordCreate(this.password);
  }

  public processPasswordInput(password: string): void {
    this._authorizationUsernameCreate(this.login);
    this._authorizationPasswordCreate(password);
  }

  public async processAuthClick(): Promise<void> {
    this.isLoading = true;
    if (
      guardUnspecified(this._password) &&
      guardUnspecified(this._login) &&
      guardEmptyString(this.password) &&
      guardEmptyString(this.login) &&
      guardMaxLengthString(this.login, this._inputsValueMaxLength) &&
      guardMaxLengthString(this.password, this._inputsValueMaxLength)
    ) {
      const input = AuthorizationUserEntity.create({
        username: this._login,
        password: this._password,
      });
      try {
        await this.authorization(input);
      } catch (error) {
        if (error instanceof AuthorizationForumsUseCaseError) {
          if (guardEmptyString(error.caption)) {
            this._authSpecialError = {
              caption: error.caption,
              description: error.description,
            };
          } else {
            this._authPasswordError = error.description;
            this._authLoginError = ' ';
          }
        } else {
          this._authPasswordError = (error as Error).message;
        }
      }
    }
    this.isLoading = false;
  }

  public processBeforeClose(): void {
    this._analyticsService.sendEventBeforeClose();
  }

  public processBeforeOpen(): void {
    this._init(this._defLogin);
    this._analyticsService.sendEventBeforeOpen();
  }

  public processEnterThrowMailClick(): void {
    this._analyticsService.sendEventEnterThrowMailClick();
  }

  public processHideAuthForm(): void {
    this._analyticsService.sendEventHideAuthForm();
  }

  private _authorizationPasswordCreate(password: string): void {
    try {
      this._password = AuthorizationPasswordVO.create(password);
      this._passwordValue = this._password.value;
      this._authPasswordError = '';
    } catch (e) {
      this._authPasswordError = (e as Error).message;
      this._passwordValue = password;
    }
  }

  private _authorizationUsernameCreate(login: string): void {
    try {
      this._login = AuthorizationUsernameVO.create(login);
      this._loginValue = this._login.value;
      this._authLoginError = '';
    } catch (e) {
      this._authLoginError = (e as Error).message;
      this._loginValue = login;
    }
  }

  private _init(login?: string) {
    const username = login ?? '';
    this._authorizationUsernameCreate(username);
    this._authorizationPasswordCreate('');
  }
}
