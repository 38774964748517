


















import { guardUnspecified } from '@smh/utils/guards';
import { Component as VueComponent, AsyncComponent as VueAsyncComponent } from 'vue';
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';

import {
  type ModalDataEvent,
  type IModalsInform,
  MODALS_INFORM_KEY,
} from '@jtnews/shared/seedwork/frontend/application';
import { Disposer } from '@jtnews/shared/seedwork/frontend/domain';
import { UiModal, NoSSR } from '@jtnews/shared/ui';

import { type ModalConfig } from './modal-container.contract';

@Component({
  name: 'ModalContainer',
  components: {
    UiModal,
    NoSSR,
  },
})
export default class ModalContainer extends Vue {
  @Prop({
    type: Array as () => ModalConfig[],
    required: true,
  })
  config!: ModalConfig[];

  @Inject(MODALS_INFORM_KEY)
  modalsInform!: IModalsInform;

  disposer: Disposer = new Disposer();
  currentModalKey: string | null = null;
  currentModalData?: Record<string, unknown> = {};

  get isOpen(): boolean {
    return guardUnspecified(this.currentModalKey);
  }

  get currentModalConfig(): ModalConfig | null {
    if (!guardUnspecified(this.currentModalKey)) {
      return null;
    }

    return this.config.find((config) => config.key === this.currentModalKey) ?? null;
  }

  get isFullscreen(): boolean {
    return this.currentModalConfig?.isFullscreen ?? false;
  }

  get currentModalComponent(): VueComponent | VueAsyncComponent | null {
    return this.currentModalConfig?.component ?? null;
  }

  get currentModalAttrs(): Record<string, string> | null {
    return this.currentModalConfig?.attrs ?? null;
  }

  handleOpen({ key, data }: ModalDataEvent) {
    this.currentModalKey = key;
    this.currentModalData = data;
  }

  handleClose() {
    this.currentModalKey = null;
  }

  emitClose() {
    if (guardUnspecified(this.currentModalKey)) {
      this.modalsInform.close(this.currentModalKey);
    }
  }

  mounted() {
    const handleOpen = this.handleOpen.bind(this);
    const handleClose = this.handleClose.bind(this);

    this.modalsInform.inform.on('open', handleOpen);
    this.disposer.add(() => this.modalsInform.inform.off('open', handleOpen));

    this.modalsInform.inform.on('close', handleClose);
    this.disposer.add(() => this.modalsInform.inform.off('close', handleClose));
  }

  beforeDestroy() {
    this.disposer.dispose();
  }
}
