

















import { guardExist } from '@smh/utils/guards';
import type { Component as VueComponent } from 'vue';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

import { Regions } from '@jtnews/shared/seedwork/frontend/domain';

import type {
  UiLogoExternalEventsReference,
  UiLogoLink,
  UiLogoType,
} from './ui-logo.contract';

// переменные для выставления внешних ссылок на логотип
const EXTERNAL_EVENTS_REFERENCE: UiLogoExternalEventsReference[] = [
  {
    link: '/award',
    regions: [Regions.Chelyabinsk],
    pages: ['home', 'main'],
  },
];

const AWARD_REGIONS: number[] = [Regions.Chelyabinsk];
const NY_REGIONS: number[] = [];
const SUP_REGIONS: Regions[] = [];

@Component({
  name: 'UiLogo',
})
export default class UiLogo extends Vue {
  @Prop({
    required: true,
    type: Boolean,
  })
  hasLink!: boolean;

  @Prop({
    required: true,
    type: Boolean,
  })
  isMobile!: boolean;

  @Prop({
    required: true,
    type: Number,
  })
  regionId!: number;

  @Prop({
    required: true,
    type: String,
  })
  pageName!: string;

  @Prop({
    type: String as () => UiLogoType,
    default: 'base',
  })
  theme: UiLogoType;

  get hasExternalReference(): boolean {
    return (
      EXTERNAL_EVENTS_REFERENCE.some((r) => r.link !== '') &&
      EXTERNAL_EVENTS_REFERENCE.some((r) => r.regions.includes(this.regionId)) &&
      EXTERNAL_EVENTS_REFERENCE.some((r) => r.pages.includes(this.$route.name || ''))
    );
  }

  get link(): UiLogoLink {
    const eventLink = EXTERNAL_EVENTS_REFERENCE.find((r) =>
      r.regions.includes(this.regionId),
    );

    return this.hasExternalReference && guardExist(eventLink)
      ? { href: eventLink.link, target: '_blank' }
      : { href: '/', target: '_self' };
  }

  get logoComponent(): () => Promise<VueComponent> {
    return () =>
      import(
        `./logos/${this.type}/${this.regionId}${this.isMobile ? '-mobile' : ''}.vue`
      );
  }

  get type(): UiLogoType {
    if (this.theme === 'blue') {
      return 'blue';
    }

    switch (true) {
      case AWARD_REGIONS.includes(this.regionId):
        return 'award';
      case NY_REGIONS.includes(this.regionId):
        return 'ny';
      case SUP_REGIONS.includes(this.regionId):
        return 'sup';
      default:
        return 'base';
    }
  }

  @Emit('clickedLogo')
  emitClickedLogo() {}
}
