import type {
  PageHitAnalyticsEvent,
  IPageAnalyticsService,
} from '@jtnews/shared/seedwork/frontend/domain';

import type {
  IAccountUseCase,
  INotificationUseCase,
  IAccountForumsUseCase,
} from '../users';

export interface IPageUseCase {
  processPageVisit(input: { event: PageHitAnalyticsEvent }): Promise<void>;
}

export type PageUseCaseConfig = {
  hasForums: boolean;
  deps: {
    pageHitAnalyticsService: IPageAnalyticsService;
    accountUseCase: IAccountUseCase;
    accountForumsUseCase: IAccountForumsUseCase;
    notificationUseCase: INotificationUseCase;
  };
};

export class PageUseCase implements IPageUseCase {
  private readonly _hasForums: boolean;
  private readonly _pageHitAnalyticsService: IPageAnalyticsService;

  private readonly _accountUseCase: IAccountUseCase;
  private readonly _accountForumsUseCase: IAccountForumsUseCase;

  private readonly _notificationUseCase: INotificationUseCase;

  constructor(config: PageUseCaseConfig) {
    const {
      hasForums,
      deps: {
        accountUseCase,
        pageHitAnalyticsService,
        notificationUseCase,
        accountForumsUseCase,
      },
    } = config;

    this._hasForums = hasForums;
    this._accountUseCase = accountUseCase;
    this._accountForumsUseCase = accountForumsUseCase;
    this._pageHitAnalyticsService = pageHitAnalyticsService;
    this._notificationUseCase = notificationUseCase;
  }

  async processPageVisit(input: { event: PageHitAnalyticsEvent }) {
    this._pageHitAnalyticsService.pageHit(input);

    try {
      await this._accountUseCase.account();

      void this._notificationUseCase.updateNewNoticesCount();

      if (this._hasForums) {
        await this._accountForumsUseCase.accountForums();
      }
    } catch (err) {
      // do something
    }
  }
}
