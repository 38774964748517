import { differenceInCalendarDays } from '@portal/utils/util-date';
import { guardUnspecified } from '@smh/utils/guards';

import type { IPushNotificationsUseCase } from '@jtnews/notifications/frontend/application';
import {
  BlockUseCase,
  IModalsInform,
  SEND_NEWS_MODAL_KEY,
} from '@jtnews/shared/seedwork/frontend/application';
import type {
  IBlockUseCase,
  ImageVM,
  PageNotificationVM,
  BlockUseCaseConfig,
} from '@jtnews/shared/seedwork/frontend/application';
import type { BlockPlaceRepositoryConfig } from '@jtnews/shared/seedwork/frontend/domain';
import { type INotificationUseCase } from '@jtnews/users/frontend/application';
import type { IPageHeaderNotificationsAnalyticsService } from '@jtnews/users/frontend/domain';

const TIMESTAMP_MULTIPLY = 1000;
const NEW_NOTIFICATION_DURATION = 7;
const LOCAL_STORAGE_ALERT_KEY = 'jtn_last_alert_timestamp';

export const PAGE_HEADER_NOTIFICATIONS_USE_CASE_KEY =
  'page_header_notifications_use_case_key';

export interface IPageHeaderNotificationsStore {
  readonly account: { avatar: ImageVM; notificationsLink: string } | null;
  readonly isAuthorized: boolean;
}

export interface IPageHeaderNotificationsUseCase
  extends INotificationUseCase,
    IBlockUseCase {
  hasNewNotifications: boolean;
  readonly notificationsList: PageNotificationVM[];
  readonly userAvatar: ImageVM | null;
  readonly profileNotificationsLink: string;
  readonly notificationsPageLink: string;
  readonly isAuthorized: boolean;
  readonly isPushNotificationsSubscribeAvailable: boolean;

  processMoreButtonClick(): void;
  processNotificationAnswerClick(): void;
  processNotificationLinkClick(): void;
  processToggleNotifications(): void;
  processNewNotificationsCheck(timestamp: number): void;
  processSetLastNotification(notification: PageNotificationVM): void;
}

export type PageHeaderNotificationsUseCaseConfig = BlockUseCaseConfig & {
  notifications: PageNotificationVM[];
  notificationsPageLink: string;
  deps: {
    analyticsService: IPageHeaderNotificationsAnalyticsService;
    notificationUseCase: INotificationUseCase;
    modalsInform: IModalsInform;
    store: IPageHeaderNotificationsStore;
    pushNotificationsUseCase: IPushNotificationsUseCase;
  };
};

export class PageHeaderNotificationsUseCase
  extends BlockUseCase
  implements IPageHeaderNotificationsUseCase
{
  hasNewNotifications: boolean;
  private readonly _analyticsService: IPageHeaderNotificationsAnalyticsService;
  private readonly _notificationUseCase: INotificationUseCase;
  private readonly _notifications: PageNotificationVM[];
  private readonly _notificationsPageLink: string;
  private readonly _store: IPageHeaderNotificationsStore;
  private readonly _modalsInform: IModalsInform;
  private readonly _pushNotificationsUseCase: IPushNotificationsUseCase;

  constructor(config: PageHeaderNotificationsUseCaseConfig) {
    super(config);

    const {
      notifications,
      notificationsPageLink,
      deps: {
        analyticsService,
        notificationUseCase,
        store,
        modalsInform,
        pushNotificationsUseCase,
      },
    } = config;

    this.hasNewNotifications = false;
    this._analyticsService = analyticsService;
    this._notificationUseCase = notificationUseCase;
    this._notifications = notifications;
    this._notificationsPageLink = notificationsPageLink;
    this._store = store;
    this._modalsInform = modalsInform;
    this._pushNotificationsUseCase = pushNotificationsUseCase;
  }

  public get userAvatar() {
    return this._store.account?.avatar ?? null;
  }

  public get profileNotificationsLink(): string {
    return this._store.account?.notificationsLink ?? '';
  }

  public get isAuthorized() {
    return this._store.isAuthorized;
  }

  public get notificationsPageLink() {
    return this._notificationsPageLink;
  }

  public get notificationsList() {
    return this._notifications;
  }

  public get hasNewNotices() {
    return this._notificationUseCase.hasNewNotices;
  }

  public get newNotificationsCount() {
    return this._notificationUseCase.newNotificationsCount;
  }

  public get newNoticesButtonText() {
    return this._notificationUseCase.newNoticesButtonText;
  }

  public get isPushNotificationsSubscribeAvailable(): boolean {
    return this._pushNotificationsUseCase.isSubscribeAvailable;
  }

  public setBlockPlace(blockPlaceConfig: BlockPlaceRepositoryConfig) {
    this._analyticsService.setBlockPlace(blockPlaceConfig);
  }

  public updateNewNoticesCount() {
    return this._notificationUseCase.updateNewNoticesCount();
  }

  public processMoreButtonClick() {
    this._analyticsService.sendEventMoreButtonClick();
  }

  public processNotificationLinkClick() {
    this._analyticsService.sendEventNotificationLinkClick();
  }

  public processNotificationAnswerClick() {
    this._analyticsService.sendEventNotificationAnswerClick();
    this._modalsInform.open(SEND_NEWS_MODAL_KEY);
  }

  public processToggleNotifications() {
    this._analyticsService.sendEventToggleNotifications();

    this._pushNotificationsUseCase.processShowNativePrompt();
  }

  public processSetLastNotification(notification: PageNotificationVM) {
    localStorage.setItem(LOCAL_STORAGE_ALERT_KEY, notification.timestamp.toString());
  }

  public processNewNotificationsCheck(timestamp: number) {
    if (timestamp === 0) {
      return;
    }

    try {
      const daysSinceLastAlert = differenceInCalendarDays(
        Date.now(),
        timestamp * TIMESTAMP_MULTIPLY,
      );

      if (daysSinceLastAlert > NEW_NOTIFICATION_DURATION) {
        return;
      }
    } catch (e) {
      return;
    }

    const savedAlertTimestamp = localStorage.getItem(LOCAL_STORAGE_ALERT_KEY);
    if (!guardUnspecified(savedAlertTimestamp)) {
      this.hasNewNotifications = true;
      return;
    }

    if (timestamp > Number(savedAlertTimestamp)) {
      this.hasNewNotifications = true;
    }
  }
}
