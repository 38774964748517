import {
  guardUnspecified,
  guardEmptyString,
  guardMinLengthString,
  guardXssString,
} from '@smh/utils/guards';

import { ValueObject } from '@jtnews/shared/seedwork/frontend/domain';

type AuthorizationUsernameProps = {
  value: string;
};

export class AuthorizationUsernameVO extends ValueObject<AuthorizationUsernameProps> {
  static readonly MIN_LENGTH = 3;

  private constructor(props: AuthorizationUsernameProps) {
    super(props);
  }

  get value(): string {
    return this.props.value;
  }

  public static create(username: string): AuthorizationUsernameVO {
    if (!guardUnspecified(username)) {
      throw Error('Необходимо заполнить поле');
    }

    if (!guardEmptyString(username)) {
      throw Error('Необходимо заполнить поле');
    }

    if (!guardMinLengthString(username, AuthorizationUsernameVO.MIN_LENGTH)) {
      throw Error('Необходимо заполнить поле');
    }

    if (!guardXssString(username)) {
      throw Error('Логин содержит недопустимый набор символов');
    }

    return new AuthorizationUsernameVO({ value: username });
  }
}
