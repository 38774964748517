


























import { guardUnspecified } from '@smh/utils/guards';
import { Component, Prop, Vue } from 'vue-property-decorator';

import type { AdvVM } from '@jtnews/shared/seedwork/frontend/application';
import { LazyHydrate } from '@jtnews/shared/ui';

const CLOSE_DELAY_TIME_MS = 4_000;

@Component({
  name: 'AdvStickyMobile',
  components: { LazyHydrate },
})
export default class AdvBlock extends Vue {
  @Prop({
    required: true,
    type: Number,
  })
  cmsPlace!: number;

  @Prop({
    required: true,
    type: Object,
  })
  block!: AdvVM;

  disposables: CallableFunction[] = [];

  isHidden = true;
  isClosed = false;
  isCloseBtnVisible = false;
  hasAdv = false;
  isBottomIntend = false;

  advObserver?: MutationObserver;
  get isHiddenAdv() {
    return this.isClosed || this.isHidden || !this.hasAdv;
  }

  beforeMount() {
    this.advObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.addedNodes.length > 0) {
          this.hasAdv = true;
          this.advObserver?.disconnect();
        }
      });
    });
  }

  mounted() {
    const config = {
      attributes: false,
      childList: true,
      characterData: false,
      subtree: true,
    };

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const innerAdvElement = (this.$refs.adv as HTMLDivElement).querySelector(
      'div > div',
    )!;
    const existingAdvElement = innerAdvElement.querySelector('*');

    if (
      guardUnspecified(existingAdvElement) &&
      existingAdvElement.childNodes.length > 0
    ) {
      this.hasAdv = true;
    } else if (guardUnspecified(innerAdvElement)) {
      this.advObserver?.observe(innerAdvElement, config);
      this.disposables.push(() => this.advObserver?.disconnect());
    }

    this.checkShouldDisplayAdv();

    const checkShouldDisplayAdv = () => this.checkShouldDisplayAdv();
    window.addEventListener('scroll', checkShouldDisplayAdv);
    this.disposables.push(() =>
      window.removeEventListener('scroll', checkShouldDisplayAdv),
    );
  }

  beforeDestroy() {
    this.disposables.forEach((dispose) => void dispose());
  }

  checkShouldDisplayAdv() {
    const pageYOffset = window.pageYOffset ?? document.documentElement.scrollTop;
    const screenHeight = window.innerHeight;
    const otherStickyEl = document.querySelector(
      '[data-sticky-id="bottomStickyElement"]',
    );

    if (guardUnspecified(otherStickyEl)) {
      this.isBottomIntend = otherStickyEl.getAttribute('data-sticked') === 'true';
    }
    if (pageYOffset > screenHeight && !this.isUnallowedAdvReferrer()) {
      this.isHidden = false;
      this.planCloseBtnShow();
    }
  }

  planCloseBtnShow() {
    const timeoutId = setTimeout(
      () => (this.isCloseBtnVisible = true),
      CLOSE_DELAY_TIME_MS,
    );

    this.disposables.push(() => clearTimeout(timeoutId));
  }

  closeAdv() {
    this.isClosed = true;
    this.isBottomIntend = false;
  }

  isUnallowedAdvReferrer() {
    const unallowedAdvReferrers = [
      'iframe-toloka.com',
      'raterhub.com',
      'iframe-tasks.yandex',
      'iframe-yang.yandex',
      'yandex-team.ru',
    ];
    const referrer =
      new URLSearchParams(window.location.search).get('_testReferrer') ||
      document.referrer;

    return unallowedAdvReferrers.some((domain) => referrer.includes(domain));
  }
}
