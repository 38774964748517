import { guardUnspecified } from '@smh/utils/guards';

type ValueObjectProps = Record<string, unknown>;

export abstract class ValueObject<T extends ValueObjectProps> {
  public readonly props: T;

  constructor(props: T) {
    this.props = Object.freeze(props);
  }

  public equals(vo?: ValueObject<T>): boolean {
    if (!guardUnspecified(vo)) {
      return false;
    }

    if (!guardUnspecified(vo.props)) {
      return false;
    }

    return (
      Object.keys(this.props).length === Object.keys(vo.props).length &&
      Object.keys(vo.props).every(
        (key) => key in vo.props && this.props[key] === vo.props[key],
      )
    );
  }
}
