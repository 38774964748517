import { guardUnspecified } from '@smh/utils/guards';
import { v4 as uuidv4 } from 'uuid';

import { Identifier } from './identifier';

export class UniqueEntityID extends Identifier<string | number> {
  constructor(id?: string | number) {
    super(guardUnspecified(id) ? id : uuidv4());
  }
}
