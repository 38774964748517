import { getCookie } from '@smh/utils/cookie';
import { injectScript } from '@smh/utils/dom';
import { Md5 } from '@smh/utils/md5';

import type { ICaptcha, ILogger } from '@jtnews/shared/seedwork/frontend/application';
import { CaptchaError } from '@jtnews/shared/seedwork/frontend/application';

type SetupStatus = 'installed' | 'error';

export type CaptchaConfig = {
  scriptSrc: string;
  timeout?: number;
  deps: {
    logger: ILogger;
  };
};

export const CAPTCHA_KEY = 'captcha_key';

export abstract class Captcha implements ICaptcha {
  private readonly _logger: ILogger;

  private _setupStatus: SetupStatus;

  private readonly _scriptSrc: string;

  private readonly _timeout: number;

  constructor(config: CaptchaConfig) {
    const {
      scriptSrc,
      timeout = 2000,
      deps: { logger },
    } = config;

    this._scriptSrc = scriptSrc;
    this._timeout = timeout;
    this._logger = logger;
  }

  protected get logger() {
    return this._logger;
  }

  protected get setupStatus() {
    return this._setupStatus;
  }

  protected get timeout() {
    return this._timeout;
  }

  protected setup() {
    return new Promise<SetupStatus>((resolve, reject) => {
      if (this._setupStatus === 'installed') {
        resolve(this._setupStatus);
        return;
      }

      const timeoutId = setTimeout(
        () =>
          reject(
            CaptchaError.of(
              `Не удалось сделать Captcha setup из-за timeout=${this._timeout}`,
            ),
          ),
        this._timeout,
      );

      injectScript({
        src: this._scriptSrc,
      })
        .then((_) => {
          this._setupStatus = 'installed';
          resolve(this._setupStatus);
        })
        .catch((err) => {
          this._setupStatus = 'error';
          reject(CaptchaError.of('Не удалось выполнить Captcha prepare', err as Error));
        })
        .finally(() => {
          clearTimeout(timeoutId);
        });
    });
  }

  protected getFallbackToken() {
    const userCookie = getCookie('ngs_uid') || '';
    const { userAgent } = window.navigator;

    return Md5.hashStr(`${userAgent}${userCookie}`);
  }

  abstract execute(input: string): Promise<string>;
}
