import { guardUnspecified } from '@smh/utils/guards';
import { defineStore, Pinia } from 'pinia';

import type { IUserStore } from '@jtnews/shared/seedwork/frontend/application';
import type {
  BookmarkVM,
  IBookmarkUseCaseUserStore,
  AccountVM,
  IAccountUseCaseUserStore,
  AccountForumsVM,
} from '@jtnews/users/frontend/application';

type UserState = {
  account: AccountVM | null;
  accountForums: AccountForumsVM | null;
};

const useUserStore = defineStore('user', {
  state: () =>
    ({
      account: null,
      accountForums: null,
    } as UserState),
  getters: {},
  actions: {},
});

export const USER_STORE_FACADE_KEY = 'user-store-facade-key';

export class UserStoreFacade
  implements IUserStore, IAccountUseCaseUserStore, IBookmarkUseCaseUserStore
{
  private readonly _userStore: ReturnType<typeof useUserStore>;

  constructor(pinia: Pinia) {
    this._userStore = useUserStore(pinia);
  }

  public get account() {
    return this._userStore.account;
  }

  public get accountForums() {
    return this._userStore.accountForums;
  }

  public get isAuthorized() {
    return guardUnspecified(this._userStore.account);
  }

  updateAccount(account: AccountVM) {
    this._userStore.account = account;
  }

  updateAccountForums(account: AccountForumsVM) {
    this._userStore.accountForums = account;
  }

  hasUserBookmark(articleId: number) {
    return guardUnspecified(this.account)
      ? this.account.bookmarks.some((bookmark) => bookmark.bookmark.id === articleId)
      : false;
  }

  findBookmarkByArticleId(articleId: number): BookmarkVM | null {
    if (guardUnspecified(this.account)) {
      return (
        this.account.bookmarks.find((item) => item.bookmark.id === articleId) ?? null
      );
    }

    return null;
  }

  addBookmark(bookmark: BookmarkVM) {
    if (!guardUnspecified(this._userStore.account)) {
      return;
    }
    this._userStore.account.bookmarks.push(bookmark);
  }

  deleteBookmark(bookmarkId: string) {
    if (!guardUnspecified(this._userStore.account)) {
      return;
    }
    this._userStore.account.bookmarks = this._userStore.account.bookmarks.filter(
      (bookmark) => bookmark.id !== bookmarkId,
    );
  }
}
