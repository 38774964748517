import { AppError } from '@jtnews/shared/seedwork/frontend/application';

export class BookmarkLimitError extends AppError {
  name: 'BookmarkLimitError';

  private constructor(message: string, error?: Error) {
    super(message, error);

    this.name = 'BookmarkLimitError';
  }

  static of(message: string, error?: Error): BookmarkLimitError {
    return new BookmarkLimitError(message, error);
  }
}
