import { DomainError } from '../domain-error';

export class PageHitAnalyticsError extends DomainError {
  name: 'PageHitAnalyticsError';

  private constructor(message: string, error?: Error) {
    super(message, error);

    this.name = 'PageHitAnalyticsError';
  }

  static of(message: string, error?: Error) {
    return new PageHitAnalyticsError(message, error);
  }
}
