import { NewsClient } from '@jtnews/shared/seedwork/frontend/infrastructure';
import type { IAppNewsClient } from '@jtnews/shared/seedwork/frontend/infrastructure';
import { IAccountForums } from '@jtnews/users/frontend/application';

import { AccountForumsDTO } from './account-forums.dto';

type AccountApiConfig = {
  deps: {
    client: IAppNewsClient;
  };
};

export type AccountForumsApiInput = {
  regionId: number;
};

export class AccountForumsApi
  extends NewsClient
  implements IAccountForums<AccountForumsDTO>
{
  static readonly FORUMS_NN_AUTHORIZATION_URL = '/jtnews/profile/';
  static readonly FORUMS_NN_AUTHORIZATION_DOMAIN = 'www.nn.ru';

  constructor(config: AccountApiConfig) {
    super(config);
  }

  async accountForums(input: AccountForumsApiInput) {
    const { regionId } = input;

    const { data } = await this.client.get<AccountForumsDTO>(
      regionId,
      AccountForumsApi.FORUMS_NN_AUTHORIZATION_URL,
      {
        withCredentials: true,
        headers: {
          accept: this.client.accept,
        },
      },
      AccountForumsApi.FORUMS_NN_AUTHORIZATION_DOMAIN,
    );

    return {
      data,
    };
  }
}
