import { guardUnspecified, guardMaxNumber } from '@smh/utils/guards';

import { ValueObject } from '../value-object';

type SendNewsFilesProps = {
  value: File[];
};

export class SendNewsFilesVOError extends Error {
  override name: string;

  private constructor(message: string, error?: Error) {
    super(message, error);

    this.name = 'SendNewsFilesVOError';
  }

  static of(message: string, error?: Error) {
    return new SendNewsFilesVOError(message, error);
  }
}

export class SendNewsFilesVO extends ValueObject<SendNewsFilesProps> {
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  static readonly MAX_FILE_SIZE_IN_B = 25 * 1024 * 1024;

  static readonly AVAILABLE_TYPES = [
    'application/pdf',
    'image/gif',
    'image/jpeg',
    'image/png',
    'text/plain',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ];

  private constructor(props: SendNewsFilesProps) {
    super(props);
  }

  get value() {
    return this.props.value;
  }

  public static create(files: File[]): SendNewsFilesVO {
    if (!guardUnspecified(files)) {
      throw SendNewsFilesVOError.of('Прикреплён повреждённый файл');
    }

    const params: string[] = [];
    let commonSize = 0;

    files.forEach((file) => {
      if (!guardUnspecified(file)) {
        throw SendNewsFilesVOError.of('Прикреплён повреждённый файл');
      }

      commonSize += file.size;

      if (!guardMaxNumber(commonSize, SendNewsFilesVO.MAX_FILE_SIZE_IN_B)) {
        throw SendNewsFilesVOError.of('Общий размер файлов не должен превышать 25 МБ');
      }

      if (!SendNewsFilesVO.AVAILABLE_TYPES.includes(file.type)) {
        throw SendNewsFilesVOError.of('Прикреплён файл недопустимого типа');
      }

      const currentParams = `${file.name}_${file.size}_${file.lastModified}`;

      if (params.includes(currentParams)) {
        throw SendNewsFilesVOError.of('Выбранный файл уже загружен');
      }

      params.push(currentParams);
    });

    return new SendNewsFilesVO({ value: files });
  }
}
