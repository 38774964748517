import type { OneSignalParams } from '@smh/one-signal';

import { ProjectPushesVM } from '@jtnews/shared/seedwork/frontend/application';

export interface IPushNotificationsPresenter {
  presentOneSignalConfig: (
    config: ProjectPushesVM | undefined,
    hasSW: boolean,
  ) => {
    appId: string;
    segment: {
      key: string;
    };
    swParams?:
      | {
          param: {
            scope: string;
          };
          path: string;
        }
      | undefined;
  } | null;
}

export class PushNotificationsPresenter implements IPushNotificationsPresenter {
  presentOneSignalConfig(
    config: ProjectPushesVM | undefined,
    hasSW: boolean,
  ): OneSignalParams & { segment: { key: string } } {
    return {
      appId: config?.appId ?? '',
      segment: {
        key: config?.segment ?? '',
      },
      promptSettings: {
        actionMessage: 'Включите уведомления, чтобы не пропустить важные новости',
        acceptButton: 'Включить',
        cancelButton: 'Не сейчас',
      },
      welcomeNotification: {
        title: 'Спасибо за подписку на новости!',
        message: 'Теперь вы не пропустите главное',
      },
      swParams: hasSW
        ? {
            param: {
              scope: '/push/onesignal/',
            },
            path: 'push/onesignal/OneSignalSDKWorker.js',
          }
        : undefined,
    };
  }
}
