import {
  IAppNewsClient,
  NewsClient,
} from '@jtnews/shared/seedwork/frontend/infrastructure';
import { ILogout } from '@jtnews/users/frontend/application';

type LogoutApiConfig = {
  deps: {
    client: IAppNewsClient;
  };
};

export type LogoutForumsApiInput = {
  regionId: number;
};

export class LogoutForumsApi extends NewsClient implements ILogout {
  static readonly FORUMS_NN_LOGOUT_DOMAIN = 'www.nn.ru';
  static readonly FORUMS_NN_LOGOUT_URL = '/jtnews/session/';

  constructor(config: LogoutApiConfig) {
    super(config);
  }

  async logout(input: LogoutForumsApiInput) {
    const { regionId } = input;

    await this.client.delete(
      regionId,
      LogoutForumsApi.FORUMS_NN_LOGOUT_URL,
      {
        withCredentials: true,
        headers: {
          accept: this.client.accept,
        },
      },
      LogoutForumsApi.FORUMS_NN_LOGOUT_DOMAIN,
    );
  }
}
