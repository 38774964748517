import { CaptchaError } from '@jtnews/shared/seedwork/frontend/application';

import { Captcha, CaptchaConfig } from './captcha';

type DevCaptchaConfig = Pick<CaptchaConfig, 'deps'>;

export class DevCaptcha extends Captcha {
  constructor(config: DevCaptchaConfig) {
    super({
      scriptSrc: '',
      deps: config.deps,
    });
  }

  async execute(): Promise<string> {
    return new Promise((resolve, reject) => {
      try {
        resolve(this.getFallbackToken());
      } catch (error) {
        reject(CaptchaError.of((error as Error).message));
      }
    });
  }
}
