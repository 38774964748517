






































import { Portal, setSelector } from '@linusborg/vue-simple-portal';
import { lock, unlock } from '@smh/utils/body-scroll-lock';
import { guardUnspecified, guardEmptyString } from '@smh/utils/guards';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';

import type { Theme } from './ui-modal.contract';

@Component({
  name: 'UiModal',
  components: {
    Portal,
  },
  model: {
    prop: 'isOpen',
    event: 'emitToggle',
  },
})
export default class UiModal extends Vue {
  @Prop({
    default: true,
    type: Boolean,
  })
  isOpen!: boolean;

  @Prop({
    default: 'body',
    type: String,
  })
  appendTo!: string;

  @Prop({
    default: '',
    type: String,
  })
  targetName!: string;

  @Prop({
    default: '',
    type: String,
  })
  dataTest!: string;

  @Prop({
    default: false,
    type: Boolean,
  })
  isFullscreen: boolean;

  @Prop({
    default: true,
    type: Boolean,
  })
  isShowCloseBtn: boolean;

  @Prop({
    default: 'light',
    type: String as () => Theme,
  })
  theme: Theme;

  needRender = false;
  isScrollable = false;

  get selector(): string {
    return guardEmptyString(this.targetName)
      ? `jtn-vm-${this.targetName}`
      : 'modal_place';
  }

  @Emit('beforeOpen')
  emitBeforeOpen() {}

  @Emit('beforeClose')
  emitBeforeClose() {}

  @Emit('opened')
  emitOpened() {}

  @Emit('closed')
  emitClosed() {}

  @Emit('toggle')
  emitToggle(isOpen: boolean) {
    return isOpen;
  }

  @Watch('isOpen')
  onOpen(value: boolean) {
    this.needRender = value;
    if (value) {
      this.emitBeforeOpen();
    } else {
      this.emitBeforeClose();
    }
  }

  @Watch('needRender')
  onNeedRender(value: boolean) {
    if (value) {
      // eslint-disable-next-line @typescript-eslint/unbound-method
      document.addEventListener('keydown', this.keydownHandler);
      lock();

      this.$nextTick(() => {
        this.setScrollable();
        this.emitOpened();
      });

      return;
    }

    // eslint-disable-next-line @typescript-eslint/unbound-method
    document.removeEventListener('keydown', this.keydownHandler);
    unlock();

    this.$nextTick(() => {
      this.$emit('closed');
      this.emitClosed();
    });
  }

  beforeMount() {
    setSelector(this.selector);
    this.needRender = this.isOpen;
  }

  beforeDestroy() {
    unlock();
  }

  close() {
    this.emitToggle(false);
  }

  setScrollable() {
    const modal = this.$refs.modal as HTMLElement;
    if (guardUnspecified(modal)) {
      this.isScrollable = modal.scrollHeight > modal.clientHeight;
    }
  }

  keydownHandler(event: KeyboardEvent) {
    if (['Escape', 'Esc'].includes(event.key)) {
      this.close();
    }
  }
}
