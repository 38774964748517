import {
  IAppNewsClient,
  NewsClient,
} from '@jtnews/shared/seedwork/frontend/infrastructure';
import { ILogout } from '@jtnews/users/frontend/application';

type LogoutApiConfig = {
  deps: {
    client: IAppNewsClient;
  };
};

export type LogoutApiInput = {
  regionId: number;
};

export class LogoutApi extends NewsClient implements ILogout {
  static readonly LOGOUT_URL = '/public/account/logout/';

  constructor(config: LogoutApiConfig) {
    super(config);
  }

  async logout(input: LogoutApiInput) {
    const { regionId } = input;

    await this.client.post(regionId, LogoutApi.LOGOUT_URL, undefined, {
      withCredentials: true,
      headers: {
        accept: this.client.accept,
      },
    });
  }
}
