import { guardUnspecified } from '@smh/utils/guards';

export class Identifier<T> {
  constructor(private readonly _value: T) {}

  equals(id?: Identifier<T>): boolean {
    if (!guardUnspecified(id)) {
      return false;
    }
    if (!(id instanceof this.constructor)) {
      return false;
    }
    return id.toValue() === this._value;
  }

  toString() {
    return String(this._value);
  }

  toValue(): T {
    return this._value;
  }
}
