
































import { guardUnspecified } from '@smh/utils/guards';
import { Component, Prop, Vue } from 'vue-property-decorator';

import { UiAdvLabel } from '../ui-adv-label';

import { AspectRatio } from './ui-image.contract';
import type { Image } from './ui-image.contract';

@Component({
  name: 'UiImage',
  components: { UiAdvLabel },
})
export default class UiImage extends Vue {
  @Prop({
    required: true,
    type: Object as () => Image | null,
  })
  image: Image | null;

  @Prop({
    type: String,
    default: '',
  })
  alt: string;

  @Prop({
    type: Number,
    required: false,
  })
  width: number;

  @Prop({
    type: Number,
    required: false,
  })
  height: number;

  @Prop({
    type: String,
    default: '',
  })
  imgClass: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  hasLabelTopPosition: boolean;

  get hasCommercialLabel() {
    return guardUnspecified(this.image) && this.image.hasCommercialLabel;
  }

  get aspectRatioClass() {
    if (guardUnspecified(this.image)) {
      switch (this.image.aspectRatio) {
        case AspectRatio.Square:
          return 'aspectRatioSquare';
        case AspectRatio.Classic:
          return 'aspectRatioClassic';
        case AspectRatio.Wide:
          return 'aspectRatioWide';
        case AspectRatio.VerticalClassic:
          return 'aspectRatioVerticalClassic';
        case AspectRatio.VerticalWide:
          return 'aspectRatioVerticalWide';
        default:
          return '';
      }
    }

    return '';
  }

  get imgWidth() {
    return this.width ?? '100%';
  }

  get wrapperStyles() {
    const styles: { width: string; height?: string } = {
      width: this.width ? `${this.width}px` : '100%',
    };

    if (this.height) {
      styles.height = `${this.height}px`;
    }

    return styles;
  }
}
