import { guardUnspecified } from '@smh/utils/guards';

import type { ApiErrorDTO } from '@jtnews/shared/newsapi/dtos';
import {
  NewsClient,
  NewsApiClientError,
} from '@jtnews/shared/seedwork/frontend/infrastructure';
import type {
  IAppNewsClient,
  IAppNewsClientRequestConfig,
} from '@jtnews/shared/seedwork/frontend/infrastructure';
import type { IBookmarkApi } from '@jtnews/users/frontend/application';
import { BookmarkLimitError } from '@jtnews/users/frontend/application';
import type { BookmarkApiResponseDTO } from '@jtnews/users/frontend/infrastructure';

type BookmarkApiConfig = {
  deps: {
    client: IAppNewsClient;
  };
};

const BOOKMARK_LIMIT_ERROR_TYPE = 'LimitExceeded';

type BookmarkApiErrorResponse = {
  response: {
    data: ApiErrorDTO;
  };
};

export type AddBookmarkApiInput = {
  regionId: number;
  profileId: number;
  articleId: number;
  type: string;
};

export type DeleteBookmarkApiInput = {
  regionId: number;
  profileId: number;
  bookmarkId: string;
};

export class BookmarkApi
  extends NewsClient
  implements IBookmarkApi<BookmarkApiResponseDTO>
{
  constructor(config: BookmarkApiConfig) {
    super(config);
  }

  async add(input: AddBookmarkApiInput) {
    try {
      const { articleId, profileId, regionId, type } = input;

      const url = `/public/users/${profileId}/bookmarks/`;

      const config: IAppNewsClientRequestConfig = {
        withCredentials: true,
        headers: {
          accept: this.client.accept,
        },
      };

      const { data } = await this.client.post<BookmarkApiResponseDTO>(
        regionId,
        url,
        {
          regionId,
          recordId: articleId,
          type,
        },
        config,
      );

      return data;
    } catch (err) {
      if (err instanceof NewsApiClientError) {
        const error = err as BookmarkApiErrorResponse;

        const limitExceededErr = error.response.data.errors?.find(
          (item) => item.name === BOOKMARK_LIMIT_ERROR_TYPE,
        );

        if (guardUnspecified(limitExceededErr)) {
          throw BookmarkLimitError.of(limitExceededErr.reason);
        }
      }

      throw err;
    }
  }

  async delete(input: DeleteBookmarkApiInput) {
    const { regionId, profileId, bookmarkId } = input;

    const url = `/public/users/${profileId}/bookmarks/${bookmarkId}`;

    const config: IAppNewsClientRequestConfig = {
      withCredentials: true,
      headers: {
        accept: this.client.accept,
      },
      params: {
        regionId,
      },
    };

    await this.client.delete(regionId, url, config);
  }
}
