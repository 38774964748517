import type {
  AnalyticsServiceConfig,
  IAnalyticsService,
} from '@jtnews/shared/seedwork/frontend/domain';
import { AnalyticsService } from '@jtnews/shared/seedwork/frontend/domain';

export type PageHeaderProfileAnalyticsServiceConfig = AnalyticsServiceConfig;

export interface IPageHeaderProfileAnalyticsService extends IAnalyticsService {
  sendEventBookmarksClick(): void;
  sendEventLogout(): void;
  sendEventProfileClick(): void;
  sendEventProfileTriggerClick(): void;
  sendEventSettingsClick(): void;
}

export class PageHeaderProfileAnalyticsService
  extends AnalyticsService
  implements IPageHeaderProfileAnalyticsService
{
  constructor(config: PageHeaderProfileAnalyticsServiceConfig) {
    super(config);
  }

  sendEventBookmarksClick(): void {
    this.analytics.send({
      event: {
        blockType: this.blockPlace,
        fieldType: 'Пользователь',
        valueName: 'Закладки',
        pageName: this.pageName,
        goalName: 'clickMenu',
      },
    });
    this.analytics.send({
      event: {
        pageName: this.pageName,
        goalName: 'ClickMenuProfile',
        blockType: this.blockPlace,
        actionType: 'Клик',
        prop1: 'Профиль',
        prop2: 'Закладки',
      },
    });
  }

  sendEventLogout(): void {
    this.analytics.send({
      event: {
        blockType: this.blockPlace,
        fieldType: 'Пользователь',
        valueName: 'Выход',
        pageName: this.pageName,
        goalName: 'clickMenu',
      },
    });
    this.analytics.send({
      event: {
        pageName: this.pageName,
        goalName: 'ClickMenuProfile',
        blockType: this.blockPlace,
        actionType: 'Клик',
        prop1: 'Профиль',
        prop2: 'Выход',
      },
    });
  }

  sendEventProfileClick(): void {
    this.analytics.send({
      event: {
        blockType: this.blockPlace,
        fieldType: 'Пользователь',
        valueName: 'Профиль',
        pageName: this.pageName,
        goalName: 'clickMenu',
      },
    });
    this.analytics.send({
      event: {
        pageName: this.pageName,
        goalName: 'ClickMenuProfile',
        blockType: this.blockPlace,
        actionType: 'Клик',
        prop1: 'Профиль',
        prop2: 'Профиль',
      },
    });
  }

  sendEventProfileTriggerClick(): void {
    this.analytics.send({
      event: {
        blockType: this.blockPlace,
        fieldType: 'Пользователь',
        valueName: 'Открыл',
        pageName: this.pageName,
        goalName: 'clickMenu',
      },
    });
    this.analytics.send({
      event: {
        pageName: this.pageName,
        goalName: 'ClickMenuProfile',
        blockType: this.blockPlace,
        actionType: 'Клик',
        prop1: 'Профиль',
        prop2: 'Открыл',
      },
    });
  }

  sendEventSettingsClick(): void {
    this.analytics.send({
      event: {
        blockType: this.blockPlace,
        fieldType: 'Пользователь',
        valueName: 'Настройки',
        pageName: this.pageName,
        goalName: 'clickMenu',
      },
    });
    this.analytics.send({
      event: {
        pageName: this.pageName,
        goalName: 'ClickMenuProfile',
        blockType: this.blockPlace,
        actionType: 'Клик',
        prop1: 'Профиль',
        prop2: 'Настройки',
      },
    });
  }
}
