import type { AnalyticsServiceConfig, IAnalyticsService } from '../analytics';
import { AnalyticsService } from '../analytics';

export type AdvertLogoAnalyticsServiceConfig = AnalyticsServiceConfig;

export interface IAdvertLogoAnalyticsService extends IAnalyticsService {
  sendEventAdvLogoClick(url: string): void;
}

export class AdvertLogoAnalyticsService
  extends AnalyticsService
  implements IAdvertLogoAnalyticsService
{
  private readonly _blockName = 'Рекламное лого';

  constructor(config: AdvertLogoAnalyticsServiceConfig) {
    super(config);
  }

  public sendEventAdvLogoClick(url: string) {
    this.analytics.send({
      event: {
        blockType: this.blockPlace,
        fieldType: this._blockName,
        valueName: url,
        pageName: this.pageName,
        goalName: 'clickMenu',
      },
    });
  }
}
