



























import { clickOutside } from '@smh/utils/click-outside';
import { Component, Vue, Inject, Ref, Watch } from 'vue-property-decorator';

import { AnalyticsBlockPlace } from '@jtnews/shared/seedwork/frontend/domain';
import { UiAvatar, UiBadge } from '@jtnews/shared/ui';
import type { IPageHeaderProfileUseCase } from '@jtnews/users/frontend/application';
import { PAGE_HEADER_PROFILE_USE_CASE_KEY } from '@jtnews/users/frontend/application';

/* eslint-disable @typescript-eslint/naming-convention */
const PageHeaderProfileDropdown = () =>
  import(
    /* webpackChunkName: "page-header-profile-dropdown" */ './page-header-profile-dropdown'
  ).then((m) => m.PageHeaderProfileDropdown);

const PageHeaderSpWidget = () =>
  import(
    /* webpackChunkName: "page-header-sp-widget" */ '../page-header-sp-widget'
  ).then((m) => m.PageHeaderSpWidget);
/* eslint-enable @typescript-eslint/naming-convention */

@Component({
  name: 'PageHeaderProfileBlocks',
  components: {
    UiAvatar,
    UiBadge,
    PageHeaderSpWidget,
    PageHeaderProfileDropdown,
  },
})
export default class PageHeaderProfileBlocks extends Vue {
  @Ref() readonly trigger!: HTMLButtonElement;

  @Inject(PAGE_HEADER_PROFILE_USE_CASE_KEY)
  private readonly _useCase!: IPageHeaderProfileUseCase;

  isOpen = false;
  isSpWidgetOpen = false;

  get accountData() {
    return this._useCase.accountData;
  }

  get hasForums() {
    return this._useCase.hasForums;
  }

  @Watch('isOpen')
  onIsOpenChanged(value: boolean) {
    if (value) {
      setTimeout(() => {
        this.setClickOutside();
        this.processProfileTriggerClick();
      }, 0);
    }
  }

  created() {
    this._useCase.setBlockPlace({
      defaultPlace: AnalyticsBlockPlace.Menu,
      ignoreBreakpoints: true,
    });
  }

  setClickOutside() {
    if (!this.trigger) {
      return;
    }

    clickOutside(
      this.trigger,
      () => {
        this.isOpen = false;
      },
      { isSkipFirstClick: false },
    );
  }

  toggleMenu() {
    if (!this.accountData.isAuthorized && !this.hasForums) {
      this._useCase.processLoginClick();
      return;
    }

    this.isOpen = !this.isOpen;
  }

  processProfileTriggerClick() {
    this._useCase.processProfileTriggerClick();
  }

  processSPWidgetToggle(status: boolean) {
    if (status) {
      this.isOpen = false;
    }

    this.isSpWidgetOpen = status;
  }
}
