



























import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

import type { CurrencyData } from './ui-currency.contract';

const ACTION_TYPE = 'Курс Валют';

@Component({
  name: 'UiCurrency',
})
export default class UiCurrency extends Vue {
  @Prop({
    required: true,
    type: Object,
  })
  block: CurrencyData;

  @Prop({
    required: true,
    type: Boolean,
  })
  isHeaderPosition!: boolean;

  get items() {
    return this.block.currency;
  }

  get link() {
    return this.block.link;
  }

  get componentType(): string {
    if (!this.link || this.link.startsWith('?')) {
      return 'div';
    }

    return 'a';
  }

  @Emit('onLinkClicked')
  onLinkClicked() {
    return ACTION_TYPE;
  }
}
