





































































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

import { UiButton } from '../ui-button';
import { UiWeatherIcon } from '../ui-weather-icon';

import type { Weather } from './ui-weather-tooltip.contract';

@Component({
  name: 'UiWeatherTooltip',
  components: {
    UiButton,
    UiWeatherIcon,
  },
})
export default class UiWeatherTooltip extends Vue {
  @Prop({
    required: true,
    type: Object,
  })
  block!: Weather;

  get icon() {
    return this.block.icon;
  }

  get cityPrepositional() {
    return this.block.cityPrepositional;
  }

  get temperature() {
    return this.block.temperature;
  }

  get precipitation() {
    return this.block.precipitation;
  }

  get wind() {
    return this.block.wind;
  }

  get humidity() {
    return this.block.humidity;
  }

  get pressure() {
    return this.block.pressure;
  }

  get link() {
    return this.block.more;
  }

  @Emit('linkClicked')
  linkClickHAndler() {
    return 'Погода';
  }
}
