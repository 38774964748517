import type { IHotNewsBlockAnalyticsService } from '@jtnews/shared/seedwork/frontend/domain';
import { BlockPlaceRepositoryConfig } from '@jtnews/shared/seedwork/frontend/domain';

import { BlockUseCase, IBlockUseCase } from '../block-usecase';
import type { BlockUseCaseConfig } from '../block-usecase';

type RGData = {
  isHotNews: boolean;
  id: number;
  recordId: number;
};

export const HOT_NEWS_BLOCK_USE_CASE_KEY = 'hot_news_block_use_case_key';

export interface IHotNewsBlockUseCase extends IBlockUseCase {
  processArticleClick(data: RGData): void;
}

export type HotNewsBlockUseCaseConfig = BlockUseCaseConfig & {
  deps: {
    analyticsService: IHotNewsBlockAnalyticsService;
  };
};

export class HotNewsBlockUseCase extends BlockUseCase implements IHotNewsBlockUseCase {
  private readonly _analyticsService: IHotNewsBlockAnalyticsService;

  constructor(config: HotNewsBlockUseCaseConfig) {
    super(config);

    const {
      deps: { analyticsService },
    } = config;

    this._analyticsService = analyticsService;
  }

  public setBlockPlace(blockPlaceConfig: BlockPlaceRepositoryConfig) {
    this._analyticsService.setBlockPlace(blockPlaceConfig);
  }

  public processArticleClick(data: RGData): void {
    this._analyticsService.sendEventArticleClick(data);
  }
}
