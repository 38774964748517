import {
  BlockPlaceRepositoryConfig,
  IFullscreenAdvBlockAnalyticsService,
} from '@jtnews/shared/seedwork/frontend/domain';

import { BlockUseCase, BlockUseCaseConfig, IBlockUseCase } from '../blocks';

export const FULLSCREEN_ADV_USE_CASE_KEY = 'fullscreen_adv_use_case_key';

export interface IFullscreenAdvUseCase extends IBlockUseCase {
  processAdvMounted(): void;
  processAdvClosed(): void;
}

export type FullscreenAdvUseCaseConfig = BlockUseCaseConfig & {
  deps: {
    analyticsService: IFullscreenAdvBlockAnalyticsService;
  };
};

export class FullscreenAdvUseCase extends BlockUseCase implements IFullscreenAdvUseCase {
  private readonly _analyticsService: IFullscreenAdvBlockAnalyticsService;

  constructor(config: FullscreenAdvUseCaseConfig) {
    super(config);

    const {
      deps: { analyticsService },
    } = config;

    this._analyticsService = analyticsService;
  }

  public setBlockPlace(blockPlaceConfig: BlockPlaceRepositoryConfig) {
    this._analyticsService.setBlockPlace(blockPlaceConfig);
  }

  public processAdvMounted() {
    this._analyticsService.sendEventAdvMounted();
  }

  public processAdvClosed() {
    this._analyticsService.sendEventAdvClosed();
  }
}
