


































import { Component, Inject, Vue } from 'vue-property-decorator';

import type { IAdvertLogoUseCase, AdvLogoData } from '../../../../application/src';
import {
  ADVERT_LOGO_USE_CASE_KEY,
  PAGE_STORE_FACADE_KEY,
  PageStoreFacade,
} from '../../../../application/src';
import { AnalyticsBlockPlace } from '../../../../domain/src';

@Component({
  name: 'AdvertLogo',
})
export default class AdvertLogo extends Vue {
  @Inject(PAGE_STORE_FACADE_KEY) pageStoreFacade!: PageStoreFacade;
  @Inject(ADVERT_LOGO_USE_CASE_KEY) private readonly _useCase!: IAdvertLogoUseCase;

  touchCount = 0;
  isShowInformer = false;

  get logo(): AdvLogoData | null {
    return this._useCase.logo;
  }

  get logoSrc(): string | undefined {
    if (this.isMobile && Boolean(this.logo?.logoMobile)) {
      return this.logo?.logoMobile;
    }
    return this.logo?.logoDesktop;
  }

  get isMobile() {
    return this.pageStoreFacade.isMobile;
  }

  get isAdvMarkInfoSignature() {
    return this.logo?.signature === 'INFO';
  }

  get advMarkText() {
    if (!this.logo) {
      return '';
    }

    if (this.isAdvMarkInfoSignature) {
      return 'Информация';
    }

    return 'Реклама';
  }

  processAdvLogoClick(url: string) {
    this._useCase.processAdvLogoClick(url);
    window.open(url, '_blank');
  }

  handleAdvMarkClick() {
    this.isShowInformer = !this.isShowInformer;
  }

  handleLogoClick(url: string | null) {
    if (url) {
      this.processAdvLogoClick(url);
    }
  }

  closeInformer() {
    this.isShowInformer = false;
  }

  handleDocumentClick(e: MouseEvent) {
    const target = e.target as HTMLElement;

    if (!target.matches('[data-click-area]') && !target.closest('[data-informer]')) {
      this.closeInformer();
    }
  }

  created() {
    this._useCase.setBlockPlace({
      defaultPlace: AnalyticsBlockPlace.Menu,
      ignoreBreakpoints: true,
    });
  }

  mounted() {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    document.addEventListener('click', this.handleDocumentClick);
  }

  beforeDestroy() {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    document.removeEventListener('click', this.handleDocumentClick);
  }
}
