import {
  IAppNewsClient,
  NewsClient,
} from '@jtnews/shared/seedwork/frontend/infrastructure';
import { IAccount } from '@jtnews/users/frontend/application';

import { AccountDTO } from './account.dto';

type AccountApiConfig = {
  deps: {
    client: IAppNewsClient;
  };
};

export type AccountApiInput = {
  regionId: number;
};

export class AccountApi extends NewsClient implements IAccount<AccountDTO> {
  static readonly ACCOUNT_URL = '/public/account/';

  constructor(config: AccountApiConfig) {
    super(config);
  }

  async account(input: AccountApiInput) {
    const { regionId } = input;

    const { data } = await this.client.get<AccountDTO>(regionId, AccountApi.ACCOUNT_URL, {
      withCredentials: true,
      headers: {
        accept: this.client.accept,
      },
    });

    return {
      data,
    };
  }
}
