















import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

import type { Traffic } from './ui-traffic.contract';

@Component({
  name: 'UiTraffic',
})
export default class UiTraffic extends Vue {
  @Prop({
    required: true,
    type: Object,
  })
  block!: Traffic;

  get level() {
    return this.block.level;
  }

  get title() {
    return this.block.title;
  }

  get link() {
    return '';
  }

  get componentType(): string {
    if (!this.link || this.link.startsWith('?')) {
      return 'div';
    }

    return 'a';
  }

  @Emit('onLinkClicked')
  onLinkClicked() {
    return this.title;
  }
}
