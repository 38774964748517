import { guardEmptyString, guardUnspecified } from '@smh/utils/guards';

import { IPushNotificationsAnalyticsService } from '@jtnews/notifications/frontend/domain';
import { PushNotificationsHttpClient } from '@jtnews/shared/newsapi/push';
import { OneSignalNotifications } from '@jtnews/shared/push-notifications';
import { ILogger } from '@jtnews/shared/seedwork/frontend/application';

import { PushNotificationsUseCaseError } from './push-notifications-usecase.error';
import { NotificationsStoreFacade } from './push-notifications.store';

const PUSH_NOTIFICATIONS_LOCAL_STORAGE_KEY = 'jtnews_push_notifications';

enum UserPushNotifications {
  NewAnswer = 'COMMENTS_NEW_ANSWER',
}

export const PUSH_NOTIFICATIONS_USE_CASE_KEY = 'push_notifications_use_case_key';

export type UserParamsForPushApi = {
  profileId: string;
  notificationTypes?: string[];
};

export type UserBrowserNotificationSettings = {
  isAnswersPushEnabled: boolean;
} | null;

export type PushNotificationsConfig = {
  deps: {
    logger: ILogger;
    store: NotificationsStoreFacade;
    pushNotificationsService: OneSignalNotifications;
    pushNotificationsApi: PushNotificationsHttpClient;
    pushNotificationsAnalyticsService: IPushNotificationsAnalyticsService;
  };
  isUnsupportedPlatform: boolean;
  regionId: number;
  isMobile: boolean;
  isAuthorized: boolean;
  userId: number | undefined;
};

export interface IPushNotificationsUseCase {
  readonly isSubscribeAvailable: boolean;
  processInitPushNotificationsService: () => void;
  processSaveUserPushNotificationBrowserSettings: ({
    profileId,
    isAfterAuth = false,
  }: {
    profileId: string;
    isAfterAuth?: boolean;
  }) => void;
  processSubscribeUserOnPushNotifications: (params: UserParamsForPushApi) => void;
  processUnsubscribeUserFromPushNotifications: (
    params: UserParamsForPushApi & { isNeedUpdateData?: boolean },
  ) => void;
  processSetupPushNotificationsService: () => void;
  processSubscribeToBrowserNotificationsDeniedChange: () => void;
  processShowPopoverNotifications: () => void;
  processShowNativePrompt: () => void;
  processSubscribeToNotificationsEnabledChange: () => void;
  processUpdateSubscriptionDataInLS: (data: UserBrowserNotificationSettings) => void;
  processSetDataToLocalStorage: <T>(key: string, value: T) => void;
  processGetDataFromLocalStorage: <T>(key: string) => T | null;
  processSubscribePopoverShown: (callback: () => void) => void;
  processSubscribePopoverActions: (callback: (event: string) => void) => void;
  processSubscribePopoverClosed: (callback: () => void) => void;
  processUnsubscribePopoverActions: () => void;
  processUnsubscribePopoverClosed: () => void;
  processUnsubscribePopoverShown: () => void;
  processUnsubscribeToNotificationsEnabledChange: () => void;
  processUnsubscribeToBrowserNotificationsDeniedChange: () => void;
  processGetUserPlayerId: () => void;
  processInitOneSignal: () => void;
  processSubscribePushNotificationsStates: () => void;
  processUnsubscribePushNotificationsStates: () => void;
  processUpdateUserPushNotificationsBySettings: () => void;
}

export class PushNotificationsUseCase implements IPushNotificationsUseCase {
  private readonly _pushNotificationsApi: PushNotificationsHttpClient;
  private readonly _pushNotificationsService: OneSignalNotifications;
  private readonly _isUnsupportedPlatform: boolean;
  private readonly _store: NotificationsStoreFacade;
  private readonly _regionId: number;
  private readonly _isMobile: boolean;
  private readonly _isAuthorized: boolean;
  private readonly _userId: number | undefined;
  private readonly _analytics: IPushNotificationsAnalyticsService;

  constructor(config: PushNotificationsConfig) {
    const { deps, isUnsupportedPlatform, regionId, isMobile, isAuthorized, userId } =
      config;
    const {
      store,
      pushNotificationsService,
      pushNotificationsApi,
      pushNotificationsAnalyticsService,
    } = deps;

    this._isUnsupportedPlatform = isUnsupportedPlatform;
    this._regionId = regionId;
    this._isMobile = isMobile;
    this._isAuthorized = isAuthorized;
    this._userId = userId;

    this._store = store;
    this._pushNotificationsService = pushNotificationsService;
    this._pushNotificationsApi = pushNotificationsApi;
    this._analytics = pushNotificationsAnalyticsService;
  }

  public get isSubscribeAvailable() {
    return (
      this._store.isPushNotificationsReady && !this._store.isPushNotificationsEnabled
    );
  }

  processInitPushNotificationsService(): void {
    if (this._isUnsupportedPlatform) {
      this._store.updatePushNotificationsNotAvailable(true);
      return;
    }

    const userBrowserNotificationSettings =
      this.processGetDataFromLocalStorage<UserBrowserNotificationSettings>(
        PUSH_NOTIFICATIONS_LOCAL_STORAGE_KEY,
      );
    this._store.updateSavedUserBrowserNotificationSettings(
      userBrowserNotificationSettings,
    );
  }

  processSaveUserPushNotificationBrowserSettings({
    profileId,
    isAfterAuth = false,
  }: {
    profileId: string;
    isAfterAuth?: boolean;
  }): void {
    if (
      !this._store.isPushNotificationsReady ||
      !guardEmptyString(this._pushNotificationsService.userPlayerId) ||
      this._store.isPushNotificationsNotAvailable
    ) {
      return;
    }

    if (
      guardUnspecified(this._store.savedUserBrowserNotificationSettings) &&
      !isAfterAuth
    ) {
      return;
    }

    const isAnswersPushNotificationsEnabled =
      guardUnspecified(this._store.savedUserBrowserNotificationSettings) && isAfterAuth
        ? this._store.savedUserBrowserNotificationSettings.isAnswersPushEnabled
        : this._store.isPushNotificationsEnabled;

    const params = {
      profileId,
      notificationTypes: [UserPushNotifications.NewAnswer],
    };

    if (isAnswersPushNotificationsEnabled) {
      void this.processSubscribeUserOnPushNotifications(params);
    } else if (!isAfterAuth) {
      void this.processUnsubscribeUserFromPushNotifications(params);
    }
  }

  async processSubscribeUserOnPushNotifications(
    params: UserParamsForPushApi,
  ): Promise<void> {
    if (!guardEmptyString(this._pushNotificationsService.userPlayerId)) {
      return;
    }

    try {
      await this._pushNotificationsApi.saveOneSignalSubscription({
        ...params,
        playerId: this._pushNotificationsService.userPlayerId,
        regionId: this._regionId,
      });

      const currentSettings = {
        isAnswersPushEnabled: true,
      };
      void this.processUpdateSubscriptionDataInLS(currentSettings);
      this._store.updateSavedUserBrowserNotificationSettings(currentSettings);
    } catch (error) {
      throw PushNotificationsUseCaseError.of(
        'Не удалось подписаться на пуш-уведомления',
        error as Error,
      );
    }
  }

  async processUnsubscribeUserFromPushNotifications(
    params: UserParamsForPushApi & { isNeedUpdateData?: boolean },
  ): Promise<void> {
    if (
      !guardEmptyString(this._pushNotificationsService.userPlayerId) ||
      this._store.isPushNotificationsNotAvailable
    ) {
      return;
    }

    const { profileId, notificationTypes, isNeedUpdateData = true } = params;

    try {
      await this._pushNotificationsApi.unsubscribeUser({
        profileId,
        notificationTypes,
        playerId: this._pushNotificationsService.userPlayerId,
        regionId: this._regionId,
      });

      if (isNeedUpdateData) {
        const currentSettings = {
          isAnswersPushEnabled: false,
        };
        void this.processUpdateSubscriptionDataInLS(currentSettings);
        this._store.updateSavedUserBrowserNotificationSettings(currentSettings);
      }
    } catch (error) {
      throw PushNotificationsUseCaseError.of(
        'Не удалось отписаться от пуш-уведомлений',
        error as Error,
      );
    }
  }

  async processSetupPushNotificationsService(): Promise<void> {
    try {
      await this._pushNotificationsService.setup();
    } finally {
      const { isNotificationsReady } = this._pushNotificationsService;
      const { isBrowserNotificationsDenied } = this._pushNotificationsService;
      const { isNotificationsEnabled } = this._pushNotificationsService;

      this._store.updatePushNotificationsNotAvailable(false);
      this._store.updatePushNotificationsReady(isNotificationsReady);
      this._store.updateBrowserNotificationsDenied(isBrowserNotificationsDenied);
      this._store.updatePushNotificationsEnabled(isNotificationsEnabled);
    }
  }

  processSubscribeToBrowserNotificationsDeniedChange(
    callback?: (value: boolean) => void,
  ): void {
    this._pushNotificationsService.subscribeToBrowserNotificationsDeniedChange(
      (value: boolean) => {
        const { isBrowserNotificationsDenied } = this._pushNotificationsService;

        this._store.updateBrowserNotificationsDenied(isBrowserNotificationsDenied);

        if (guardUnspecified(callback)) {
          callback(value);
        }
      },
    );
  }

  processShowPopoverNotifications(force = false): void {
    this._pushNotificationsService.showPopoverNotifications(force);
  }

  processShowNativePrompt(): void {
    this._pushNotificationsService.showNativePrompt();
  }

  processSubscribeToNotificationsEnabledChange(
    callback?: (isEnabled: boolean) => void,
  ): void {
    this._pushNotificationsService.subscribeToNotificationsEnabledChange((isEnabled) => {
      const { isNotificationsEnabled } = this._pushNotificationsService;

      this._store.updatePushNotificationsEnabled(isNotificationsEnabled);

      if (guardUnspecified(callback)) {
        callback(isEnabled);
      }
    });
  }

  processUpdateSubscriptionDataInLS(data: UserBrowserNotificationSettings): void {
    if (guardUnspecified(data)) {
      const savedData = guardUnspecified(this._store.savedUserBrowserNotificationSettings)
        ? {
            ...this._store.savedUserBrowserNotificationSettings,
            ...data,
          }
        : { ...data };

      this.processSetDataToLocalStorage(PUSH_NOTIFICATIONS_LOCAL_STORAGE_KEY, savedData);
    }
  }

  processSetDataToLocalStorage<T>(key: string, value: T): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  processGetDataFromLocalStorage<T>(key: string): T | null {
    const value = localStorage.getItem(key);
    return guardUnspecified(value) ? (JSON.parse(value) as T) : null;
  }

  processSubscribePopoverShown(callback: () => void): void {
    this._pushNotificationsService.subscribePopoverShown(callback);
  }

  processSubscribePopoverActions(callback: (event: string) => void): void {
    this._pushNotificationsService.subscribePopoverActions(callback);
  }

  processSubscribePopoverClosed(callback: () => void): void {
    this._pushNotificationsService.subscribePopoverClosed(callback);
  }

  processUnsubscribePopoverActions(): void {
    this._pushNotificationsService.unsubscribePopoverActions();
  }

  processUnsubscribePopoverClosed(): void {
    this._pushNotificationsService.unsubscribePopoverClosed();
  }

  processUnsubscribePopoverShown(): void {
    this._pushNotificationsService.unsubscribePopoverShown();
  }

  processUnsubscribeToNotificationsEnabledChange(): void {
    this._pushNotificationsService.unsubscribeToNotificationsEnabledChange();
  }

  processUnsubscribeToBrowserNotificationsDeniedChange(): void {
    this._pushNotificationsService.unsubscribeToBrowserNotificationsDeniedChange();
  }

  async processGetUserPlayerId(): Promise<void> {
    await this._pushNotificationsService.getUserPlayerId();
  }

  processUpdateUserPushNotificationsBySettings() {
    if (guardUnspecified(this._userId)) {
      this.processSaveUserPushNotificationBrowserSettings({
        profileId: String(this._userId),
      });
    }
  }

  async processOnChangeSubscribeToNotificationsState(isEnabled: boolean) {
    try {
      if (isEnabled) {
        await this.processGetUserPlayerId();
      }
    } finally {
      if (this._isAuthorized) {
        this.processUpdateUserPushNotificationsBySettings();
      }
    }
  }

  processSetPositionForPushNotificationsPopover() {
    const globalWrapperEl = document.querySelector('#push-notifications-popover-place');
    const topCoords = globalWrapperEl?.getBoundingClientRect().top ?? 0;

    if (!this._isMobile && topCoords > 0) {
      setTimeout(() => {
        const oneSignalPromptEl: HTMLElement | null = document.querySelector(
          '#onesignal-slidedown-container',
        );
        if (guardUnspecified(oneSignalPromptEl)) {
          oneSignalPromptEl.style.top = `${topCoords}px`;
        }
      });
    }
  }

  processSubscribePushNotificationsPopoverActions() {
    this._pushNotificationsService.subscribePopoverActions((event) => {
      switch (event) {
        case 'allow':
          this._analytics.sendEventClickPopoverAllowed();
          break;
        case 'disallow':
          this._analytics.sendEventClickPopoverDisallowed();
          break;
        default:
          break;
      }
    });
  }

  processSubscribePushNotificationsPopoverClosed() {
    this.processSubscribePopoverClosed(() => {
      this.processUnsubscribePopoverActions();
      this.processUnsubscribePopoverClosed();
    });
  }

  processOnPushNotificationsPopoverShown() {
    this.processSetPositionForPushNotificationsPopover();
    this.processSubscribePushNotificationsPopoverActions();
    this.processSubscribePushNotificationsPopoverClosed();
  }

  processSubscribePushNotificationsStates() {
    this.processSubscribeToNotificationsEnabledChange(
      void this.processOnChangeSubscribeToNotificationsState,
    );

    this.processSubscribeToBrowserNotificationsDeniedChange((isDenied) => {
      this._analytics.sendEventClickBrowserPermission(isDenied);
      this.processUnsubscribeToBrowserNotificationsDeniedChange();
    });

    this.processSubscribePopoverShown(() => {
      this._analytics.sendEventViewFormPushNotificationsSubscribe();
      this.processOnPushNotificationsPopoverShown();
    });
  }

  async processInitOneSignal(): Promise<void> {
    await this.processSetupPushNotificationsService();

    if (!this._store.isPushNotificationsNotAvailable) {
      this.processSubscribePushNotificationsStates();
      this.processShowPopoverNotifications();
    }
  }

  processUnsubscribePushNotificationsStates() {
    this.processUnsubscribePopoverShown();
    this.processUnsubscribePopoverClosed();
    this.processUnsubscribePopoverActions();
    this.processUnsubscribeToNotificationsEnabledChange();
    this.processUnsubscribeToBrowserNotificationsDeniedChange();
  }
}
