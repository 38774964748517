import type { IPageHitAnalytics, PageHitAnalyticsEvent } from './page-hit-analytics';

export interface IPageAnalyticsService {
  pageHit(input: { event: PageHitAnalyticsEvent }): void;
}

export type PageAnalyticsServiceConfig = {
  deps: {
    pageHitAnalytics: IPageHitAnalytics;
  };
};

export class PageAnalyticsService implements IPageAnalyticsService {
  private readonly _pageHitAnalytics: IPageHitAnalytics;

  constructor(config: PageAnalyticsServiceConfig) {
    const {
      deps: { pageHitAnalytics },
    } = config;
    this._pageHitAnalytics = pageHitAnalytics;
  }

  pageHit(input: { event: PageHitAnalyticsEvent }): void {
    const { event } = input;

    this._pageHitAnalytics.send({ event });
  }
}
