/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return */

import { guardUnspecified } from '@smh/utils/guards';
import { retryAsync } from '@smh/utils/retry';
import axios, { AxiosInstance, AxiosStatic } from 'axios';

import { getDomainByRegionId } from '@jtnews/shared/seedwork/bff/domain';

import type {
  IAppNewsClient,
  IAppNewsClientRequestConfig,
  IAppNewsClientResponse,
  IAppNewsClientRetryOptions,
} from './news-client.api';

type NewsHttpClientConfiguration = {
  acceptEncoding?: string;
  timeout?: number;
  maxRedirects?: number;
  maxContentLength?: number;
  httpAgent?: any;
  httpsAgent?: any;
};

const initInterceptors = (i: AxiosStatic | AxiosInstance) => {
  i.interceptors.request.use(
    (config: any) => {
      config.metadata = { startTime: Date.now() };
      return config;
    },
    (error: any) => Promise.reject(error),
  );

  i.interceptors.response.use(
    (response: any) => {
      response.config.metadata.endTime = Date.now();

      if (typeof response.data !== undefined && typeof response.data === 'string') {
        response.data = {};
      }

      response.data.responseTime =
        response.config.metadata.endTime - response.config.metadata.startTime;
      return response;
    },
    (error: any) => Promise.reject(error),
  );
};

const configureClient = (config: NewsHttpClientConfiguration) => {
  const {
    acceptEncoding,
    timeout,
    maxRedirects,
    maxContentLength,
    httpAgent,
    httpsAgent,
  } = config;

  if (guardUnspecified(acceptEncoding)) {
    axios.defaults.headers.get['Accept-Encoding'] = acceptEncoding;
  }

  if (guardUnspecified(httpAgent) && guardUnspecified(httpsAgent)) {
    const client = axios.create({
      timeout,
      httpAgent,
      httpsAgent,
      maxRedirects,
      maxContentLength,
    });

    initInterceptors(client);

    return client;
  }

  initInterceptors(axios);

  return axios;
};

const retryIfSpecified = <T>(
  fn: () => Promise<T>,
  options?: IAppNewsClientRetryOptions,
): Promise<T> => {
  if (guardUnspecified(options)) {
    return retryAsync(fn, options);
  }

  return fn();
};

/**
 * @deprecated
 */
export type OriginalError = {
  message: string;
  stack: string;
  response?: {
    status: number;
    statusText: string;
    data: unknown;
  };
  config?: {
    url?: string;
    params?: unknown;
    data?: unknown;
  };
};

export const NEWS_CLIENT_KEY = 'new_client_key';

/**
 * @deprecated
 */
export class NewsApiClientError extends Error {
  public readonly response?: {
    status: number;
    statusText: string;
    data: unknown;
  };

  public readonly request: {
    url?: string;
    params?: unknown;
    data?: unknown;
  };

  public readonly originalStack: string;

  constructor(originalError: OriginalError) {
    super(originalError.message);
    this.name = this.constructor.name;

    if ('captureStackTrace' in Error) {
      Error.captureStackTrace(this, this.constructor);
    }

    this.originalStack = originalError.stack;

    if (originalError.response) {
      this.response = {
        status: originalError.response.status,
        statusText: originalError.response.statusText,
        data: originalError.response.data,
      };
    }

    if (originalError.config) {
      this.request = {
        url: originalError.config.url,
        params: originalError.config.params,
        data: originalError.config.data,
      };
    }
  }
}

export type NewsHttpClientConfig = {
  host?: string;
  acceptEncoding?: string;
  envType?: string;
  protocol?: 'http' | 'https';
  enableKeepAlive?: boolean;
  timeout?: number;
  maxContentLength?: number;
  maxRedirects?: number;
  httpAgent?: any;
  httpsAgent?: any;
  needFilterHeaders?: boolean;
};

export const configureNewsHttpClient = (config: NewsHttpClientConfig): IAppNewsClient => {
  const {
    acceptEncoding,
    envType = '',
    protocol = 'http',
    httpAgent,
    httpsAgent,
    // cap the maximum content length we'll accept to 50MBs, just in case
    maxContentLength = 50000000,
    // follow up to 10 HTTP 3xx redirects
    maxRedirects = 10,
    // 10 sec timeout
    timeout = 10000,
    // responseType,
    needFilterHeaders,
    host,
  } = config;

  const DEFAULT_VERSION = 'v1';
  const ACCEPT = 'vnd.news.v1.jtnews+json';
  const ALLOWED_HEADERS = [
    'x-region',
    'x-cluster-request',
    'x-forwarded-for',
    'x-real-ip',
    'user-agent',
    'cookie',
    'x-request-path',
    'referer',
    'host',
    'x-client-ip',
  ];

  const getEnvType = () => {
    if (guardUnspecified(envType)) {
      return envType;
    }

    return '';
  };

  const getProtocol = (): 'http' | 'https' => {
    if (guardUnspecified(protocol)) {
      return protocol;
    }

    return 'http';
  };

  const getHost = (regionId: number, version?: string, domain?: string) => {
    const v = version ?? DEFAULT_VERSION;

    /**
     * нужно будет избавить от getDomainByRegionId и брать domain из page.meta.domain
     * сейчас IAppNewsClientRequestConfig.domain пока что опциональный
     */
    if (guardUnspecified(host)) {
      return `${host}/${v}`;
    }

    const protocol = `${getProtocol()}://`;

    if (guardUnspecified(domain)) {
      return `${protocol}${domain}`;
    }

    return `${protocol}newsapi.${getDomainByRegionId(regionId)}${getEnvType()}/${v}`;
  };

  const httpClient = configureClient({
    acceptEncoding,
    maxContentLength,
    maxRedirects,
    timeout,
    httpAgent,
    httpsAgent,
  });

  const filterHeaders = (headers: any = {}) =>
    ALLOWED_HEADERS.reduce((acc: Record<string, string>, current: string) => {
      if (guardUnspecified(headers)) {
        acc[current] = headers[current] || '';
      }

      return acc;
    }, {});

  const prepareHeaders = (input: {
    headers?: any;
    needFilterHeaders?: boolean;
    regionId: number;
  }) => {
    const { headers = {}, needFilterHeaders = false, regionId } = input;

    return needFilterHeaders
      ? filterHeaders({
          ...headers,
          host: `newsapi.${getDomainByRegionId(regionId)}`,
        })
      : headers;
  };

  const configureConfig = (regionId: number, config?: IAppNewsClientRequestConfig) => ({
    ...(guardUnspecified(config) ? config : {}),
    headers: prepareHeaders({
      headers: config?.headers,
      regionId,
      needFilterHeaders,
    }),
    params: {
      regionId,
      ...(guardUnspecified(config?.params) ? config?.params : {}),
    },
  });

  const get = <T = any, R = IAppNewsClientResponse<T>>(
    regionId: number,
    url: string,
    config: IAppNewsClientRequestConfig,
    domain?: string,
  ) =>
    retryIfSpecified(async function internalGet() {
      try {
        return await httpClient.get<T, R>(
          `${getHost(regionId, config?.version, domain)}${url}`,
          configureConfig(regionId, config),
        );
      } catch (error) {
        throw new NewsApiClientError(error as OriginalError);
      }
    }, config.retry);

  const post = <T = any, R = IAppNewsClientResponse<T>, D = any>(
    regionId: number,
    url: string,
    data?: D,
    config?: IAppNewsClientRequestConfig,
    domain?: string,
  ) =>
    retryIfSpecified(async function internalPost() {
      try {
        return await httpClient.post<T, R, D>(
          `${getHost(regionId, config?.version, domain)}${url}`,
          data,
          configureConfig(regionId, config),
        );
      } catch (error) {
        throw new NewsApiClientError(error as OriginalError);
      }
    }, config?.retry);

  const put = <T = any, R = IAppNewsClientResponse<T>, D = any>(
    regionId: number,
    url: string,
    data?: D,
    config?: IAppNewsClientRequestConfig,
    domain?: string,
  ) =>
    retryIfSpecified(async function internalPut() {
      try {
        return await httpClient.put<T, R, D>(
          `${getHost(regionId, config?.version, domain)}${url}`,
          data,
          configureConfig(regionId, config),
        );
      } catch (error) {
        throw new NewsApiClientError(error as OriginalError);
      }
    }, config?.retry);

  const patch = <T = any, R = IAppNewsClientResponse<T>, D = any>(
    regionId: number,
    url: string,
    data?: D,
    config?: IAppNewsClientRequestConfig,
    domain?: string,
  ) =>
    retryIfSpecified(async function internalPatch() {
      try {
        return await httpClient.patch<T, R, D>(
          `${getHost(regionId, config?.version, domain)}${url}`,
          data,
          configureConfig(regionId, config),
        );
      } catch (error) {
        throw new NewsApiClientError(error as OriginalError);
      }
    }, config?.retry);

  const deleteMethod = async <T = any, R = IAppNewsClientResponse<T>, D = any>(
    regionId: number,
    url: string,
    config?: IAppNewsClientRequestConfig,
    domain?: string,
  ) =>
    retryIfSpecified(async function internalDelete() {
      try {
        return await httpClient.delete<T, R, D>(
          `${getHost(regionId, config?.version, domain)}${url}`,
          configureConfig(regionId, config),
        );
      } catch (error) {
        throw new NewsApiClientError(error as OriginalError);
      }
    }, config?.retry);

  return {
    accept: ACCEPT,
    version: DEFAULT_VERSION,
    get,
    post,
    put,
    patch,
    delete: deleteMethod,
  };
};
