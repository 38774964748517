import Vue from 'vue';
import Router, { RouterOptions } from 'vue-router';

import { HOME_SHELL } from '@jtnews/home/frontend/shell';
import {
  ARTICLES_BY_DATE_PAGE_SHELL,
  ARTICLES_BY_RUBRIC_PAGE_SHELL
} from '@jtnews/records/frontend/shell';
import { VIDEOS_SHELL } from '@jtnews/videos/frontend/shell';

Vue.use(Router);

const routes: RouterOptions['routes'] = [
  ...HOME_SHELL,
  ...ARTICLES_BY_DATE_PAGE_SHELL,
  ...ARTICLES_BY_RUBRIC_PAGE_SHELL,
  ...VIDEOS_SHELL
];

export const createRouter = (): Router =>
  new Router({
    mode: 'history',
    routes
  });
