import { guardEmptyString } from '@smh/utils/guards';

import type { ILogger, ImageVM } from '@jtnews/shared/seedwork/frontend/application';
import { ImageAspectRatio } from '@jtnews/shared/seedwork/frontend/application';
import {
  Presenter,
  presentResponsiveImageData,
} from '@jtnews/shared/seedwork/frontend/infrastructure';
import type {
  AccountForumsVM,
  IAccountForumsPresenter,
} from '@jtnews/users/frontend/application';
import { AccountPresenterError } from '@jtnews/users/frontend/application';

import type { AccountForumsDTO } from './account-forums.dto';

type AccountPresenterConfig = {
  deps: {
    logger: ILogger;
  };
};

export class AccountForumsPresenter
  extends Presenter<AccountForumsVM, AccountForumsDTO, null>
  implements IAccountForumsPresenter<AccountForumsDTO>
{
  private readonly _avatarSize = 32;

  constructor(config: AccountPresenterConfig) {
    super(config);
  }

  present(input: { data: AccountForumsDTO; params: null }): AccountForumsVM {
    try {
      const {
        data: { id, passportId, login, email, avatar, sex, link, menu },
      } = input;

      return {
        id,
        passportId,
        login,
        email,
        sex,
        link,
        avatar: guardEmptyString(avatar) ? this._presentImage(avatar) : null,
        menu,
      };
    } catch (err) {
      throw AccountPresenterError.of('Не удалось выполнить present', err as Error);
    }
  }

  private _presentImage(url: string): ImageVM {
    return presentResponsiveImageData({
      url,
      width: this._avatarSize,
      values: [],
      aspectRatio: ImageAspectRatio.Square,
      isPreloadImage: false,
      isLazy: false,
    }).data;
  }
}
