type AuthorizationInput = {
  regionId: number;
  username: string;
  password: string;
  token?: string;
};

export interface IAuthorization<TOutput> {
  authorization(input: AuthorizationInput): Promise<{
    data: TOutput;
  }>;
}

export const LOCAL_STORAGE_USER_IS_AUTH_KEY = 'UMP_USER_IS_AUTH';
