import { sendLI, sendYM, sendGA } from '@smh/analytics/core';
import { PageViewsLoyaltyService } from '@smh/analytics/user-loyalty';
import { injectScript } from '@smh/utils/dom';
import { guardUnspecified } from '@smh/utils/guards';
import { toQueryString } from '@smh/utils/url';

import type { ILogger } from '@jtnews/shared/seedwork/frontend/application';
import type {
  IPageHitAnalytics,
  PageHitAnalyticsEvent,
} from '@jtnews/shared/seedwork/frontend/domain';
import { PageHitAnalyticsError } from '@jtnews/shared/seedwork/frontend/domain';

type MediascopeCounter = {
  idc: string;
  cid: string;
  tms: string;
  account: string;
  tmsec: string;
};

export const PAGE_HIT_ANALYTICS_KEY = 'page_hit_analytics_key';

export type PageHitAnalyticsConfig = {
  yandex: {
    countersIds: number[];
  };
  liveInternet: {
    countersIds: string[];
  };
  mediascope: {
    counter: MediascopeCounter | null;
  };
  google: {
    counter: string;
  };
  deps: {
    logger: ILogger;
  };
};

export class PageHitAnalytics implements IPageHitAnalytics {
  private readonly _logger: ILogger;

  private readonly _pageViewsLoyaltyService = new PageViewsLoyaltyService();

  constructor(private readonly _config: PageHitAnalyticsConfig) {
    const {
      deps: { logger },
    } = _config;

    this._logger = logger;
  }

  private get _liveInternetCounters() {
    return this._config.liveInternet.countersIds;
  }

  private get _yandexCounters() {
    return this._config.yandex.countersIds;
  }

  private get _mediascopeCounter() {
    return this._config.mediascope.counter;
  }

  private get _googleCounter() {
    return this._config.google.counter;
  }

  send(input: { event: PageHitAnalyticsEvent; params?: unknown }) {
    const {
      event: { reachGoals, serverDate, idlc },
    } = input;

    this._liveInternetCounters.forEach((counterId) => {
      sendLI('hit', { counterId });
    });

    if (guardUnspecified(this._mediascopeCounter)) {
      if (guardUnspecified(idlc)) {
        this._sendMediascopeHit({
          counter: this._mediascopeCounter,
          idlc,
        });
      }

      this._sendMediascopeAuditoryMetrics({
        counter: this._mediascopeCounter,
      });
    }

    this._sendUserLoyaltyAnalytics({
      counters: this._yandexCounters,
      date: serverDate,
    });

    reachGoals.forEach(({ name, params }) => {
      sendYM('reachGoal', { counters: this._yandexCounters, goalName: name, params });
    });

    sendGA('pageView', { trackers: [this._googleCounter] });
  }

  private _sendMediascopeHit(input: { counter: MediascopeCounter; idlc: string }): void {
    const { href } = window.location;
    const { counter, idlc } = input;

    const { idc, cid, tms } = counter;
    const query = toQueryString(
      {
        idc,
        cid,
        tms,
        urlc: encodeURIComponent(href),
        fts: '0',
        typ: '1',
        ver: '0',
        type: '4',
        idlc,
      },
      '&',
    );
    const xhr = new XMLHttpRequest();
    xhr.withCredentials = true;
    xhr.open('GET', `'https://tns-counter.ru/e/ec01'${query}`);
    xhr.send();
    xhr.onerror = (_) => {
      this._logger.capture({
        error: PageHitAnalyticsError.of('Ошибка получения счетчика Mediascope'),
      });
    };
  }

  private _sendMediascopeAuditoryMetrics(input: { counter: MediascopeCounter }) {
    const {
      counter: { account, tmsec },
    } = input;

    const content = `((counterHostname) => {
      window.MSCounter = {
        counterHostname: counterHostname
      };
      window.mscounterCallbacks = window.mscounterCallbacks || [];
      window.mscounterCallbacks.push(() => {
        msCounterExampleCom = new MSCounter.counter({
          'account': '${account}',
          'tmsec': '${tmsec}',
          'autohit': true
        });
      });

      const newScript = document.createElement('script');
      newScript.async = true;
      newScript.src = counterHostname + '/ncc/counter.js';

      const referenceNode = document.querySelector('script');
      if (referenceNode) {
        referenceNode.parentNode.insertBefore(newScript, referenceNode);
      } else {
        document.firstElementChild.appendChild(newScript);
      }
    })('https://tns-counter.ru/');`;

    void injectScript({
      content,
    });
  }

  private _sendUserLoyaltyAnalytics(input: { date: string; counters: number[] }) {
    const { counters, date } = input;
    const { loyalty, views } = this._pageViewsLoyaltyService.getPageViewsLoyalty(date);

    const params = {
      Пользователь: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Лояльность (просмотры за 30 дней)': { [loyalty]: views.toString() },
      },
    };

    sendYM('userParams', { counters, params });
  }
}
