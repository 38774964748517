export const environment = {
  localBuild: meta.env.LOCAL_BUILD,
  newApp: meta.env.NEW_APP,
  useProxy: meta.env.USE_PROXY,
  proxyUrl: meta.env.PROXY_URL,
  bundleType: meta.env.BUNDLE_TYPE,
  isClientBundle: meta.env.IS_CLIENT_BUNDLE,

  mode: meta.env.MODE,
  prod: meta.env.PROD,
  dev: meta.env.DEV,
  release: meta.env.RELEASE_NUMBER,
  port: meta.env.PORT,
  envType: meta.env.ENV_TYPE,

  sentryDSN: meta.env.DSN_SENTRY,
  sentryUseProxy: meta.env.SENTRY_USE_PROXY,

  influxHost: meta.env.INFLUX_HOST,
  influxPort: meta.env.INFLUX_PORT,
  influxDb: meta.env.INFLUX_DB,
  influxUser: meta.env.INFLUX_USER,
  influxPass: meta.env.INFLUX_PASSWORD,
  enableCache: meta.env.ENABLE_CACHE,
  cacheMax: meta.env.CACHE_MAX,
  cacheMaxAge: meta.env.CACHE_MAX_AGE,

  regionId: meta.env.X_REGION,
  geoRegionId: meta.env.X_GEO_REGION,
  ab: meta.env.X_AB,
  browserName: meta.env.X_BROWSER_NAME,
  browserVersionMajor: meta.env.X_BROWSER_VERSION_MAJOR,
  browserVersionMinor: meta.env.X_BROWSER_VERSION_MINOR,
  browserVersionPatch: meta.env.X_BROWSER_VERSION_PATCH,
  isDesktop: meta.env.X_IS_DESKTOP,
  isTablet: meta.env.X_IS_TABLET,
  isMobile: meta.env.X_IS_MOBILE,
  isMobileOnly: meta.env.X_IS_MOBILE_ONLY,
  isSafari: meta.env.X_IS_SAFARI,
  isIOS: meta.env.X_IS_IOS,
  isBrowserSupported: meta.env.X_IS_SUPPORTED_BROWSER,
  botName: meta.env.X_BOT_NAME,
  tcpRTT: meta.env.X_TCP_RTT,
  newsapiHost: meta.env.NEWSAPI_HOST === '' ? undefined : meta.env.NEWSAPI_HOST,
};
