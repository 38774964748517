import {
  ILogger,
  LoggerCaptureInput,
} from '@jtnews/shared/seedwork/frontend/application';

export class DevLogger implements ILogger {
  capture(input: LoggerCaptureInput) {
    const { error } = input;
    console.error(error);
  }
}
