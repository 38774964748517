import { Entity } from '../entity';
import { UniqueEntityID } from '../unique-entity-id';

import { SendNewsContactVO } from './send-news-contact.vo';
import { SendNewsContentVO } from './send-news-content.vo';
import { SendNewsFilesVO } from './send-news-files.vo';

type SendNewsUserProps = {
  contact: SendNewsContactVO;
  content: SendNewsContentVO;
  files: SendNewsFilesVO;
};

export class SendNewsEntity extends Entity<SendNewsUserProps> {
  private constructor(props: SendNewsUserProps, id?: UniqueEntityID) {
    super(props, id);
  }

  get id() {
    return this.entityId;
  }

  get contact() {
    return this.props.contact;
  }

  get content() {
    return this.props.content;
  }

  get files() {
    return this.props.files;
  }

  public static create(props: SendNewsUserProps, id?: UniqueEntityID) {
    return new SendNewsEntity(props, id);
  }
}
