import { guardEmptyString } from '@smh/utils/guards';

import {
  BlockPlaceRepositoryConfig,
  SendNewsContactVO,
  SendNewsContactVOError,
  SendNewsContentVO,
  SendNewsContentVOError,
  SendNewsEntity,
  SendNewsFilesVO,
  SendNewsFilesVOError,
} from '@jtnews/shared/seedwork/frontend/domain';
import type { ISendNewsModalAnalyticsService } from '@jtnews/users/frontend/domain';

import type { BlockUseCaseConfig, IBlockUseCase } from '../blocks';
import { BlockUseCase } from '../blocks';
import type { ICaptcha } from '../captcha';

import { SendNewsUseCaseError } from './send-news-usecase.error';
import { ISendNews } from './send-news.api';

const SEND_NEWS_MODAL_NAME = 'send-news-modal';

type RequiredForm = {
  contact: string;
  content: string;
};

type Form = RequiredForm & {
  files: File[];
};

type ValidateResult =
  | {
      isValid: false;
      error: Record<keyof Form, string>;
    }
  | {
      isValid: true;
      entity: SendNewsEntity;
    };

export const SEND_NEWS_MODAL_USE_CASE_KEY = 'send_news_use_case_key';

export const SEND_NEWS_MODAL_KEY = 'send_news_modal_key';

export interface ISendNewsModalUseCase extends IBlockUseCase {
  readonly modalName: string;
  validateFiles(value: File[]): { isValid: boolean; text: string };
  validate(form: Form): ValidateResult;
  checkEmptyFields(form: RequiredForm): boolean;
  processSendNews(form: SendNewsEntity): Promise<void>;
  processOpenModal(): void;
}

export type SendNewsModalUseCaseConfig = BlockUseCaseConfig & {
  regionId: number;
  deps: {
    analyticsService: ISendNewsModalAnalyticsService;
    captcha: ICaptcha;
    sendNewsApi: ISendNews;
  };
};

export class SendNewsModalUseCase extends BlockUseCase implements ISendNewsModalUseCase {
  private readonly _analyticsService: ISendNewsModalAnalyticsService;
  private readonly _captcha: ICaptcha;
  private readonly _regionId: number;
  private readonly _sendNewsApi: ISendNews;
  private readonly _captchaAction = 'send_news';

  constructor(config: SendNewsModalUseCaseConfig) {
    super(config);

    const {
      regionId,
      deps: { analyticsService, sendNewsApi, captcha },
    } = config;

    this._analyticsService = analyticsService;
    this._captcha = captcha;
    this._regionId = regionId;
    this._sendNewsApi = sendNewsApi;
  }

  get modalName(): string {
    return SEND_NEWS_MODAL_NAME;
  }

  public setBlockPlace(blockPlaceConfig: BlockPlaceRepositoryConfig) {
    this._analyticsService.setBlockPlace(blockPlaceConfig);
  }

  public checkEmptyFields(form: RequiredForm): boolean {
    return Object.values(form).reduce((acc, value) => {
      if (!acc) {
        return false;
      }

      // eslint-disable-next-line no-param-reassign
      acc = guardEmptyString(value);
      return acc;
    }, true);
  }

  public validate(form: Form): ValidateResult {
    try {
      const contact = SendNewsContactVO.create(form.contact);
      const content = SendNewsContentVO.create(form.content);
      const files = SendNewsFilesVO.create(form.files);

      return {
        isValid: true,
        entity: SendNewsEntity.create({
          contact,
          content,
          files,
        }),
      };
    } catch (error) {
      const errorTexts = {
        contact: '',
        content: '',
        files: '',
      };

      if (error instanceof SendNewsContactVOError) {
        return {
          isValid: false,
          error: {
            ...errorTexts,
            contact: error.message,
          },
        };
      }

      if (error instanceof SendNewsContentVOError) {
        return {
          isValid: false,
          error: {
            ...errorTexts,
            content: error.message,
          },
        };
      }

      if (error instanceof SendNewsFilesVOError) {
        return {
          isValid: false,
          error: {
            ...errorTexts,
            files: error.message,
          },
        };
      }

      return {
        isValid: false,
        error: errorTexts,
      };
    }
  }

  public validateFiles(value: File[]): { isValid: boolean; text: string } {
    try {
      SendNewsFilesVO.create(value);
      return { isValid: true, text: '' };
    } catch (error) {
      if (error instanceof SendNewsFilesVOError) {
        return { isValid: false, text: error.message };
      }
      return { isValid: false, text: '' };
    }
  }

  public async processSendNews(form: SendNewsEntity) {
    try {
      const captchaToken = await this._captcha.execute(this._captchaAction);

      await this._sendNewsApi.send({
        regionId: this._regionId,
        contact: form.contact.value,
        content: form.content.value,
        attaches: form.files.value,
        captchaToken,
      });

      this._analyticsService.sendEventSendNews();
    } catch (error) {
      throw SendNewsUseCaseError.of('Не удалось отправить новость.', error as Error);
    }
  }

  public processOpenModal() {
    this._analyticsService.sendEventOpenModal();
  }
}
