import { guardUnspecified } from '@smh/utils/guards';

import type { ILogger, ImageVM } from '@jtnews/shared/seedwork/frontend/application';
import { ImageAspectRatio } from '@jtnews/shared/seedwork/frontend/application';
import {
  Presenter,
  presentResponsiveImageData,
} from '@jtnews/shared/seedwork/frontend/infrastructure';
import type { AccountVM, IAccountPresenter } from '@jtnews/users/frontend/application';
import { AccountPresenterError } from '@jtnews/users/frontend/application';

import type { AccountDTO } from './account.dto';

type AccountPresenterConfig = {
  deps: {
    logger: ILogger;
  };
};

export class AccountPresenter
  extends Presenter<AccountVM, AccountDTO, null>
  implements IAccountPresenter<AccountDTO>
{
  private readonly _avatarSize = 32;

  constructor(config: AccountPresenterConfig) {
    super(config);
  }

  present(input: { data: AccountDTO; params: null }): AccountVM {
    try {
      const {
        data: {
          avatar,
          bookmarks,
          commentFormProfileLink,
          email,
          featureFlags,
          gameStat,
          hasMailAccount,
          hasMailsCounter,
          hasNgsEmail,
          hasProfile,
          isBlocked,
          isNotifyEmailValidated,
          link,
          login,
          notifyAboutCommentModeration,
          notifyAboutNewAnswers,
          notifyAboutNewVotes,
          notifyEmail,
          passportLogin,
          pushCommentsNewAnswerFeatureFlag,
          sex,
          trusted,
          userId,
        },
      } = input;

      const profilePageLink = `/profile/${userId}`;

      return {
        avatar: this._presentImage(avatar),
        bookmarks: guardUnspecified(bookmarks) ? bookmarks : [],
        bookmarksLink: `${profilePageLink}/?tab=bookmarks`,
        notificationsLink: `${link}?tab=notices`,
        commentFormProfileLink,
        email,
        featureFlags,
        gameStat,
        hasMailAccount,
        hasMailsCounter,
        hasNgsEmail,
        hasProfile,
        isBlocked,
        isNotifyEmailValidated,
        isTrusted: trusted,
        link,
        login,
        notifyAboutCommentModeration,
        notifyAboutNewAnswers,
        notifyAboutNewVotes,
        notifyEmail,
        passportLogin,
        profilePageLink,
        profileSettingsLink: '/profile',
        pushCommentsNewAnswerFeatureFlag,
        sex: sex === '' ? 'unknown' : sex,
        userId: Number(userId),
      };
    } catch (err) {
      throw AccountPresenterError.of('Не удалось выполнить present', err as Error);
    }
  }

  private _presentImage(url: string): ImageVM {
    return presentResponsiveImageData({
      url,
      width: this._avatarSize,
      values: [],
      aspectRatio: ImageAspectRatio.Square,
      isPreloadImage: false,
      isLazy: false,
    }).data;
  }
}
