import { AnalyticsService, IAnalyticsService } from '../analytics';
import type { AnalyticsServiceConfig } from '../analytics';

enum ActionType {
  View = 'Просмотр',
  Click = 'Клик',
}

export interface IThemesBlockAnalyticsService extends IAnalyticsService {
  sendEventArticleClick(url: string, index: number): void;
  sendEventBlockShown(url: string, index: number): void;
}

export type ThemesBlockAnalyticsServiceConfig = AnalyticsServiceConfig;

export class ThemesBlockAnalyticsService
  extends AnalyticsService
  implements IThemesBlockAnalyticsService
{
  private readonly _blockName = 'Строка сюжетов';

  constructor(config: ThemesBlockAnalyticsServiceConfig) {
    super(config);
  }

  sendEventArticleClick(url: string, index: number): void {
    this.analytics.send({
      event: {
        pageName: this.pageName,
        blockType: this.blockPlace,
        fieldType: this._blockName,
        goalName: 'clickSliderArticle',
        valueName: { [ActionType.Click]: { [`${index + 1}`]: url } },
      },
    });
    this.analytics.send({
      event: {
        pageName: this.pageName,
        blockType: this._blockName,
        actionType: ActionType.Click,
        goalName: 'clickSliderArticle',
        prop1: `${index + 1}`,
        prop2: url,
      },
    });
  }

  sendEventBlockShown(url: string, index: number): void {
    this.analytics.send({
      event: {
        pageName: this.pageName,
        blockType: this.blockPlace,
        fieldType: this._blockName,
        goalName: 'viewSliderArticle',
        valueName: { [ActionType.View]: { [`${index + 1}`]: url } },
      },
    });
    this.analytics.send({
      event: {
        pageName: this.pageName,
        blockType: this._blockName,
        actionType: ActionType.View,
        goalName: 'viewSliderArticle',
        prop1: `${index + 1}`,
        prop2: url,
      },
    });
  }
}
