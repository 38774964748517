import { environment } from '@jtnews/shared/environments';
import type { ILogger } from '@jtnews/shared/seedwork/frontend/application';
import { Logger, DevLogger } from '@jtnews/shared/seedwork/frontend/infrastructure';

const {
  prod,
  sentryDSN,
  sentryUseProxy,
  localBuild,
  envType,
  mode,
  release,
  bundleType
} = environment;

export const logger: ILogger =
  prod && !localBuild
    ? new Logger({
        dsn: sentryDSN,
        useProxy: sentryUseProxy,
        environment: envType ? 'development' : mode,
        release: release.toString(),
        tags: {
          ['env_type']: envType,
          ['app_type']: bundleType
        },
        extra: ['reason', 'regionId'],
        exclude: [
          'Ошибка инициализации OneSignal [OneSignal: This app ID does not match any existing app. Double check your app ID.]',
          'Ошибка инициализации OneSignal [OneSignal is not defined]'
        ]
      })
    : new DevLogger();
