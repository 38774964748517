import { guardUnspecified } from '@smh/utils/guards';

import type { BlockConfigDTO } from './block.dto';

export type Nullable<T> = T | null | undefined;
export type Truthy<T, F> = { [K in keyof T]-?: Exclude<T[K], F> };

export const guardBlockHasInfoData = <T extends { data?: unknown }>(
  val: Nullable<T>,
): val is Truthy<T, 'data'> => guardUnspecified(val) && guardUnspecified(val.data);

export const guardBlockLazy = <T>(val: BlockConfigDTO<T>) => !guardUnspecified(val.path);

export const guardBlockMobile = <T>(val: BlockConfigDTO<T>) =>
  !guardUnspecified(val.settings.types) ||
  (guardUnspecified(val.settings.types) && val.settings.types.mobile === true);
