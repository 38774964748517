import { createApp } from './app/main';

export const render = (): void => {
  const { app, store, router } = createApp();

  store.state.value = window.__PINIA_INITIAL_STATE__;

  // wait until router has resolved all async before hooks
  // and async components...
  router.onReady(() => {
    // eslint-disable-next-line
    // router.beforeResolve((to: any, from: any, next: any) => {
    //   const matched = router.getMatchedComponents(to);
    //   const prevMatched = router.getMatchedComponents(from);

    //   let diffed = false;
    //   const activated = matched.filter((component: any, i: any) => {
    //     if (diffed) {
    //       return diffed;
    //     }
    //     diffed = prevMatched[i] !== component;
    //     return diffed;
    //   });

    //   const asyncDataHooks = activated
    //     .map((component: any) => component.asyncData)
    //     .filter(_ => _);
    //   if (!asyncDataHooks.length) {
    //     return next();
    //   }
    //   // Promise.all(asyncDataHooks.map((hook: any) => hook({ store, route: to })))
    //   //   .then(() => next())
    //   //   .catch(next);
    // });

    // actually mount to DOM
    app.$mount('#app');
  });
};
