import { AppError } from '@jtnews/shared/seedwork/frontend/application';

type ApiErrorMessage = {
  caption?: string;
  description: string;
};

export class AuthorizationUseCaseError extends AppError {
  name: 'AuthorizationUseCaseError';
  caption: string;
  description: string;

  private constructor(message: ApiErrorMessage, error?: Error) {
    super(message.description, error);

    this.name = 'AuthorizationUseCaseError';
    this.caption = message.caption ?? '';
    this.description = message.description;
  }

  static of(message: ApiErrorMessage, error?: Error) {
    return new AuthorizationUseCaseError(message, error);
  }
}
