import { DomainError } from '@jtnews/shared/seedwork/frontend/domain';

import { AppError } from '../app-error';

export const LOGGER_KEY = 'logger_key';

export type LoggerCaptureInput = {
  error: AppError | DomainError;
  extra?: string[];
  tags?: Record<string, number | string | boolean | bigint | symbol | null | undefined>;
};

export interface ILogger {
  capture(input: LoggerCaptureInput): void;
}
