import {
  guardUnspecified,
  guardEmptyString,
  guardMinLengthString,
  guardXssString,
  guardEmailString,
  guardPhoneString,
} from '@smh/utils/guards';

import { ValueObject } from '../value-object';

type SendNewsContactProps = {
  value: string;
};

export class SendNewsContactVOError extends Error {
  override name: string;

  private constructor(message: string, error?: Error) {
    super(message, error);

    this.name = 'SendNewsContactVOError';
  }

  static of(message: string, error?: Error) {
    return new SendNewsContactVOError(message, error);
  }
}

export class SendNewsContactVO extends ValueObject<SendNewsContactProps> {
  static readonly MIN_LENGTH = 5;
  static readonly CORRUPTED_PHONE_REGEX = /[0-9+-]+/;

  private constructor(props: SendNewsContactProps) {
    super(props);
  }

  get value() {
    return this.props.value;
  }

  public static create(contact: string): SendNewsContactVO {
    if (!guardUnspecified(contact)) {
      throw SendNewsContactVOError.of('Введите E-mail');
    }

    if (!guardEmptyString(contact)) {
      throw SendNewsContactVOError.of('Введите E-mail');
    }

    if (!guardMinLengthString(contact, SendNewsContactVO.MIN_LENGTH)) {
      throw SendNewsContactVOError.of(
        'Номер телефона или E-mail должны быть не меньше пяти символов',
      );
    }

    if (!guardEmailString(contact) && !guardPhoneString(contact)) {
      const phoneMatch = this.CORRUPTED_PHONE_REGEX.exec(contact);
      if (guardUnspecified(phoneMatch) && phoneMatch[0] === phoneMatch?.input) {
        throw SendNewsContactVOError.of('Введите корректный номер телефона');
      }
      throw SendNewsContactVOError.of('Введите корректный E-mail');
    }

    if (!guardXssString(contact)) {
      throw SendNewsContactVOError.of('Введите корректный E-mail');
    }

    return new SendNewsContactVO({ value: contact });
  }
}
