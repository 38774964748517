









import { Component, Prop, Vue } from 'vue-property-decorator';

import type { SPData } from './ui-sp.contract';

@Component({
  name: 'UiSP',
})
export default class UiSP extends Vue {
  @Prop({
    required: true,
    type: Object as () => SPData,
  })
  block: SPData;

  get link() {
    return this.block.link;
  }
}
