import { AppError } from '@jtnews/shared/seedwork/frontend/application';

export class AccountPresenterError extends AppError {
  name: 'AccountPresenterError';

  private constructor(message: string, error?: Error) {
    super(message, error);

    this.name = 'AccountPresenterError';
  }

  static of(message: string, error?: Error) {
    return new AccountPresenterError(message, error);
  }
}
