import { guardUnspecified } from '@smh/utils/guards';

import type {
  LazyBlockWithData,
  LazyBlockWithoutData,
} from '@jtnews/shared/seedwork/frontend/application';

import type { PresenterConfig } from '../presenter';
import { Presenter } from '../presenter';

import type { BlockConfigDTO, BlockReachGoals } from './block.dto';
import { guardBlockHasInfoData, guardBlockLazy, guardBlockMobile } from './block.guard';
import type { Nullable, Truthy } from './block.guard';

type BlockPresenterConfig = PresenterConfig;

export const presentReachGoals = (rg?: BlockReachGoals) => ({
  general: rg?.general ?? [],
  view: rg?.view ?? [],
});

export abstract class BlockPresenter<TOutput, TData, TParams = null> extends Presenter<
  TOutput,
  TData,
  TParams
> {
  constructor(config: BlockPresenterConfig) {
    super(config);
  }

  protected presentReachGoals(rg?: BlockReachGoals) {
    return {
      general: rg?.general ?? [],
      view: rg?.view ?? [],
    };
  }

  protected guardBlockHasInfoData<T extends { data?: unknown }>(
    val: Nullable<T>,
  ): val is Truthy<T, 'data'> {
    return guardBlockHasInfoData(val);
  }

  protected guardBlockLazy<T>(val: BlockConfigDTO<T>) {
    return guardBlockLazy(val);
  }

  protected guardBlockMobile<T>(val: BlockConfigDTO<T>) {
    return guardBlockMobile(val);
  }

  protected createLazyBlock<TType>(
    type: TType,
    data?: TData,
  ): LazyBlockWithoutData<TType>;

  protected createLazyBlock<TType, TData>(
    type: TType,
    data: TData,
  ): LazyBlockWithData<TType, TData>;

  protected createLazyBlock<TType, TData>(type: TType, data?: TData) {
    if (guardUnspecified(data)) {
      return {
        isLazy: true,
        type,
        data,
      };
    }

    return {
      isLazy: true,
      type,
    };
  }
}
