import {
  formatComponentName,
  generateComponentTrace,
  getComponentMetadata
} from '@smh/monitoring/sentry-client';
import type { VueExtended } from '@smh/monitoring/sentry-client';
import type { Pinia } from 'pinia';
import { PiniaVuePlugin, createPinia } from 'pinia';
import Vue from 'vue';
import Router from 'vue-router';

import { observeVisibilityPlugin } from '@jtnews/shared/observe-visibility';

import { VueHandlerError } from '../vue-handler.error';

import App from './app.vue';
import { logger } from './client.container';
import { createRouter } from './router';

Vue.use(PiniaVuePlugin);

enum VueErrorLevel {
  Warning = 'Warning',
  Error = 'Error'
}

const createErrorHandler =
  (level: VueErrorLevel) =>
  (errorOrMessage: Error | string, vm: Vue, info: string): void => {
    const metadata = getComponentMetadata(vm as VueExtended);

    const prefix = `VueHandler${level}`;

    const error =
      typeof errorOrMessage === 'string'
        ? VueHandlerError.of(`${prefix}: ${errorOrMessage} - ${info}`, metadata)
        : VueHandlerError.of(
            `${prefix}: ${errorOrMessage.message} - ${info}`,
            metadata,
            errorOrMessage
          );

    logger.capture({
      error,
      extra: ['metadata']
    });
  };

Vue.config.errorHandler = createErrorHandler(VueErrorLevel.Error);
Vue.config.warnHandler = createErrorHandler(VueErrorLevel.Warning);

Vue.directive('observe-visibility', observeVisibilityPlugin.directive);

export type Application = {
  app: Vue;
  store: Pinia;
  router: Router;
};

export const createApp = (): Application => {
  const router = createRouter();

  router.beforeEach((to, from, next) => {
    const cacheUrlsRegex = new RegExp(
      /(webcache\.googleusercontent\.com|yandexwebcache\.net)/,
      'gi'
    );
    if (
      typeof window !== 'undefined' &&
      window.location.href.match(cacheUrlsRegex) !== null
    ) {
      return false;
    }
    next();
  });

  const store = createPinia();

  const app = new Vue({
    router,
    pinia: store,
    render: h => h(App)
  });

  return { app, router, store };
};
