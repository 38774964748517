import type { ILogger } from '@jtnews/shared/seedwork/frontend/application';
import { BlockPresenter } from '@jtnews/shared/seedwork/frontend/infrastructure';
import { BookmarkVM } from '@jtnews/users/frontend/application';

import { BookmarkDTO } from './bookmark.dto';

export type BookmarkPresenterConfig = {
  deps: {
    logger: ILogger;
  };
};

export abstract class BookmarkPresenter<TOutput, TData> extends BlockPresenter<
  TOutput,
  TData
> {
  constructor(config: BookmarkPresenterConfig) {
    super(config);
  }

  presentBookmark(data: BookmarkDTO): BookmarkVM {
    return {
      id: data.id,
      type: data.type,
      bookmark: { id: data.bookmark.id },
    };
  }
}
