import { ILogger, IPresenter } from '@jtnews/shared/seedwork/frontend/application';

export type PresenterConfig = {
  deps: {
    logger: ILogger;
  };
};

export abstract class Presenter<TOutput, TData, TParams = null>
  implements IPresenter<TOutput, TData, TParams>
{
  protected logger: ILogger;

  constructor(config: PresenterConfig) {
    const {
      deps: { logger },
    } = config;

    this.logger = logger;
  }

  abstract present(input: { data: TData; params: TParams }): TOutput;
}
