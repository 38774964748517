import { CreateElement } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

const LAZY_BLOCK_ATTR = 'data-lazy-block';

@Component({
  name: 'LazyBlock',
})
export default class LazyBlock extends Vue {
  @Prop({
    type: Number,
    default: 500,
  })
  triggerDistance!: number;

  canRender = false;

  intersectionObserver: IntersectionObserver | null = null;

  mounted() {
    if (this.$el.hasAttribute(LAZY_BLOCK_ATTR)) {
      this.intersectionObserver = new IntersectionObserver(
        this.handleIntersection.bind(this),
        {
          rootMargin: `${this.triggerDistance}px`,
        },
      );

      this.intersectionObserver.observe(this.$el);
    }
  }

  beforeDestroy() {
    if (this.intersectionObserver) {
      this.intersectionObserver.disconnect();
    }
  }

  handleIntersection(entries: IntersectionObserverEntry[]) {
    const [entry] = entries;
    if (entry.isIntersecting) {
      this.canRender = true;
      this.intersectionObserver?.disconnect();
      this.intersectionObserver = null;
    }
  }

  render(h: CreateElement) {
    const contents = this.$scopedSlots.default?.({
      canRender: this.canRender,
    });

    return (
      contents ??
      h('div', {
        attrs: {
          ...this.$attrs,
          [LAZY_BLOCK_ATTR]: true,
        },
      })
    );
  }
}
