import { IAnalytics } from './analytics';

type ReachGoalPropValue = {
  [prop: string]: Record<string, ReachGoalPropValue | string> | string;
};

export const REACH_GOAL_ANALYTICS_KEY = 'reach_goal_analytics_key';

export type ReachGoalProp =
  | string
  | number
  | string[]
  | Record<string, string | ReachGoalPropValue>;

export type ReachGoalByProductName = {
  pageName: string;
  blockType?: string;
  fieldType: string;
  goalName: string;
  valueName: ReachGoalProp;
  productName?: string;
};

export type ReachGoalByProps = {
  pageName: string;
  goalName: string;
  blockType: string;
  actionType: string;
  prop1?: string;
  prop2?: ReachGoalProp;
};

export type ReachGoalOnlyGoal = string;

export type ReachGoal = ReachGoalByProductName | ReachGoalByProps | ReachGoalOnlyGoal;

export interface IReachGoalAnalytics extends IAnalytics<ReachGoal, unknown> {
  send(input: { event: ReachGoal; params?: unknown }): void;
}
