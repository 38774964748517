import type { BookmarkVM } from '@jtnews/users/frontend/application';
import { BookmarkPresenterError } from '@jtnews/users/frontend/application';

import type { BookmarkApiResponseDTO } from './bookmark.dto';
import { BookmarkPresenter, BookmarkPresenterConfig } from './bookmark.presenter';

type BookmarkPresenterInput = BookmarkApiResponseDTO;

export class BookmarkSpecPresenter extends BookmarkPresenter<
  BookmarkVM | null,
  BookmarkPresenterInput
> {
  constructor(config: BookmarkPresenterConfig) {
    super(config);
  }

  present(input: { data: BookmarkPresenterInput; params: null }): BookmarkVM | null {
    try {
      const { data } = input;

      if (this.guardBlockHasInfoData(data)) {
        return this.presentBookmark(data.data);
      }
      return null;
    } catch (err) {
      throw BookmarkPresenterError.of('Не удалось выполнить present', err as Error);
    }
  }
}
