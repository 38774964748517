import type {
  AnalyticsServiceConfig,
  IAnalyticsService,
} from '@jtnews/shared/seedwork/frontend/domain';
import { AnalyticsService } from '@jtnews/shared/seedwork/frontend/domain';

enum ActionType {
  View = 'Просмотр',
  Close = 'Закрыть (крестик)',
}

enum ActionGoals {
  View = 'viewFullscreen',
  Close = 'clickFullscreen',
}

export type FullscreenAdvBlockAnalyticsServiceConfig = AnalyticsServiceConfig;

export interface IFullscreenAdvBlockAnalyticsService extends IAnalyticsService {
  sendEventAdvMounted(): void;
  sendEventAdvClosed(): void;
}

export class FullscreenAdvBlockAnalyticsService
  extends AnalyticsService
  implements IFullscreenAdvBlockAnalyticsService
{
  private readonly _blockName = 'Фулскрин';
  private readonly _productType = 'Реклама';

  constructor(config: FullscreenAdvBlockAnalyticsServiceConfig) {
    super(config);
  }

  sendEventAdvMounted(): void {
    this._sendEvent(ActionGoals.View);
  }

  sendEventAdvClosed(): void {
    this._sendEvent(ActionGoals.Close);
  }

  private _sendEvent(goalName: ActionGoals): void {
    const valueName = goalName === ActionGoals.View ? ActionType.View : ActionType.Close;

    this.analytics.send({
      event: {
        productName: this._productType,
        pageName: this.pageName,
        blockType: this.blockPlace,
        fieldType: this._blockName,
        valueName,
        goalName,
      },
    });

    this.analytics.send({
      event: {
        pageName: this.pageName,
        blockType: this.blockPlace,
        actionType: valueName,
        goalName,
        prop1: this._blockName,
        prop2: this._productType,
      },
    });
  }
}
