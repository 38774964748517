










































































import { lock, unlock } from '@smh/utils/body-scroll-lock';
import { clickOutside } from '@smh/utils/click-outside';
import { guardUnspecified } from '@smh/utils/guards';
import { Component, Inject, Vue, Watch } from 'vue-property-decorator';

import type { IPageHeaderUseCase } from '@jtnews/shared/seedwork/frontend/application';
import { PAGE_HEADER_USE_CASE_KEY } from '@jtnews/shared/seedwork/frontend/application';
import { AnalyticsBlockPlace, Regions } from '@jtnews/shared/seedwork/frontend/domain';
import { UiLogo, LazyHydrate } from '@jtnews/shared/ui';

import InformerBlock from '../informer-block/informer-block.component.vue';

import PageHeaderSearch from './page-header-search/page-header-search.component.vue';
import { PageHeaderServices } from './page-header-services';

/* eslint-disable @typescript-eslint/naming-convention */
const PageHeaderSiteMenu = () =>
  import(
    /* webpackChunkName: "page-header-site-menu" */ './page-header-site-menu'
  ).then((m) => m.PageHeaderSiteMenu);
/* eslint-enable @typescript-eslint/naming-convention */

const DESKTOP_ADV_HEIGHT = 250;
const PROJECT_BAR_HEIGHT = 36;

@Component({
  name: 'PageHeader',
  components: {
    InformerBlock,
    LazyHydrate,
    PageHeaderSearch,
    PageHeaderServices,
    PageHeaderSiteMenu,
    UiLogo,
  },
})
export default class PageHeader extends Vue {
  @Inject(PAGE_HEADER_USE_CASE_KEY) private readonly _useCase!: IPageHeaderUseCase;

  isSticky = false;
  isIgnoreScroll = false;

  menuIsOpen = false;
  notificationsIsOpen = false;
  searchIsOpen = false;

  menuMaxHeight: number | null = null;

  listeners = {
    scroll: () => {},
  };

  get commonData() {
    return this._useCase.commonData;
  }

  get headerData() {
    return this._useCase.headerData;
  }

  get isMobile() {
    return this._useCase.isMobile;
  }

  get pageName() {
    return this._useCase.pageName;
  }

  get regionId() {
    return this._useCase.regionId;
  }

  get hasAdvert() {
    return guardUnspecified(this.$slots.advert);
  }

  get isShowWeatherTooltip() {
    return !(this.menuIsOpen || this.notificationsIsOpen);
  }

  get wrapperStyles() {
    if (guardUnspecified(this.menuMaxHeight)) {
      return { maxHeight: `${this.menuMaxHeight}px` };
    }
  }

  get isLongLogo() {
    return (
      this.isMobile &&
      [
        Regions.Vladivostok,
        Regions.Sterlitamak,
        Regions.Voronezh,
        Regions.Izhevsk,
      ].includes(this.regionId)
    );
  }

  get headerStickyOffset() {
    return this.isMobile ? PROJECT_BAR_HEIGHT : PROJECT_BAR_HEIGHT + DESKTOP_ADV_HEIGHT;
  }

  get subscribePageLink() {
    return this.commonData.subscribePageLink;
  }

  get socials() {
    return this.commonData.socials;
  }

  get mobileApps() {
    return this.commonData.mobileApps;
  }

  get currency() {
    return this.commonData.currency;
  }

  get traffic() {
    return this.commonData.traffic;
  }

  get weather() {
    return this.commonData.weather;
  }

  get services() {
    return this.headerData.services?.data ?? [];
  }

  get rubrics() {
    return this.headerData.rubrics?.data ?? [];
  }

  get themes() {
    return this.headerData.themes?.data ?? [];
  }

  get informerBlockPlaceConfig() {
    return { defaultPlace: AnalyticsBlockPlace.Menu, ignoreBreakpoints: true };
  }

  @Watch('menuIsOpen')
  onMenuIsOpenChanged(value: boolean) {
    if (value) {
      this.processMenuOpen();
      lock();
      this.checkMenuTopPosition();

      return;
    }

    this.processMenuClose();
    unlock();
  }

  toggleMenu() {
    this.menuIsOpen = !this.menuIsOpen;
  }

  checkMenuTopPosition() {
    const btn = this.$refs.menuBtn as HTMLElement;

    if (guardUnspecified(btn)) {
      const { bottom } = btn.getBoundingClientRect();
      const { clientHeight } = document.documentElement;
      this.menuMaxHeight = clientHeight - bottom;
    }
  }

  setClickOutside() {
    setTimeout(() => {
      if (this.menuIsOpen) {
        const container = (this.$refs.menuContainer as Vue).$el as HTMLElement;
        if (!container) {
          return;
        }

        clickOutside(
          container,
          () => {
            this.processMenuClose();
          },
          { isSkipFirstClick: false },
        );
      }
    }, 0);
  }

  created() {
    this._useCase.setBlockPlace({
      defaultPlace: AnalyticsBlockPlace.Menu,
      ignoreBreakpoints: true,
    });
  }

  mounted() {
    this.listeners.scroll = this.onScroll.bind(this);

    window.addEventListener('scroll', this.listeners.scroll);
  }

  beforeDestroy() {
    window.removeEventListener('scroll', this.listeners.scroll);
  }

  onScroll() {
    if (this.isIgnoreScroll) {
      return;
    }
    this.isSticky = window.pageYOffset > this.headerStickyOffset;
  }

  processMenuOpen() {
    this.isIgnoreScroll = true;
    this._useCase.processRubricsMenuOpen();
  }

  processMenuClose() {
    this.menuIsOpen = false;
    this.isIgnoreScroll = false;
  }

  processRubricClick(rubricName: string) {
    this._useCase.processRubricClick(rubricName);
  }

  processProjectClick(projectName: string) {
    this._useCase.processProjectClick(projectName);
  }

  processSearchOpen() {
    this._useCase.processSearchOpen();
  }

  processSearchSubmit(event: { event: Event; value: string }) {
    this._useCase.processSearchSubmit(event);
  }
}
