import { AppError } from '@jtnews/shared/seedwork/frontend/application';

export class AccountForumsUseCaseError extends AppError {
  name: 'AccountForumsUseCaseError';

  private constructor(message: string, error?: Error) {
    super(message, error);

    this.name = 'AccountForumsUseCaseError';
  }

  static of(message: string, error?: Error) {
    return new AccountForumsUseCaseError(message, error);
  }
}
