
















import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

import { UiWeatherIcon } from '../ui-weather-icon';

import type { Weather } from './ui-weather.contract';

@Component({
  name: 'UiWeather',
  components: { UiWeatherIcon },
})
export default class UiWeather extends Vue {
  @Prop({
    required: true,
    type: Object,
  })
  block!: Weather;

  @Prop({
    required: true,
    type: Boolean,
  })
  isHeaderPosition!: boolean;

  get icon() {
    return this.block.icon;
  }

  get temperature() {
    return this.block.temperature.value;
  }

  get link() {
    return this.block.more;
  }

  get title() {
    return this.block.title;
  }

  get componentType(): string {
    if (!this.link || this.link.startsWith('?')) {
      return 'div';
    }

    return 'a';
  }

  @Emit('onLinkClicked')
  onLinkClicked() {
    return this.title;
  }
}
