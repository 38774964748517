import { AppError } from '@jtnews/shared/seedwork/frontend/application';

export class PushNotificationsUseCaseError extends AppError {
  name: 'PushNotificationsUseCaseError';

  private constructor(message: string, error?: Error) {
    super(message, error);

    this.name = 'PushNotificationsUseCaseError';
  }

  static of(message: string, error?: Error) {
    return new PushNotificationsUseCaseError(message, error);
  }
}
