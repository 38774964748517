/* eslint-disable @typescript-eslint/no-explicit-any */

export interface IAppNewsClientResponse<T = any> {
  data: T;
  status: number;
  statusText: string;
  headers: Record<string, string>;
  responseTime: number;
}

export interface IAppNewsClientBasicCredentials {
  username: string;
  password: string;
}

export interface IAppNewsClientRetryOptions {
  maxTry: number;
  delay?: number;
}

export interface IAppNewsClientRequestConfig {
  url?: string;
  version?: string;
  domain?: string;
  params?: any;
  headers?: any;
  withCredentials?: boolean;
  auth?: IAppNewsClientBasicCredentials;
  retry?: IAppNewsClientRetryOptions;
  delay?: number;
}

export interface IAppNewsClient {
  accept: string;
  version: string;
  get<T = any, R = IAppNewsClientResponse<T>>(
    regionId: number,
    url: string,
    config?: IAppNewsClientRequestConfig,
    domain?: string,
  ): Promise<R>;
  post<T = any, R = IAppNewsClientResponse<T>, D = any>(
    regionId: number,
    url: string,
    data?: D,
    config?: IAppNewsClientRequestConfig,
    domain?: string,
  ): Promise<R>;
  put<T = any, R = IAppNewsClientResponse<T>, D = any>(
    regionId: number,
    url: string,
    data?: D,
    config?: IAppNewsClientRequestConfig,
    domain?: string,
  ): Promise<R>;
  patch<T = any, R = IAppNewsClientResponse<T>, D = any>(
    regionId: number,
    url: string,
    data?: D,
    config?: IAppNewsClientRequestConfig,
    domain?: string,
  ): Promise<R>;
  delete<T = any, R = IAppNewsClientResponse<T>>(
    regionId: number,
    url: string,
    config?: IAppNewsClientRequestConfig,
    domain?: string,
  ): Promise<R>;
}

export type NewsClientConfig = {
  deps: {
    client: IAppNewsClient;
  };
};
export abstract class NewsClient {
  protected client: IAppNewsClient;

  constructor(config: NewsClientConfig) {
    const {
      deps: { client },
    } = config;

    this.client = client;
  }
}
