import { getPreloadImageString } from '@smh/utils/dom';
import { guardEmptyString, guardUnspecified } from '@smh/utils/guards';

import {
  ImageAspectRatio,
  ImageDecoding,
  ImageLoading,
  ImageSourceVM,
  ResponsiveImageVM,
} from '@jtnews/shared/seedwork/frontend/application';

const WEBP_EXT = '.webp-portal';
const WEBP_TYPE = 'image/webp';

const getHeightByWidth = (width: number, aspectRatio: ImageAspectRatio): number =>
  Math.round(width / aspectRatio);

const getImageUrl = (url: string, width: number, height = 0, crop = true): string => {
  const replacePattern = '##';

  if (!guardEmptyString(url)) {
    throw new Error('getImageUrl: url is empty');
  }

  if (!url.includes(replacePattern)) {
    throw new Error('getImageUrl: replace pattern not found');
  }

  const endReplaceValue = height > 0 ? `_${height}${crop ? '_c' : ''}` : '';

  return url.replace(replacePattern, `_${width}${endReplaceValue}.`);
};

const getWebpImageUrl = (url: string): string => `${url}${WEBP_EXT}`;

const getImageSources = (input: ResponsiveImageParams): ImageSourceVM[] => {
  try {
    const {
      url,
      values,
      aspectRatio = ImageAspectRatio.Wide,
      isCrop = true,
      hasHeight = true,
    } = input;

    return values.map((val) => {
      const { width, breakpoint, noMedia = false, hasRetina = true } = val;
      const media = !noMedia && breakpoint > 0 ? `(max-width: ${breakpoint}px)` : '';
      const height = hasHeight ? getHeightByWidth(width, aspectRatio) : 0;

      const imageUrl = getImageUrl(url, width, height, isCrop);
      const webpImageUrl = getWebpImageUrl(imageUrl);
      let srcset = webpImageUrl;

      if (hasRetina) {
        const width2x = width * 2;
        const height2x = hasHeight ? getHeightByWidth(width2x, aspectRatio) : 0;
        const image2x = getImageUrl(url, width2x, height2x, isCrop);
        const webpImage2x = getWebpImageUrl(image2x);

        srcset = `${webpImageUrl} 1x, ${webpImage2x} 2x`;
      }

      return {
        media,
        srcset,
        type: WEBP_TYPE,
      };
    });
  } catch {
    return [];
  }
};
export type ResponsiveImageValues = {
  width: number;
  breakpoint: number;
  noMedia?: boolean;
  hasRetina?: boolean;
};

export type ResponsiveImageParams = {
  width: number;
  url: string;
  isCrop?: boolean;
  aspectRatio?: ImageAspectRatio;
  isLazy?: boolean;
  isPreloadImage?: boolean;
  values: ResponsiveImageValues[];
  hasHeight?: boolean;
  hasCommercialLabel?: boolean;
};

export const presentImagePreloadLink = (input: ResponsiveImageParams): string => {
  const {
    url,
    values,
    aspectRatio = ImageAspectRatio.Wide,
    isCrop = true,
    hasHeight = true,
  } = input;

  if (!guardEmptyString(url)) {
    return '';
  }

  return values.reduce((preload, item, index) => {
    /* eslint-disable no-param-reassign */
    const { width, breakpoint, noMedia = false, hasRetina = true } = item;
    const isFirst = index === 0;
    const isLast = index === values.length - 1;
    const media = !noMedia && breakpoint > 0 ? `(max-width: ${breakpoint}px)` : '';
    const height = hasHeight ? getHeightByWidth(width, aspectRatio) : 0;
    let srcset = '';
    let preloadImageUrl = '';
    let imageType = WEBP_TYPE;

    try {
      const imageUrl = getImageUrl(url, width, height, isCrop);

      preloadImageUrl = getWebpImageUrl(imageUrl);

      if (hasRetina) {
        const width2x = width * 2;
        const height2x = hasHeight ? getHeightByWidth(width2x, aspectRatio) : 0;
        const image2x = getImageUrl(url, width2x, height2x, isCrop);
        const webpImage2x = getWebpImageUrl(image2x);

        srcset = `${preloadImageUrl} 1x, ${webpImage2x} 2x`;
      } else {
        srcset = preloadImageUrl;
      }
    } catch {
      preloadImageUrl = url;
      imageType = '';
    }

    if (isFirst && !noMedia) {
      preload = `${preload}${getPreloadImageString(
        preloadImageUrl,
        imageType,
        media,
        srcset,
      )}`;
    } else if (!isLast && !noMedia) {
      const prev = values[index - 1];

      if (!prev.noMedia) {
        preload = `${preload}${getPreloadImageString(
          preloadImageUrl,
          imageType,
          `(min-width: ${prev.breakpoint}.1px) and ${media}`,
          srcset,
        )}`;
      }
    } else if (isLast) {
      const prev = values[index - 1];

      if (guardUnspecified(prev) && !prev.noMedia) {
        preload = `${preload}${getPreloadImageString(
          preloadImageUrl,
          imageType,
          `(min-width: ${prev.breakpoint}.1px)`,
          srcset,
        )}`;
      }
    }

    return preload;
    /* eslint-enable no-param-reassign */
  }, '');
};

export const presentImageAspectRatio = (
  width: number,
  height: number,
): ImageAspectRatio => {
  const imageAspectRatio = width / height;

  if (imageAspectRatio > 1) {
    return imageAspectRatio > ImageAspectRatio.Classic
      ? ImageAspectRatio.Wide
      : ImageAspectRatio.Classic;
  }

  if (imageAspectRatio < 1) {
    return imageAspectRatio < ImageAspectRatio.VerticalClassic
      ? ImageAspectRatio.VerticalWide
      : ImageAspectRatio.VerticalClassic;
  }

  return imageAspectRatio === ImageAspectRatio.Square
    ? ImageAspectRatio.Square
    : ImageAspectRatio.None;
};

export const presentResponsiveImageData = (
  input: ResponsiveImageParams,
): ResponsiveImageVM => {
  const {
    url,
    width,
    aspectRatio = ImageAspectRatio.Wide,
    hasHeight = true,
    isPreloadImage = true,
    isCrop = true,
    isLazy = true,
    hasCommercialLabel = false,
  } = input;

  try {
    const data = {
      src: getImageUrl(
        url,
        width,
        hasHeight ? getHeightByWidth(width, aspectRatio) : 0,
        isCrop,
      ),
      sources: getImageSources(input),
      loading: isLazy ? ImageLoading.Lazy : ImageLoading.Eager,
      decoding: isPreloadImage ? ImageDecoding.Async : ImageDecoding.Auto,
      aspectRatio,
      hasCommercialLabel,
    };
    const preload = isPreloadImage ? presentImagePreloadLink(input) : '';

    return {
      data,
      preload,
    };
  } catch {
    return {
      data: {
        src: url,
        sources: [],
        aspectRatio,
        hasCommercialLabel,
      },
      preload: '',
    };
  }
};
