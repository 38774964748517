import { AnalyticsService, IAnalyticsService } from '../analytics';
import type { AnalyticsServiceConfig } from '../analytics';

type RGData = {
  currentPlace: number;
  id: number;
  isCommercial: boolean;
};

enum ActionType {
  View = 'Просмотр',
  Click = 'Переход на материал',
}

export interface INewsFeedBlockAnalyticsService extends IAnalyticsService {
  sendEventNewsView(data: RGData): void;
  sendEventArticleClick(data: RGData): void;
  sendEventAllNewsClick(): void;
  sendEventTitleClick(): void;
}

export type NewsFeedBlockAnalyticsServiceConfig = AnalyticsServiceConfig;

export class NewsFeedBlockAnalyticsService
  extends AnalyticsService
  implements INewsFeedBlockAnalyticsService
{
  private readonly _blockName = 'Лента новостей';

  constructor(config: NewsFeedBlockAnalyticsServiceConfig) {
    super(config);
  }

  sendEventAllNewsClick(): void {
    this.analytics.send({
      event: {
        pageName: this.pageName,
        blockType: this.blockPlace,
        fieldType: this._blockName,
        goalName: 'clickNewsFeed',
        valueName: 'Все новости (кнопка)',
      },
    });
  }

  sendEventArticleClick({ currentPlace, id, isCommercial }: RGData): void {
    const articleType = isCommercial ? 'Рекламный' : 'Редакционный';

    const valueName = {
      [currentPlace.toString()]: {
        [ActionType.Click]: {
          [articleType]: id.toString(),
        },
      },
    };

    this.analytics.send({
      event: {
        pageName: this.pageName,
        blockType: this.blockPlace,
        fieldType: this._blockName,
        goalName: 'clickNewsFeed',
        valueName,
      },
    });
  }

  sendEventNewsView({ currentPlace, id, isCommercial }: RGData): void {
    const articleType = isCommercial ? 'Рекламный' : 'Редакционный';
    const valueName = {
      [currentPlace.toString()]: {
        [ActionType.View]: {
          [articleType]: id.toString(),
        },
      },
    };

    this.analytics.send({
      event: {
        pageName: this.pageName,
        blockType: this.blockPlace,
        fieldType: this._blockName,
        goalName: 'viewNewsFeed',
        valueName,
      },
    });
  }

  sendEventTitleClick(): void {
    this.analytics.send({
      event: {
        pageName: this.pageName,
        blockType: this.blockPlace,
        fieldType: this._blockName,
        goalName: 'clickNewsFeed',
        valueName: 'Переход на все новости',
      },
    });
  }
}
