import { AnalyticsService, IAnalyticsService } from '../analytics';
import type { AnalyticsServiceConfig } from '../analytics';

enum ActionType {
  View = 'Просмотр',
  Click = 'Клик',
}

export interface IInformerBlockAnalyticsService extends IAnalyticsService {
  sendEventBlockShown(isHeader: boolean): void;

  sendEventLinkClicked(name: string, isHeader: boolean): void;
}

export type InformerBlockAnalyticsServiceConfig = AnalyticsServiceConfig;

export class InformerBlockAnalyticsService
  extends AnalyticsService
  implements IInformerBlockAnalyticsService
{
  private readonly _blockName = 'Информер';

  constructor(config: InformerBlockAnalyticsServiceConfig) {
    super(config);
  }

  public sendEventBlockShown(isHeader: boolean): void {
    if (isHeader) {
      return;
    }

    this.analytics.send({
      event: {
        pageName: this.pageName,
        blockType: this.blockPlace,
        fieldType: this._blockName,
        goalName: 'informer_view',
        valueName: ActionType.View,
      },
    });
  }

  public sendEventLinkClicked(name: string, isHeader: boolean): void {
    this.analytics.send({
      event: {
        pageName: this.pageName,
        blockType: this.blockPlace,
        fieldType: this._blockName,
        goalName: isHeader ? 'clickMenu' : 'informer_click',
        valueName: name,
      },
    });
  }
}
