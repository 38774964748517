






import { Component, Prop, Vue } from 'vue-property-decorator';

import type { Color, Weight, Size } from './ui-badge.contract';

@Component({
  name: 'UiBadge',
})
export default class UiBadge extends Vue {
  @Prop({
    type: String as () => Color,
    default: 'primary',
  })
  color: Color;

  @Prop({
    type: String as () => Weight,
    default: 'normal',
  })
  weight: Weight;

  @Prop({
    type: String as () => Size,
    default: 'large',
  })
  size: Size;
}
