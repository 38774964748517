import {
  BlockPlaceRepositoryConfig,
  IAdvertLogoAnalyticsService,
} from '@jtnews/shared/seedwork/frontend/domain';

import { BlockUseCase, IBlockUseCase } from '../../blocks/block-usecase';
import type { BlockUseCaseConfig } from '../../blocks/block-usecase';
import { AdvLogoData } from '../page-adv.vm';

export const ADVERT_LOGO_USE_CASE_KEY = 'advert_logo_use_case_key';

export type AdvertLogoType = 'prinzip' | 'freedom' | 'vyboryLogo' | 'kornei' | 'none';

export interface IAdvertLogoUseCase extends IBlockUseCase {
  readonly logo: AdvLogoData | null;
  processAdvLogoClick(url: string): void;
}

export type AdvertLogoUseCaseStore = {
  advLogo: AdvLogoData | null;
};

export type AdvertLogoUseCaseConfig = BlockUseCaseConfig & {
  deps: {
    analyticsService: IAdvertLogoAnalyticsService;
    store: AdvertLogoUseCaseStore;
  };
};

export class AdvertLogoUseCase extends BlockUseCase implements IAdvertLogoUseCase {
  private readonly _analyticsService: IAdvertLogoAnalyticsService;
  private readonly _store: AdvertLogoUseCaseStore;

  constructor(config: AdvertLogoUseCaseConfig) {
    super(config);

    const {
      deps: { analyticsService, store },
    } = config;

    this._analyticsService = analyticsService;
    this._store = store;
  }

  get logo(): AdvLogoData | null {
    return this._store.advLogo;
  }

  public setBlockPlace(blockPlaceConfig: BlockPlaceRepositoryConfig) {
    this._analyticsService.setBlockPlace(blockPlaceConfig);
  }

  public processAdvLogoClick(url: string) {
    this._analyticsService.sendEventAdvLogoClick(url);
  }
}
