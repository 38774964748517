import type {
  ILogger,
  IAccountForumsUseCase,
} from '@jtnews/shared/seedwork/frontend/application';

import { AccountForumsPresenterError } from './account-forums-presenter.error';
import { AccountForumsUseCaseError } from './account-forums-usecase.error';
import type { IAccountForums } from './account-forums.api';
import type { IAccountForumsPresenter } from './account-forums.presenter';
import type { AccountForumsVM } from './account-forums.vm';

export interface IAccountForumsUseCaseUserStore {
  updateAccountForums: (account: AccountForumsVM) => void;
}

export type AccountForumsUseCaseConfig<TPresenterInputData> = {
  regionId: number;
  deps: {
    logger: ILogger;
    getAccount: IAccountForums<TPresenterInputData>;
    presenter: IAccountForumsPresenter<TPresenterInputData>;
    store: IAccountForumsUseCaseUserStore;
  };
};

export class AccountForumsUseCase<TPresenterInputData> implements IAccountForumsUseCase {
  private readonly _regionId: number;

  private readonly _getAccount: IAccountForums<TPresenterInputData>;

  private readonly _presenter: IAccountForumsPresenter<TPresenterInputData>;

  private readonly _store: IAccountForumsUseCaseUserStore;

  private readonly _logger: ILogger;

  constructor(config: AccountForumsUseCaseConfig<TPresenterInputData>) {
    const {
      regionId,
      deps: { getAccount, presenter, store, logger },
    } = config;

    this._regionId = regionId;
    this._getAccount = getAccount;
    this._presenter = presenter;
    this._store = store;
    this._logger = logger;
  }

  async accountForums() {
    try {
      const { data } = await this._getAccount.accountForums({
        regionId: this._regionId,
      });

      const account = this._presenter.present({
        data,
        params: null,
      });

      this._store.updateAccountForums(account);
    } catch (err) {
      if (err instanceof AccountForumsPresenterError) {
        this._logger.capture({
          error: AccountForumsUseCaseError.of(
            'Не удалось выполнить present для аутентификации',
            err as Error,
          ),
        });

        throw err;
      }
    }
  }
}
