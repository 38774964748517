import { Pinia, defineStore } from 'pinia';

import { UserBrowserNotificationSettings } from './push-notifications.usecase';

type NotificationStoreState = {
  savedUserBrowserNotificationSettings: UserBrowserNotificationSettings;
  isPushNotificationsEnabled: boolean;
  isPushNotificationsReady: boolean;
  isPushNotificationsNotAvailable: boolean;
  isBrowserNotificationsDenied: boolean;
};

const usePushNotificationsStore = defineStore('pushNotifications', {
  state: () =>
    ({
      savedUserBrowserNotificationSettings: null,
      isPushNotificationsEnabled: false,
      isPushNotificationsReady: false,
      isPushNotificationsNotAvailable: false,
      isBrowserNotificationsDenied: false,
    } as NotificationStoreState),
  getters: {},
  actions: {},
});

export interface IPushNotificationsStore {
  updatePushNotificationsEnabled(isNotificationsEnabled: boolean): void;
  updatePushNotificationsReady(isNotificationsReady: boolean): void;
  updatePushNotificationsNotAvailable(isNotificationsNotAvailable: boolean): void;
  updateBrowserNotificationsDenied(isBrowserNotificationsDenied: boolean): void;
  updateSavedUserBrowserNotificationSettings(
    savedUserBrowserNotificationSettings: UserBrowserNotificationSettings,
  ): void;
}

export class NotificationsStoreFacade implements IPushNotificationsStore {
  private readonly _store: ReturnType<typeof usePushNotificationsStore>;

  constructor(pinia: Pinia) {
    this._store = usePushNotificationsStore(pinia);
  }

  public get savedUserBrowserNotificationSettings() {
    return this._store.savedUserBrowserNotificationSettings;
  }

  public get isPushNotificationsEnabled() {
    return this._store.isPushNotificationsEnabled;
  }

  public get isPushNotificationsReady() {
    return this._store.isPushNotificationsReady;
  }

  public get isPushNotificationsNotAvailable() {
    return this._store.isPushNotificationsNotAvailable;
  }

  public get isBrowserNotificationsDenied() {
    return this._store.isBrowserNotificationsDenied;
  }

  updatePushNotificationsEnabled(isNotificationsEnabled: boolean) {
    this._store.isPushNotificationsEnabled = isNotificationsEnabled;
  }

  updatePushNotificationsReady(isNotificationsReady: boolean) {
    this._store.isPushNotificationsReady = isNotificationsReady;
  }

  updatePushNotificationsNotAvailable(isNotificationsNotAvailable: boolean) {
    this._store.isPushNotificationsNotAvailable = isNotificationsNotAvailable;
  }

  updateBrowserNotificationsDenied(isBrowserNotificationsDenied: boolean) {
    this._store.isBrowserNotificationsDenied = isBrowserNotificationsDenied;
  }

  updateSavedUserBrowserNotificationSettings(
    savedUserBrowserNotificationSettings: UserBrowserNotificationSettings,
  ): void {
    this._store.savedUserBrowserNotificationSettings =
      savedUserBrowserNotificationSettings;
  }
}
