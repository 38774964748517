import { ILogger, IAccountUseCase } from '@jtnews/shared/seedwork/frontend/application';

import { AccountPresenterError } from './account-presenter.error';
import { AccountUseCaseError } from './account-usecase.error';
import { IAccount } from './account.api';
import { IAccountPresenter } from './account.presenter';
import type { AccountVM } from './account.vm';

export interface IAccountUseCaseUserStore {
  updateAccount: (account: AccountVM) => void;
}

export type AccountUseCaseConfig<TPresenterInputData> = {
  regionId: number;
  deps: {
    logger: ILogger;
    getAccount: IAccount<TPresenterInputData>;
    presenter: IAccountPresenter<TPresenterInputData>;
    store: IAccountUseCaseUserStore;
  };
};

export class AccountUseCase<TPresenterInputData> implements IAccountUseCase {
  private readonly _regionId: number;

  private readonly _getAccount: IAccount<TPresenterInputData>;

  private readonly _presenter: IAccountPresenter<TPresenterInputData>;

  private readonly _store: IAccountUseCaseUserStore;

  private readonly _logger: ILogger;

  constructor(config: AccountUseCaseConfig<TPresenterInputData>) {
    const {
      regionId,
      deps: { getAccount, presenter, store, logger },
    } = config;

    this._regionId = regionId;
    this._getAccount = getAccount;
    this._presenter = presenter;
    this._store = store;
    this._logger = logger;
  }

  async account() {
    try {
      const hasTtq = document.cookie.includes('ngs_ttq');

      if (!hasTtq) {
        return;
      }

      const { data } = await this._getAccount.account({
        regionId: this._regionId,
      });

      const account = this._presenter.present({
        data,
        params: null,
      });

      this._store.updateAccount(account);
    } catch (err) {
      if (err instanceof AccountPresenterError) {
        this._logger.capture({
          error: AccountUseCaseError.of(
            'Не удалось выполнить present для аутентификации',
            err as Error,
          ),
        });

        throw err;
      }
    }
  }
}
