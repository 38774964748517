import { ACTUAL_RUBRICS_REGEX_IDS } from '@jtnews/shared/data';

type ArticlesByRubricPageType = typeof import('@jtnews/records/frontend/features');

export const ARTICLES_BY_RUBRIC_PAGE_SHELL = [
  // страница поиска новости по рубрике
  {
    name: 'articlesByRubricPage',
    path: `/text/:rubricId${ACTUAL_RUBRICS_REGEX_IDS}`,
    component: (): Promise<ArticlesByRubricPageType> =>
      import(
        /* webpackChunkName: "new-home-page" */ '@jtnews/records/frontend/features'
      ).then((m) => m.ArticlesByRubricPage) as Promise<ArticlesByRubricPageType>,
  },
];
