import { guardUnspecified, guardZeroNumber } from '@smh/utils/guards';

import type { INotificationApi } from './notification.api';

export const NOTIFICATION_USE_CASE_KEY = 'notification_use_case_key';

export interface INotificationUseCase {
  readonly hasNewNotices: boolean;
  readonly newNoticesButtonText: string;
  readonly newNotificationsCount: number;
  updateNewNoticesCount(): Promise<void>;
}

export type NotificationUseCaseStore = {
  account: {
    userId: number;
  } | null;
};

export type NotificationUseCaseConfig<TNotice> = {
  regionId: number;
  deps: {
    noticesApi: INotificationApi<TNotice>;
    store: NotificationUseCaseStore;
  };
};

export class NotificationUseCase<TNotice extends { data?: { total: number } }>
  implements INotificationUseCase
{
  private readonly _noticesApi: INotificationApi<TNotice>;
  private readonly _regionId: number;
  private readonly _store: NotificationUseCaseStore;

  private _newNotificationsCount = 0;

  constructor(config: NotificationUseCaseConfig<TNotice>) {
    const {
      deps: { noticesApi, store },
      regionId,
    } = config;

    this._noticesApi = noticesApi;
    this._store = store;
    this._regionId = regionId;
  }

  public get newNotificationsCount() {
    return this._newNotificationsCount;
  }

  public get hasNewNotices() {
    return guardZeroNumber(this._newNotificationsCount);
  }

  public get newNoticesButtonText() {
    return this.hasNewNotices
      ? 'Новые уведомления в профиле'
      : 'В профиле нет новых уведомлений';
  }

  public async updateNewNoticesCount(): Promise<void> {
    if (!guardUnspecified(this._store.account)) {
      this._newNotificationsCount = 0;
      return;
    }

    const { data } = await this._noticesApi.get({
      regionId: this._regionId,
      userId: this._store.account.userId,
    });

    this._newNotificationsCount = data.data?.total ?? 0;
  }
}
