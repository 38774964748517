import { AppError } from '@jtnews/shared/seedwork/frontend/application';

export type VueHandlerErrorMetadata = {
  componentName?: string;
  trace?: string;
};

export class VueHandlerError extends AppError {
  name: 'VueHandlerError';

  metadata?: VueHandlerErrorMetadata;

  private constructor(
    message: string,
    metadata?: VueHandlerErrorMetadata,
    error?: Error
  ) {
    super(message, error);

    this.name = 'VueHandlerError';
    this.metadata = metadata;
  }

  static of(
    message: string,
    metadata?: VueHandlerErrorMetadata,
    error?: Error
  ): VueHandlerError {
    return new VueHandlerError(message, metadata, error);
  }
}
