import type {
  AnalyticsServiceConfig,
  IAnalyticsService,
} from '@jtnews/shared/seedwork/frontend/domain';
import { AnalyticsService } from '@jtnews/shared/seedwork/frontend/domain';

export type PageHeaderNotificationsAnalyticsServiceConfig = AnalyticsServiceConfig;

export interface IPageHeaderNotificationsAnalyticsService extends IAnalyticsService {
  sendEventMoreButtonClick(): void;
  sendEventNotificationAnswerClick(): void;
  sendEventNotificationLinkClick(): void;
  sendEventToggleNotifications(): void;
}

export class PageHeaderNotificationsAnalyticsService
  extends AnalyticsService
  implements IPageHeaderNotificationsAnalyticsService
{
  constructor(config: PageHeaderNotificationsAnalyticsServiceConfig) {
    super(config);
  }

  sendEventMoreButtonClick(): void {
    this.analytics.send({
      event: {
        blockType: this.blockPlace,
        fieldType: 'Пользователь',
        goalName: 'clickMenu',
        pageName: this.pageName,
        valueName: { ['Колокольчик']: 'Ещё (кнопка)' },
      },
    });
    this.analytics.send({
      event: {
        actionType: 'Клик',
        blockType: this.blockPlace,
        goalName: 'ClickMenuProfile',
        pageName: this.pageName,
        prop1: 'Профиль',
        prop2: { ['Колокольчик']: 'Ещё (кнопка)' },
      },
    });
  }

  sendEventNotificationAnswerClick(): void {
    this.analytics.send({
      event: {
        blockType: this.blockPlace,
        fieldType: 'Форма связи с редакцией',
        goalName: 'clickMenu',
        pageName: this.pageName,
        valueName: 'Открыта',
      },
    });
    this.analytics.send({
      event: {
        actionType: 'Клик',
        blockType: this.blockPlace,
        goalName: 'contactEditors',
        pageName: this.pageName,
        prop1: 'Форма связи с редакцией',
        prop2: 'Открыта',
      },
    });
  }

  sendEventNotificationLinkClick(): void {
    this.analytics.send({
      event: {
        blockType: this.blockPlace,
        fieldType: 'Пользователь',
        goalName: 'clickMenu',
        pageName: this.pageName,
        valueName: { ['Колокольчик']: 'Ответить (кнопка)' },
      },
    });
    this.analytics.send({
      event: {
        actionType: 'Клик',
        blockType: this.blockPlace,
        goalName: 'ClickMenuProfile',
        pageName: this.pageName,
        prop1: 'Профиль',
        prop2: 'Ответить (кнопка)',
      },
    });
  }

  sendEventToggleNotifications(): void {
    this.analytics.send({
      event: {
        blockType: this.blockPlace,
        fieldType: 'Пользователь',
        goalName: 'clickMenu',
        pageName: this.pageName,
        valueName: 'Колокольчик',
      },
    });
    this.analytics.send({
      event: {
        actionType: 'Клик',
        blockType: this.blockPlace,
        goalName: 'ClickMenuProfile',
        pageName: this.pageName,
        prop1: 'Профиль',
        prop2: 'Колокольчик',
      },
    });
  }
}
