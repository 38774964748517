


































import { clickOutside } from '@smh/utils/click-outside';
import { Component, Vue, VModel, Emit, Watch } from 'vue-property-decorator';

@Component({
  name: 'PageHeaderSearch',
})
export default class PageHeaderSearch extends Vue {
  @VModel({ type: Boolean }) isOpen!: boolean;

  searchValue = '';

  @Emit('searchOpened')
  emitSearchOpen() {}

  @Emit('submit')
  submit(event: Event): {
    event: Event;
    value: string;
  } {
    (this.$refs.input as HTMLElement)?.blur();
    return {
      event,
      value: this.searchValue,
    };
  }

  @Watch('isOpen')
  onIsOpenChanged(value: boolean) {
    if (value) {
      setTimeout(() => {
        this.setClickOutside();
      }, 0);
    }
  }

  toggleMenu() {
    this.isOpen = !this.isOpen;

    setTimeout(() => {
      if (this.isOpen) {
        this.emitSearchOpen();
        (this.$refs.input as HTMLElement)?.focus();
      }
    }, 0);
  }

  setClickOutside() {
    const container = this.$refs.container as Element | undefined;
    if (!container) {
      return;
    }

    clickOutside(
      container,
      () => {
        this.isOpen = false;
      },
      { isSkipFirstClick: false },
    );
  }
}
