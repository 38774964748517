
































import { guardUnspecified, guardEmptyString } from '@smh/utils/guards';
import { Component, Prop, Vue } from 'vue-property-decorator';

import { UiImage } from '../ui-image';
import type { Image } from '../ui-image';

import type { AvatarShape, AvatarSize, AvatarSex } from './ui-avatar.contract';

@Component({
  name: 'UiAvatar',
  components: {
    UiImage,
  },
})
export default class UiAvatar extends Vue {
  @Prop({
    type: Number as () => AvatarSize,
    default: 64,
  })
  avatarSize: AvatarSize;

  @Prop({
    type: String as () => AvatarShape,
    default: 'square',
  })
  avatarShape: AvatarShape;

  @Prop({
    type: String as () => AvatarSex,
    default: 'unknown',
  })
  sex: AvatarSex;

  @Prop({
    type: Object as () => Image,
    default: null,
  })
  image: Image;

  get sizeClass(): string {
    return `size-${this.avatarSize}`;
  }

  get hasImage(): boolean {
    return guardUnspecified(this.image?.src) && guardEmptyString(this.image.src);
  }
}
