

















import { Component, Prop, Vue, Emit } from 'vue-property-decorator';

import type { MenuServiceVM } from '@jtnews/shared/seedwork/frontend/application';
import { UiSlider, UiScrollable } from '@jtnews/shared/ui';

@Component({
  name: 'PageHeaderServices',
  components: { UiSlider, UiScrollable },
})
export default class PageHeader extends Vue {
  @Prop({
    required: true,
    type: Array as () => MenuServiceVM[],
  })
  services!: MenuServiceVM[];

  @Emit('projectClicked')
  emitProjectClick(projectName: string): string {
    return projectName;
  }

  processServiceClicked({ title }: MenuServiceVM) {
    this.emitProjectClick(title);
  }
}
