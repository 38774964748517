

























import { lock, unlock } from '@smh/utils/body-scroll-lock';
import { clickOutside } from '@smh/utils/click-outside';
import { guardZeroNumber } from '@smh/utils/guards';
import { Component, Inject, Prop, Ref, Vue, Watch } from 'vue-property-decorator';

import { AnalyticsBlockPlace } from '@jtnews/shared/seedwork/frontend/domain';
import { UiBadge, UiButton } from '@jtnews/shared/ui';
import type { IPageHeaderNotificationsUseCase } from '@jtnews/users/frontend/application';
import { PAGE_HEADER_NOTIFICATIONS_USE_CASE_KEY } from '@jtnews/users/frontend/application';

/* eslint-disable @typescript-eslint/naming-convention */
const PageHeaderNotificationsDropdown = () =>
  import(
    /* webpackChunkName: "page-header-notifications-dropdown" */ './page-header-notifications-dropdown'
  ).then((m) => m.PageHeaderNotificationsDropdown);
/* eslint-enable @typescript-eslint/naming-convention */

@Component({
  name: 'PageHeaderNotifications',
  components: { UiBadge, UiButton, PageHeaderNotificationsDropdown },
})
export default class PageHeaderNotifications extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  })
  isMobile!: boolean;

  @Ref() readonly trigger!: HTMLButtonElement;

  @Inject(PAGE_HEADER_NOTIFICATIONS_USE_CASE_KEY)
  private readonly _useCase!: IPageHeaderNotificationsUseCase;

  isOpen = false;

  get newNotificationsCount() {
    return this._useCase.newNotificationsCount;
  }

  get hasProfileNotifications(): boolean {
    return guardZeroNumber(this.newNotificationsCount);
  }

  get hasNewNotifications(): boolean {
    return this._useCase.hasNewNotifications;
  }

  get notificationsList() {
    return this._useCase.notificationsList;
  }

  get timestamp() {
    return this.notificationsList[0]?.timestamp ?? 0;
  }

  get badgeSize(): string {
    return this.hasProfileNotifications ? 'large' : 'small';
  }

  get isShowBadge(): boolean {
    return this.hasNewNotifications || this.hasProfileNotifications;
  }

  @Watch('isOpen')
  onIsOpenChanged(value: boolean) {
    if (value) {
      if (this.hasNewNotifications) {
        this._useCase.processSetLastNotification(this.notificationsList[0]);
      }
      setTimeout(() => {
        this.setClickOutside();
      }, 0);
    }
  }

  @Watch('notificationsList')
  onNotificationsListChanged() {
    this._useCase.processNewNotificationsCheck(this.timestamp);
  }

  created() {
    this._useCase.setBlockPlace({
      defaultPlace: AnalyticsBlockPlace.Menu,
      ignoreBreakpoints: true,
    });
  }

  mounted() {
    this._useCase.processNewNotificationsCheck(this.timestamp);
  }

  toggleMenu(isOpen: boolean) {
    this.isOpen = isOpen;
    if (this.isOpen && this.isMobile) {
      lock();
    } else {
      unlock();
    }
  }

  setClickOutside() {
    if (!this.trigger) {
      return;
    }
    clickOutside(
      this.trigger,
      () => {
        this.toggleMenu(false);
      },
      { isSkipFirstClick: false },
    );
  }
}
