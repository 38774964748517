import {
  IAppNewsClient,
  IAppNewsClientRequestConfig,
  NewsClient,
} from '@jtnews/shared/seedwork/frontend/infrastructure';
import type {
  NoticesApiInput,
  INotificationApi,
} from '@jtnews/users/frontend/application';

import type { NotificationApiResponseDTO } from './notification.dto';

type NotificationApiConfig = {
  deps: {
    client: IAppNewsClient;
  };
};

export type AddNotificationApiInput = {
  regionId: number;
  profileId: number;
  articleId: number;
  type: string;
};

export type DeleteNotificationApiInput = {
  regionId: number;
  profileId: number;
  bookmarkId: string;
};

export class NotificationApi
  extends NewsClient
  implements INotificationApi<NotificationApiResponseDTO>
{
  constructor(config: NotificationApiConfig) {
    super(config);
  }

  public async get(input: NoticesApiInput) {
    const { regionId, userId } = input;
    const url = `/public/users/${userId}/notices/new/count/`;

    const options: IAppNewsClientRequestConfig = {
      withCredentials: true,
      params: {
        regionId,
      },
      url,
    };

    const { data, responseTime: duration } =
      await this.client.get<NotificationApiResponseDTO>(regionId, url, options);

    return {
      data,
      duration,
    };
  }
}
