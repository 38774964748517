import type {
  AnalyticsServiceConfig,
  IAnalyticsService,
} from '@jtnews/shared/seedwork/frontend/domain';
import { AnalyticsService } from '@jtnews/shared/seedwork/frontend/domain';

export type PushNotificationsAnalyticsServiceConfig = AnalyticsServiceConfig;

export interface IPushNotificationsAnalyticsService extends IAnalyticsService {
  sendEventViewFormPushNotificationsSubscribe: () => void;
  sendEventClickPopoverAllowed: () => void;
  sendEventClickPopoverDisallowed: () => void;
  sendEventClickBrowserPermission: (value: boolean) => void;
}

export class PushNotificationsAnalyticsService
  extends AnalyticsService
  implements IPushNotificationsAnalyticsService
{
  private readonly _blockType: string;
  private readonly _fieldType: string;

  constructor(config: PushNotificationsAnalyticsServiceConfig) {
    super(config);

    this._blockType = 'Формы';
    this._fieldType = 'Подписка на push-уведомления';
  }

  sendEventViewFormPushNotificationsSubscribe() {
    this.analytics.send({
      event: {
        fieldType: this._fieldType,
        goalName: 'viewFormPushSubscribe',
        pageName: this.pageName,
        valueName: 'Просмотр',
      },
    });
    this.analytics.send({
      event: {
        actionType: 'Просмотр',
        blockType: this._blockType,
        goalName: 'viewFormPushSubscribe',
        pageName: this.pageName,
        prop1: this._fieldType,
        prop2: 'Продукт',
      },
    });
  }

  sendEventClickPopoverAllowed(): void {
    this.analytics.send({
      event: {
        fieldType: this._fieldType,
        goalName: 'clickFormPushSubscribe',
        pageName: this.pageName,
        valueName: {
          ['Клик']: {
            ['undefined']: 'Включить',
          },
        },
      },
    });
    this.analytics.send({
      event: {
        actionType: 'Клик',
        blockType: this._blockType,
        goalName: 'clickFormPushSubscribe',
        pageName: this.pageName,
        prop1: this._fieldType,
        prop2: 'Включить',
      },
    });
  }

  sendEventClickPopoverDisallowed(): void {
    this.analytics.send({
      event: {
        fieldType: this._fieldType,
        goalName: 'clickFormPushSubscribe',
        pageName: this.pageName,
        valueName: {
          ['Клик']: {
            ['undefined']: 'Не сейчас',
          },
        },
      },
    });
    this.analytics.send({
      event: {
        actionType: 'Клик',
        blockType: this._blockType,
        goalName: 'clickFormPushSubscribe',
        pageName: this.pageName,
        prop1: this._fieldType,
        prop2: 'Не сейчас',
      },
    });
  }

  sendEventClickBrowserPermission(value: boolean): void {
    const valueName = value ? 'Заблокировать' : 'Разрешить';

    this.analytics.send({
      event: {
        fieldType: this._fieldType,
        goalName: 'clickFormPushBrowserSubscribe',
        pageName: this.pageName,
        valueName: {
          ['Клик']: {
            ['undefined']: valueName,
          },
        },
      },
    });
    this.analytics.send({
      event: {
        actionType: 'Клик',
        blockType: this._blockType,
        goalName: 'clickFormPushBrowserSubscribe',
        pageName: this.pageName,
        prop1: this._fieldType,
        prop2: valueName,
      },
    });
  }
}
