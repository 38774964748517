














































import { guardUnspecified } from '@smh/utils/guards';
import { isAbsoluteUrl } from '@smh/utils/url';
import { Component, Inject, Prop, Vue, Watch } from 'vue-property-decorator';

import { INFORMER_BLOCK_USE_CASE_KEY } from '@jtnews/shared/seedwork/frontend/application';
import type {
  CurrencyBlockVM,
  TrafficBlockVM,
  WeatherBlockVM,
  IInformerBlockUseCase,
  SPBlockVM,
} from '@jtnews/shared/seedwork/frontend/application';
import type { BlockPlaceRepositoryConfig } from '@jtnews/shared/seedwork/frontend/domain';
import {
  UiCurrency,
  UiObserveVisibility,
  UiTraffic,
  UiWeather,
  UiWeatherTooltip,
  UiSP,
} from '@jtnews/shared/ui';

@Component({
  name: 'InformerBlock',
  components: {
    UiCurrency,
    UiObserveVisibility,
    UiTraffic,
    UiWeather,
    UiWeatherTooltip,
    UiSP,
  },
})
export default class InformerBlock extends Vue {
  @Prop({
    default: false,
    type: Boolean,
  })
  isHeaderPosition!: boolean;

  @Prop({
    default: null,
    type: Object as () => CurrencyBlockVM | null,
  })
  currency: CurrencyBlockVM | null;

  @Prop({
    default: null,
    type: Object as () => TrafficBlockVM | null,
  })
  traffic: TrafficBlockVM | null;

  @Prop({
    default: null,
    type: Object as () => WeatherBlockVM | null,
  })
  weather: WeatherBlockVM | null;

  @Prop({
    default: false,
    type: Boolean,
  })
  isTooltipEnabled: boolean;

  @Prop({
    required: true,
    type: Object as () => BlockPlaceRepositoryConfig,
  })
  blockPlaceConfig!: BlockPlaceRepositoryConfig;

  @Prop({
    default: null,
    type: Object as () => SPBlockVM | null,
  })
  sp: SPBlockVM | null;

  @Prop({
    default: false,
    type: Boolean,
  })
  isDesktop: boolean;

  @Inject(INFORMER_BLOCK_USE_CASE_KEY)
  private readonly _useCase!: IInformerBlockUseCase;

  isShowTooltip = false;

  get isShowWeatherTooltip() {
    return (
      this.isShowTooltip &&
      this.isHeaderPosition &&
      this.weather &&
      this.weather.data &&
      this.isTooltipEnabled
    );
  }

  get hasSP() {
    return guardUnspecified(this.sp);
  }

  get hasTrafficLink() {
    const link = this.traffic?.data.link ?? '';
    return isAbsoluteUrl(link);
  }

  @Watch('isTooltipEnabled')
  onIsTooltipEnabledChanged(value: boolean) {
    if (!value) {
      this.hideWeatherTooltip();
    }
  }

  created() {
    this._useCase.setBlockPlace(this.blockPlaceConfig);
  }

  showWeatherTooltip() {
    if (this.isTooltipEnabled && !this.isShowTooltip) {
      this.isShowTooltip = true;
    }
  }

  hideWeatherTooltip() {
    this.isShowTooltip = false;
  }

  processBlockShown() {
    this._useCase.processBlockShown(this.isHeaderPosition);
  }

  processLinkClick(name: string) {
    this._useCase.processLinkClicked(name, this.isHeaderPosition);
  }
}
