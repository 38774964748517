









































import { guardUnspecified } from '@smh/utils/guards';
import { Component, Vue, Prop } from 'vue-property-decorator';

import type { SliderOptions } from './ui-slider.contract';

const SLIDER_OPTIONS = {
  lazyLoad: true,
  draggable: true,
  pageDots: false,
  prevNextButtons: false,
  groupCells: undefined,
  setGallerySize: false,
  cellAlign: 'left',
  contain: true,
  initialIndex: 0,
};

type ButtonSize = 'large' | 'medium';

@Component({
  name: 'UiSlider',
})
export default class UiSlider extends Vue {
  @Prop({
    default: 0,
    type: Number,
  })
  paddings: number;

  @Prop({
    default: 360,
    type: Number,
  })
  initialSliderHeight: number;

  @Prop({
    default: false,
    type: Boolean,
  })
  fullHeight: boolean;

  @Prop({
    default: false,
    type: Boolean,
  })
  isShowButtons: boolean;

  @Prop({
    default: false,
    type: Boolean,
  })
  fixButtonsOnPicture: boolean;
  @Prop({
    default: 'image-wrap',
    type: String,
  })
  dataSliderAtr: string;
  @Prop({
    default: 'medium',
    type: String as () => ButtonSize,
  })
  buttonSize: ButtonSize;

  @Prop({
    default: () => ({}),
    type: Object as () => SliderOptions,
  })
  sliderOptions: SliderOptions;

  observer: MutationObserver | null = null;
  sliderIsReady = false;
  sliderHeight = '0px';
  btnTopPosition = '0px';
  btnRightPosition = '0px';
  slider: typeof import('flickity').prototype | null = null;

  activeIndex = 0;
  slides: HTMLElement[] = [];

  get wrapperStyles() {
    return {
      height: this.sliderHeight,
      ...this.opacityStyle,
    };
  }

  get opacityStyle() {
    return {
      opacity: this.sliderIsReady ? 1 : 0,
    };
  }

  get leftButtonStyles() {
    if (this.fullHeight) {
      return;
    }

    return {
      top: this.btnTopPosition,
      ...this.opacityStyle,
    };
  }

  get rightButtonStyles() {
    if (this.fullHeight) {
      return;
    }

    return {
      top: this.btnTopPosition,
      left: this.fixButtonsOnPicture ? this.btnRightPosition : 'initial',
      ...(this.fixButtonsOnPicture ? { right: 'initial' } : undefined),
      ...this.opacityStyle,
    };
  }

  get isFirstSlide(): boolean {
    return this.activeIndex === 0;
  }

  get isLastSlide(): boolean {
    return this.activeIndex + 1 === this.slides.length;
  }

  get hasBtnPrev(): boolean {
    return guardUnspecified(this.$slots.btnPrev);
  }

  get hasBtnNext(): boolean {
    return guardUnspecified(this.$slots.btnNext);
  }

  emitSlideChanged(index: number) {
    this.$emit('slideChanged', index + 1);
  }

  emitSliderMounted() {
    this.$emit('sliderMounted');
  }

  async initSlider(): Promise<void> {
    await this.initFlickity();
    this.$nextTick(() => {
      setTimeout(() => {
        this.setSliderHeight();
        this.setBtnPosition();
        this.sliderIsReady = true;
      }, 0);
    });
  }

  created() {
    this.sliderHeight = this.fullHeight ? '100%' : `${this.initialSliderHeight}px`;
  }

  beforeDestroy() {
    this.observer?.disconnect();
    this.slider?.destroy();
  }

  async mounted() {
    if (guardUnspecified(this.$slots.default)) {
      await this.initSlider();
    } else {
      this.createMutationObserver();
    }

    this.emitSliderMounted();
  }

  createMutationObserver() {
    this.observer = new MutationObserver(() => {
      void this.initSlider().then(() => this.observer?.disconnect());
    });

    this.observer.observe(this.$refs.slider as HTMLElement, { childList: true });
  }

  async initFlickity(): Promise<void> {
    const { default: flickity } = await import('flickity');

    await import('flickity-as-nav-for');

    // eslint-disable-next-line new-cap
    this.slider = new flickity(this.$refs.slider as HTMLElement, {
      ...SLIDER_OPTIONS,
      on: {
        change: (index?: number) => {
          this.sliderChanged(index);
        },
      },
      ...this.sliderOptions,
    });

    if (this.fullHeight) {
      // хак, чтобы слайдер ресайзился после исчезновения полосы прокрутки
      // иначе элементы при прокрутке съезжают
      this.slider.resize();
    }
  }

  setSliderHeight(): void {
    if (this.fullHeight) {
      return;
    }

    let maxHeight = this.initialSliderHeight;

    this.$slots.default?.forEach((slide) => {
      const elm = slide.elm as HTMLElement;

      if (!guardUnspecified(elm)) {
        return;
      }

      this.slides.push(elm);

      const { height } = elm.getBoundingClientRect();

      if (height > maxHeight) {
        maxHeight = height;
      }
    });

    this.slides.forEach((slide) => {
      slide.style.height = `${maxHeight}px`;
    });

    this.sliderHeight = `${maxHeight + this.paddings}px`;
  }

  setBtnPosition(): void {
    if (!guardUnspecified(this.$slots.default)) {
      return;
    }

    let height: number | undefined = 0;
    let width: number | undefined = 0;

    this.$slots.default.find((slide) => {
      const elm = (slide.elm as HTMLElement)?.querySelector(
        `div[data-slider="${this.dataSliderAtr}"]`,
      );
      const sizes = elm?.getBoundingClientRect();

      height = sizes?.height;
      width = sizes?.width;

      return guardUnspecified(height);
    });

    if (guardUnspecified(height)) {
      this.btnTopPosition = `${height / 2}px`;
    }

    if (this.fixButtonsOnPicture && guardUnspecified(width)) {
      const BUTTON_WIDTH = 24;
      const INDENT = 8;
      this.btnRightPosition = `${width - BUTTON_WIDTH - INDENT}px`;
    }
  }

  prevSlide() {
    this.slider?.previous();
  }

  nextSlide() {
    this.slider?.next();
  }

  sliderChanged(index?: number) {
    if (!guardUnspecified(index)) {
      return;
    }

    this.activeIndex = index;
    this.emitSlideChanged(index);
  }
}
