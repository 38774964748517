import {
  BlockPlaceRepositoryConfig,
  IThemesBlockAnalyticsService,
} from '@jtnews/shared/seedwork/frontend/domain';

import { BlockUseCase, IBlockUseCase } from '../block-usecase';
import type { BlockUseCaseConfig } from '../block-usecase';

export const THEMES_USE_CASE_KEY = 'themes';

export interface IThemesBlockUseCase extends IBlockUseCase {
  readonly isMobile: boolean;
  processArticleClick(url: string, index: number): void;
  processBlockShown(url: string, index: number): void;
}

export type ThemesBlockUseCaseConfig = BlockUseCaseConfig & {
  isMobile: boolean;
  deps: {
    analyticsService: IThemesBlockAnalyticsService;
  };
};

export class ThemesBlockUseCase extends BlockUseCase implements IThemesBlockUseCase {
  private readonly _analyticsService: IThemesBlockAnalyticsService;
  private readonly _isMobile: boolean;

  constructor(config: ThemesBlockUseCaseConfig) {
    super(config);
    const {
      isMobile,
      deps: { analyticsService },
    } = config;

    this._analyticsService = analyticsService;
    this._isMobile = isMobile;
  }

  public get isMobile(): boolean {
    return this._isMobile;
  }

  public setBlockPlace(blockPlaceConfig: BlockPlaceRepositoryConfig) {
    this._analyticsService.setBlockPlace(blockPlaceConfig);
  }

  public processArticleClick(url: string, index: number): void {
    this._analyticsService.sendEventArticleClick(url, index);
  }

  public processBlockShown(url: string, index: number): void {
    this._analyticsService.sendEventBlockShown(url, index);
  }
}
